import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {loadStripe} from '@stripe/stripe-js';
import {useLocation} from 'react-router-dom';

import Button from '../../common/Button';

import SubscriptionFeatures from './SubscriptionFeatures';

import {
  isMostValuableOffer,
  getProductInfo,
  getExistingSubscription,
} from '../../../util/subscriptions';
import {STRIPE_PUBLIC_KEY, WEB_APP_URL} from '../../../util/constants';
import {logAndShowError} from '../../../util/errors';
import firebase from '../../../util/firebase';
import {logEvent} from '../../../util/analytics';

import {displayToast} from '../../../actions/toasts';
import {getSubscriptionOfferings} from '../../../actions/subscriptions';
import {getUserSubscription} from '../../../actions/user';
import {
  showLoadingModal,
  hideLoadingModal
} from '../../../actions/loadingModal';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const SubscriptionSettings = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const subscriptions = useSelector((store) => store.subscriptions);
  const user = useSelector((store) => store.user);
  const {
    existingSubscription, 
    existingSubscriptionOffering
  } = getExistingSubscription(user.subscriptionInfo, subscriptions.offerings);

  useEffect(() => {
    dispatch(getSubscriptionOfferings());
    dispatch(getUserSubscription());

    const query = new URLSearchParams(location.search);
    if (query.get("success")) {
      dispatch(
        displayToast({
          type: 'success',
          message: "You've been subscribed! You should receive an email confirmation shortly.",
          dismissAfterTime: 5000,
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleManageSubscription = async () => {
    logEvent('tapped_manage_subscription');
    try {
      dispatch(showLoadingModal());
      const httpsCallable = firebase.functions().httpsCallable('getStripeBillingPortalSession');
      const {data: session} = await httpsCallable({
        userId: user.uid,
        appDomain: WEB_APP_URL,
      });
      dispatch(hideLoadingModal());
      window.location.href = session.url;
    } catch(error) {
      dispatch(hideLoadingModal());
      logAndShowError(error);
    }
  };

  const handleSubscribe = async (offering) => {
    try {
      dispatch(showLoadingModal());
      const stripe = await stripePromise;
      const httpsCallable = firebase.functions().httpsCallable('getStripeCheckoutSession');
      const {data: session} = await httpsCallable({
        userId: user.uid,
        offering,
        appDomain: WEB_APP_URL
      });
      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      dispatch(hideLoadingModal());
      if (result.error) {
        logAndShowError(result.error);
      }
    } catch(error) {
      dispatch(hideLoadingModal());
      logAndShowError(error);
    }
  }

  const renderSubscriptionOffering = (offering) => {
    return (
      <SubscriptionOffering
        key={offering.identifier}
        allOfferings={subscriptions.offerings}
        offering={offering}
        onClick={() => handleSubscribe(offering)}
      />
    );
  };

  const renderExistingSubscriptionContent = () => {
    const {subscriptionInfo} = user;
    const activeSubscription = subscriptionInfo.entitlements.active.paid;
    const {
      latestPurchaseDate,
      expirationDate,
      billingIssueDetectedAt,
      willRenew,
      store,
    } =  activeSubscription;
    let planString;
    if (existingSubscriptionOffering) {
      const {priceString, intervalString} = getProductInfo(existingSubscriptionOffering);
      planString = `${priceString} ${intervalString}`;
    }
    const startingDateString = DateTime.fromISO(latestPurchaseDate).toFormat(
      'MMMM d, yyyy'
    );
    const expirationString = DateTime.fromISO(expirationDate).toFormat('MMMM d, yyyy');

    return (
      <>
        <h3 className="card-title">
          Your subscription info
        </h3>
        <div className="card-body">
          {planString && <p className="mb-2">Current plan: <strong>{planString}</strong></p>}
          <p className="text-sm">Started on <strong>{startingDateString}</strong></p>
          <p className="text-sm">
            {willRenew ? 'Renews' : 'Ends'} on <strong>{expirationString}</strong>
          </p>
          {billingIssueDetectedAt && (
            <div className="rounded-md bg-red-50 px-5 py-4 mt-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-bold text-red-800">
                    Heads up!
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    A billing issue was detected with your subscription. Please make sure your billing information for the subscription is up to date to avoid any disruptions in your service.
                  </div>
                </div>
              </div>
            </div>
          )}
          {store === 'PROMOTIONAL' ? (
            <div className="mt-3">
              <p>You're on a promotional plan! No need to manage anything.</p>
            </div>
          ) : (
            store === 'STRIPE' ? (
              <div className="mt-5">
                <button
                  className="btn btn-primary"
                  onClick={handleManageSubscription}
                >
                  Manage Subscription
                </button>
              </div>
            ) : (
              <div className="mt-3">
                <p>
                  Looks like you subscribed on {store === 'APP_STORE' ? 'iOS' : 'Android'}. 
                  To manage your subscription, please go {subscriptionInfo.managementURL ? (
                    <a href={`${subscriptionInfo.managementURL}`}>here</a>
                  ) : (
                    `to the app on your ${store === 'APP_STORE' ? 'iOS' : 'Android'} device`
                  )}.
                </p>
              </div>
            )
          )}
        </div>
      </>
    );
  };

  const renderSubscribeContent = () => {
    return (
      <>
        <h3 className="card-title text-center">
          Upgrade for full access to Decision Journal
        </h3>
        <div className="card-body space-y-4">
          <SubscriptionFeatures />
          <div className="justify-center sm:flex w-full gap-4 sm:space-y-0 space-y-4">
            {subscriptions.offerings.map(renderSubscriptionOffering)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="card">
      {existingSubscription
        ? renderExistingSubscriptionContent()
        : renderSubscribeContent()}
    </div>
  )
}

const SubscriptionOffering = ({
  offering,
  allOfferings,
  onClick,
}) => {
  const {title, trialPeriod} = getProductInfo(offering);
  const isBestValue = isMostValuableOffer(offering, allOfferings);

  const trialPeriodUnit = trialPeriod.intro_price_period_unit;
  const trialPrice = trialPeriod.intro_price;
  const trialPeriodUnits = trialPeriod.intro_price_period_number_of_units;
  const trialString = `${trialPeriodUnits} ${_.capitalize(trialPeriodUnit)} ${
    trialPrice === 0 ? 'Free' : trialPrice
  }`;

  return (
    <div style={{minHeight: '200px'}} className="p-4 justify-between w-full border border-gray-300 dark:border-gray-800 rounded-md">
      <div className="relative text-center justify-between flex flex-col h-full">
        <h4 className="mt-2 text-xl leading-8 pt-10">
          {title}
        </h4>
        {trialPeriod && trialPeriodUnits && (
          <p className="text-sm">
            {trialString}
          </p>
        )}
        {isBestValue && (
          <span className="inline-flex items-center absolute top-1 right-1 px-2 py-0.5 rounded-lg text-xs font-semibold bg-purple-100 text-purple-800">
            Best Value!
          </span>
        )}
        <Button
          additionalClasses="btn-primary btn-block mt-4 text-base"
          label={`Upgrade Now`}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default SubscriptionSettings;
