import {useDispatch, useSelector} from 'react-redux';
import {Switch} from '@headlessui/react';

import {updateUserNotificationSettings} from '../../../actions/user';


const NotificationsSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  const updateNotificationSettings = (field, val) => {
    const settings = {
      ...user.notificationSettings,
      [field]: val,
    };
    dispatch(updateUserNotificationSettings(settings));
  };

  return (
    <div className="card">
      <h3 className="card-title">Notification Settings</h3>
      <div className="card-body">
        <div className="space-y-4">
          <section>
            <h5 className="settings-section-header">By Method</h5>
            <div className="settings-section-content">
              <div className="settings-row">
                <p>
                  Push Notifications
                </p>
                <Switch
                  checked={user.notificationSettings &&
                    user.notificationSettings.pushNotifications}
                  onChange={(value) => updateNotificationSettings('pushNotifications', value)}
                  className={`${
                    user.notificationSettings &&
                    user.notificationSettings.pushNotifications ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700'
                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span className="sr-only">Enable push notifications</span>
                  <span
                    className={`${
                      user.notificationSettings &&
                      user.notificationSettings.pushNotifications ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0`}
                  />
                </Switch>
              </div>
              <div className="settings-row">
                <p>
                  Email Notifications
                </p>
                <Switch
                  checked={user.notificationSettings &&
                    user.notificationSettings.emailNotifications}
                  onChange={(value) => updateNotificationSettings('emailNotifications', value)}
                  className={`${
                    user.notificationSettings &&
                    user.notificationSettings.emailNotifications ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700'
                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span className="sr-only">Enable push notifications</span>
                  <span
                    className={`${
                      user.notificationSettings &&
                      user.notificationSettings.emailNotifications ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0`}
                  />
                </Switch>
              </div>
            </div>
          </section>
          <section>
            <h5 className="settings-section-header">By Type</h5>
            <div className="settings-section-content">
              <div className="settings-row">
                <div>
                  <p>Review Reminders</p>
                  <span className="settings-helper-text">We&#39;ll send you reminder notifications when a decision of yours is due for review.</span>
                </div>
                <Switch
                  checked={user.notificationSettings &&
                    user.notificationSettings.reviewReminders}
                  onChange={(value) => updateNotificationSettings('reviewReminders', value)}
                  className={`${
                    user.notificationSettings &&
                    user.notificationSettings.reviewReminders ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700'
                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span className="sr-only">Enable push notifications</span>
                  <span
                    className={`${
                      user.notificationSettings &&
                      user.notificationSettings.reviewReminders ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0`}
                  />
                </Switch>
              </div>
              <div className="settings-row">
                <div>
                  <p>Decision Reminders</p>
                  <span className="settings-helper-text">We&#39;ll send you periodic notifications to remind you to add decisions to your journal.</span>
                </div>
                <Switch
                  checked={user.notificationSettings &&
                    user.notificationSettings.decisionReminders}
                  onChange={(value) => updateNotificationSettings('decisionReminders', value)}
                  className={`${
                    user.notificationSettings &&
                    user.notificationSettings.decisionReminders ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700'
                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span className="sr-only">Enable push notifications</span>
                  <span
                    className={`${
                      user.notificationSettings &&
                      user.notificationSettings.decisionReminders ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0`}
                  />
                </Switch>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default NotificationsSettings;