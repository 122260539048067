import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showCreateDecision} from '../../util/navigation';
import {logEvent} from '../../util/analytics';
import {getGettingStartedSteps} from '../../util/gettingStarted';
import {logError, logAndShowError} from '../../util/errors';

import {skipGettingStartedStep} from '../../actions/user';
import {linkUserCalendar} from '../../actions/calendar';
import {showLoadingModal, hideLoadingModal} from '../../actions/loadingModal';

const GettingStartedSteps = ({
  onClickStep,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const gettingStartedStepsStatuses = user.gettingStartedSteps;
  const scheduledForReviewDecisions = useSelector((store) => store.scheduledForReviewDecisions.list);

  const showAddDecision = () => {
    logEvent('clicked_getting_started_step', {step: 'add'});
    showCreateDecision(history);
    if (onClickStep) {
      onClickStep('addDecision');
    }
  }

  const showSaveADraft = () => {
    logEvent('clicked_getting_started_step', {step: 'draft'});
    if (gettingStartedStepsStatuses['savedDraft']) {
      history.push('/');
    } else {
      showCreateDecision(history);
    }
    if (onClickStep) {
      onClickStep('saveDraft');
    }
  }

  const showConnectCalendar = async () => {
    logEvent('clicked_getting_started_action', { step: 'calendar' });
    if (onClickStep) {
      onClickStep('connectCalendar');
    }
    try {
      dispatch(showLoadingModal());
      await dispatch(linkUserCalendar('google'));
      dispatch(hideLoadingModal());
    } catch (e) {
      dispatch(hideLoadingModal());
      if (e.error === 'popup_closed_by_user') {
        logError(e);
      } else {
        logAndShowError(e);
      }
    }
  }

  const showTagDecision = () => {
    logEvent('clicked_getting_started_action', { step: 'tag' });
    if (gettingStartedStepsStatuses['taggedDecision']) {
      history.push(`/past`);
    } else {
      if (scheduledForReviewDecisions.length > 0) {
        history.push(`/scheduled`);
      } else {
        history.push(`/past`);
      }
    }
    if (onClickStep) {
      onClickStep('tagDecision');
    }
  }

  const showReviewDecision = () => {
    logEvent('clicked_getting_started_action', { step: 'review' });
    history.push(`/scheduled`);
    if (onClickStep) {
      onClickStep('reviewDecision');
    }
  }

  const steps = getGettingStartedSteps(gettingStartedStepsStatuses).map(step => {
    switch (step.id) {
      case 'connectedCalendar':
        return {
          ...step,
          onClick: showConnectCalendar,
        }
      case 'savedDraft':
        return {
          ...step,
          onClick: showSaveADraft,
        }
      case 'addedDecision':
        return {
          ...step,
          onClick: showAddDecision,
        }
      case 'taggedDecision':
        return {
          ...step,
          onClick: showTagDecision,
        }
      case 'reviewedDecision':
        return {
          ...step,
          onClick: showReviewDecision,
        }
      default:
        return step;
    }
  });

  const handleSkipStep = (e, step) => {
    skipStep(step.id);
    e.preventDefault();
    e.stopPropagation();
  }

  const skipStep = (step) => {
    dispatch(skipGettingStartedStep(step));
  }

  return (
    <ol className="overflow-hidden">
      {steps.map((step, idx) => {
        const previousStep = steps[idx - 1];
        const isCurrentStep = (!step.isCompleted && !step.isSkipped) && (!previousStep || (previousStep.isCompleted || previousStep.isSkipped));
        const isLastStep = idx === steps.length - 1;

        return (
          <li 
            key={step.id} 
            className={`relative ${isLastStep ? '' : 'pb-6'}`}>
            {!isLastStep && (
              <div 
                className={`-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full ${
                  step.isCompleted 
                    ? 'bg-indigo-600' 
                    : step.isSkipped
                      ? 'bg-gray-500 dark:bg-gray-600'
                      : 'bg-gray-300 dark:bg-gray-700'}`} 
                aria-hidden="true"></div>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={step.onClick} className="relative flex items-start group">
              <span className="h-9 flex items-center">
                <span 
                  className={`relative z-10 w-8 h-8 flex items-center justify-center rounded-full ${
                    step.isCompleted 
                    ? 'bg-indigo-600' 
                    : step.isSkipped 
                      ? 'bg-gray-500 dark:bg-gray-600'
                      : isCurrentStep 
                        ? 'bg-white dark:bg-gray-800 border-2 border-indigo-600' 
                        : 'bg-white dark:bg-gray-800 border-2 border-gray-300 dark:border-gray-700'}`}>
                  {step.isCompleted ? (
                    <svg 
                      className="w-5 h-5 text-white" 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 20 20" 
                      fill="currentColor" 
                      aria-hidden="true">
                      <path 
                        fillRule="evenodd" 
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  ) : step.isSkipped ? (
                    <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  ) : (
                    <span className={`h-2.5 w-2.5 rounded-full ${isCurrentStep ? 'bg-indigo-600' : 'bg-transparent group-hover:bg-gray-300 dark:group-hover:bg-gray-600'}`}></span>
                  )}
                </span>
              </span>
              <span className="ml-4 min-w-0 flex flex-col flex-1">
                <span className={`text-xs font-semibold tracking-wide uppercase ${step.isCompleted || isCurrentStep ? 'text-indigo-600' : 'text-gray-500 dark:text-gray-300'}`}>
                  {step.title}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400 mt-1">{step.subtitle}</span>
                {!step.isCompleted && !step.isSkipped && isCurrentStep && <span onClick={(e) => handleSkipStep(e, step)} className="link text-gray-400 dark:text-gray-500 text-xs mt-1">Skip Step</span>}
              </span>
            </a>
          </li>
        )
      })}
    </ol>
  )
};

export default GettingStartedSteps;