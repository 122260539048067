/* global gapi */

import {GOOGLE_API_KEY, GOOGLE_CALENDAR_CLIENT_ID} from './constants';

const initGapi = () => {
  gapi.load('client', async () => {
    await gapi.client.init({
      apiKey: GOOGLE_API_KEY,
      clientId: GOOGLE_CALENDAR_CLIENT_ID,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
      scope: 'https://www.googleapis.com/auth/calendar.calendarlist.readonly https://www.googleapis.com/auth/calendar.events.readonly',
    });

    await gapi.client.load('calendar', 'v3');
  });
}

export {
  initGapi,
};