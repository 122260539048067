const TextField = ({
  autoFocus,
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  attachmentRight,
  attachmentLeft,
  min,
  max
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="form-field-label">
          {label}
        </label>
      )}
      <div className="mt-1 flex">
        {attachmentLeft}
        <input 
          id={name}
          autoFocus={autoFocus} 
          type={type || "text"}
          name={name} 
          placeholder={placeholder} 
          className={`${attachmentRight ? 'with-attachment-right' : ''} ${attachmentLeft ? 'with-attachment-left' : ''}  text-field`}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
        />
        {attachmentRight}
      </div>
    </div>
  )
}

export default TextField;