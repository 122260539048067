import {useDispatch} from 'react-redux';

import Button from './Button';

import {linkUserCalendar} from '../../actions/calendar';
import {showLoadingModal, hideLoadingModal} from '../../actions/loadingModal';

import {logError, logAndShowError} from '../../util/errors';

const ConnectGoogleCalendarButton = () => {
  const dispatch = useDispatch();

  const connectCalendar = async () => {
    try {
      dispatch(showLoadingModal());
      await dispatch(linkUserCalendar('google'));
      dispatch(hideLoadingModal());
    } catch (e) {
      dispatch(hideLoadingModal());
      if (e.error === 'popup_closed_by_user') {
        logError(e);
      } else {
        logAndShowError(e);
      }
    }
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <Button 
        label="Connect Google Calendar" 
        onClick={connectCalendar} 
        additionalClasses="btn btn-sm"
      />
      <div className="text-center mt-3 text-xs text-gray-500">
        <p>
          Connect your calendar to add decisions for your meetings and events.
        </p>
      </div>
    </div>
  )
}

export default ConnectGoogleCalendarButton;