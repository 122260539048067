import {round} from 'lodash';

import {isDefined} from './helpers';

// All skill = 0
// All luck = 1
const getSkillVsLuckForWeight = (weight) => {
  const skillMeasurement = isDefined(weight) ? round(Math.abs(weight - 1) * 100, 0) : 'N/A';
  const luckMeasurement = isDefined(weight) ? round(weight * 100, 0) : 'N/A';
  return {
    skill: skillMeasurement,
    luck: luckMeasurement,
  };
}

// If negative, then overestimated skill
// If positive, then overestimated luck
// If 0, then perfect accuracy
const getSkillVsLuckDifferenceSummary = (difference) => {
  if (difference === 0) {
    return 'accurately estimated the role of skill and luck in your outcomes.';
  } else if (difference > 0) {
    return (
      <span>
        <strong><u>overestimated the role of luck</u></strong> and <strong><u>underestimated the role of skill</u></strong> in your outcomes.
      </span>
    );
  } else {
    return (
      <span>
        <strong><u>overestimated the role of skill</u></strong> and <strong><u>underestimated the role of luck</u></strong> in your outcomes.
      </span>
    );
  }
}

export {
  getSkillVsLuckForWeight,
  getSkillVsLuckDifferenceSummary
}