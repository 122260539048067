const ReviewOutcomeEstimatesTable = ({
  outcomeEstimates, 
  disabled,
  readonly, 
  onChange,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="table-wrap">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th scope="col" className="th text-left">
                    Outcome
                  </th>
                  <th scope="col" className="th text-center">
                    Did it happen?
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {outcomeEstimates.map((outcomeEstimate, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="td">{outcomeEstimate.text}</td>
                      <td className="td text-center">
                        <div className="flex items-center h-5 justify-center">
                          {readonly ? (
                            outcomeEstimate.itHappened ? "Yes" : "No"
                          ) : (
                            <input
                              disabled={disabled}
                              name="comments" 
                              type="checkbox" 
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 dark:border-gray-700 rounded"
                              checked={outcomeEstimate.itHappened}
                              onChange={(e) => onChange(idx, e.target.checked)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewOutcomeEstimatesTable;