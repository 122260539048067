import SelectInput from '../../common/SelectInput';

const TimeSeriesGraphNavMenu = ({onChange, currentSelection}) => {
  return (
    <>
      <div className="px-0 mb-6 sm:hidden">
        <SelectInput
          name="tabs"
          value={currentSelection}
          onChange={(event) => onChange(event.target.value)}
          options={[
            {value: "accuracy", label: "Accuracy Scores"},
            {value: "brier", label: "Brier Scores"},
            {value: "skillLuck", label: "Skill vs Luck"},
          ]}
        />
      </div>
      <div className="hidden sm:block">
        <div className="page-sub-menu-wrap mb-6">
          <nav className="page-sub-menu" aria-label="Tabs">
            <button 
              onClick={() => onChange('accuracy')}
              className={`page-sub-menu-item ${currentSelection === 'accuracy' ? 'active' : ''}`}
            >
              Accuracy Scores
            </button>
            <button 
              onClick={() => onChange('brier')}
              className={`page-sub-menu-item ${currentSelection === 'brier' ? 'active' : ''}`}
            >
              Brier Scores
            </button>
            <button 
              onClick={() => onChange('skillLuck')}
              className={`page-sub-menu-item ${currentSelection === 'skillLuck' ? 'active' : ''}`}
            >
              Skill vs Luck
            </button>
          </nav>
        </div>
      </div>
    </>
  )
}

export default TimeSeriesGraphNavMenu;