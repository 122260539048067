import {useSelector} from 'react-redux';
import {
  Switch,
  Route,
} from 'react-router-dom';

import PageContainer from '../../common/PageContainer';

import AccountNav from './AccountNav';
import CalendarSettings from './CalendarSettings';
import ApiKeySettings from './ApiKeySettings';
import SubscriptionSettings from './SubscriptionSettings';
import Support from './Support';
import NotificationsSettings from './NotificationsSettings';
import GettingStartedSettings from './GettingStartedSettings';

const AccountHome = ({history}) => {
  const user = useSelector((store) => store.user);
  const gettingStartedStepsStatuses = user.gettingStartedSteps;

  return (
    <PageContainer title="Your Account">
      <div className="px-4 sm:py-8 sm:px-0">
        <div className="md:grid md:grid-cols-12 md:gap-x-5">
        <AccountNav history={history} user={user} />
        <div className="col-span-8">
          <Switch>
            <Route path="/account/subscription">
              <SubscriptionSettings history={history}  />
            </Route>
            <Route path="/account/calendar">
              <CalendarSettings history={history}  />
            </Route>
            <Route path="/account/api_key">
              <ApiKeySettings history={history}  />
            </Route>
            {gettingStartedStepsStatuses && !gettingStartedStepsStatuses.isCompleted && (
              <Route path="/account/getting_started">
                <GettingStartedSettings history={history}  />
              </Route>
            )}
            <Route path="/account/support">
              <Support history={history} />
            </Route>
            <Route path="/account">
              <NotificationsSettings history={history} />
            </Route>
          </Switch>
        </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default AccountHome;