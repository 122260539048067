import {
  SUPPORT_EMAIL, 
  GUIDES_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '../../../util/constants';

const Support = () => {
  return (
    <div className="space-y-3 sm:space-y-6">
      <div className="card">
        <h3 className="card-title">Our Guides</h3>
        <div className="card-body space-y-4">
          <p className="font-bold">
            Looking for information about how to use the site or just about decision journals in general?
          </p>
          <p>
            We've put together a few guides that might be helpful. Check them out by clicking the button below.
          </p>
          <div>
            <a className="btn btn-primary" target="_blank" rel="noreferrer" href={GUIDES_URL}>View our guides</a>
          </div>
        </div>
      </div>
      <div className="card">
        <h3 className="card-title">Contact Support</h3>
        <div className="card-body space-y-4">
          <p className="font-bold">
            Run into a problem? Have a feature request? Or just want to say hi?
          </p>
          <p>
            You can contact us at any time by simply sending an email to: <a target="_blank" rel="noreferrer" href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </p>
          <p>
            Please include in the subject line what your issue is and we'll try to get back to you as quickly as possible. Most emails get answered within a few hours.
          </p>
        </div>
      </div>
      <div className="divide-x divide-gray-200 dark:divide-gray-700 space-x-3 text-center items-center py-3">
        <a className="text-sm" href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">Privacy Policy</a>
        <a className="text-sm pl-3" href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">Terms of Service</a>
      </div>
    </div>
  )
}

export default Support;