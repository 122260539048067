import emoji from 'node-emoji';

const Emoji = ({name, size}) => {
  return (
    <div 
      className={`rounded-full ${size === 'xl' ? 'text-5xl' : 'text-2xl'}`}
    >
      {emoji.get(name)}
    </div>
  )
}

export default Emoji;