import {Link} from 'react-router-dom';

import {getBreadcrumbForPath} from '../../../util/navigation';

const DecisionsBreadcrumbs = ({history}) => {
  const historyState = history.location.state;

  const mapHistoryToCrumbs = () => {
    const crumbs = [];
    if (Array.isArray(historyState.prevLocation)) {
      historyState.prevLocation.map((path) => crumbs.push(getBreadcrumbForPath(path)));
    } else {
      crumbs.push(getBreadcrumbForPath(historyState.prevLocation));
    }
    crumbs.push({
      ...getBreadcrumbForPath(history.location.pathname),
      active: true,
    });
    return crumbs;
  }

  const renderCrumb = (crumb, idx) => {
    return (
      <li key={crumb.path}>
        <div className="flex items-center">
          {idx !== 0 && (
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          )}
          {!crumb.active ? (
            <Link to={crumb.path} className={`${idx !== 0 ? 'ml-4' : ''} text-sm font-medium text-gray-500 hover:text-gray-700`}>{crumb.title}</Link>
          ) : (
            <span aria-current="page" className="ml-4 text-sm font-medium text-gray-500">{crumb.title}</span>
          )}
        </div>
      </li>
    );
  }

  if (historyState) {
    return (
      <nav className="flex mb-5" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {mapHistoryToCrumbs().map(renderCrumb)}
        </ol>
      </nav>
    );
  }

  return null;
}

export default DecisionsBreadcrumbs;