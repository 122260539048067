const showLoadingModal = () => {
  return {
    type: 'SHOW_LOADING_MODAL',
  }
}

const hideLoadingModal = () => {
  return {
    type: 'HIDE_LOADING_MODAL',
  }
}

export {
  showLoadingModal,
  hideLoadingModal,
}