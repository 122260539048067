import {useState, useEffect} from 'react';
import {Link, useRouteMatch, withRouter} from 'react-router-dom';
import {includes} from 'lodash';

import UserMenu from './UserMenu';
import Search from './Search';
import SmallScreenNav from './SmallScreenNav';

const decisionPages = [
  'in_progress',
  'to_review',
  'past',
  'scheduled',
  'decisions'
];

const NavigationMenu = ({history}) => {
  const match = useRouteMatch("/:section");
  const page = (match && match.params.section) || 'home';
  const [isSmallNavVisible, setIsSmallNavVisible] = useState(false);

  useEffect(() => {
    const navigationListener = history.listen(onNavigationChange);

    return () => {
      navigationListener();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavigationChange = (location, action) => {
    // If navigation has changed, ensure menu closes
    setIsSmallNavVisible(false);
  }

  const toggleSmallNav = () => {
    setIsSmallNavVisible(!isSmallNavVisible);
  }

  return (
    <nav className="navbar">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex flex-row h-8 w-auto outline-none focus:outline-none">
                <img className="block lg:hidden h-8 w-auto" src="https://firebasestorage.googleapis.com/v0/b/decision-journal-app.appspot.com/o/website%2Ficon-rounded.png?alt=media&token=b52b9082-c0cf-4bad-840d-ba1881e2fdb9" alt="Decision Journal" />
                <img className="hidden lg:block h-8 w-auto" src="https://firebasestorage.googleapis.com/v0/b/decision-journal-app.appspot.com/o/website%2Ficon-rounded.png?alt=media&token=b52b9082-c0cf-4bad-840d-ba1881e2fdb9" alt="Decision Journal" />
                <h1 className="hidden lg:flex lg:text-xl ml-2 h-8 w-auto">Decision Journal</h1>
              </Link>
            </div>
            <div className="hidden sm:-my-px sm:mx-6 sm:flex sm:space-x-8">
              <Link to="/" className={`navbar-link ${(page === 'home') || includes(decisionPages, page) ? 'active' : ''}`}>
                Decisions
              </Link>

              <Link to="/analyze" className={`navbar-link ${page === 'analyze' && 'active'}`}>
                Analyze
              </Link>
            </div>
          </div>
          <Search />
          <UserMenu />
          <div className="-mr-2 flex items-center sm:hidden">
            <button 
              onClick={toggleSmallNav}
              className="bg-white dark:bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
              <span className="sr-only">Open main menu</span>
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <SmallScreenNav isVisible={isSmallNavVisible} />
    </nav>
  );
};

export default withRouter(NavigationMenu);