const getGettingStartedSteps = (gettingStartedStepsStatuses) => {
  return [{
    id: 'connectedCalendar',
    title: 'Connect Calendar',
    subtitle: 'Create decisions for your meetings or events.',
    isCompleted: gettingStartedStepsStatuses && gettingStartedStepsStatuses['connectedCalendar'] === 'completed',
    isSkipped: gettingStartedStepsStatuses && gettingStartedStepsStatuses['connectedCalendar'] === 'skipped',
  }, {
    id: 'savedDraft',
    title: 'Save a draft',
    subtitle: 'Start working on a decision by saving it as a draft.',
    isCompleted: gettingStartedStepsStatuses && gettingStartedStepsStatuses['savedDraft'] === 'completed',
    isSkipped: gettingStartedStepsStatuses && gettingStartedStepsStatuses['savedDraft'] === 'skipped',
  }, {
    id: 'addedDecision',
    title: 'Add a decision',
    subtitle: "After you've made a decision, add it and schedule a review.",
    isCompleted: gettingStartedStepsStatuses && gettingStartedStepsStatuses['addedDecision'] === 'completed',
    isSkipped: gettingStartedStepsStatuses && gettingStartedStepsStatuses['addedDecision'] === 'skipped',
  }, {
    id: 'taggedDecision',
    title: 'Tag a decision',
    subtitle: 'Categorize your decisions by tagging them.',
    isCompleted: gettingStartedStepsStatuses && gettingStartedStepsStatuses['taggedDecision'] === 'completed',
    isSkipped: gettingStartedStepsStatuses && gettingStartedStepsStatuses['taggedDecision'] === 'skipped',
  }, {
    id: 'reviewedDecision',
    title: 'Review a decision',
    subtitle: 'Review one of your decisions.',
    isCompleted: gettingStartedStepsStatuses && gettingStartedStepsStatuses['reviewedDecision'] === 'completed',
    isSkipped: gettingStartedStepsStatuses && gettingStartedStepsStatuses['reviewedDecision'] === 'skipped',
  }];
}

export {
  getGettingStartedSteps,
}