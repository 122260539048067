import firebase from '../util/firebase';
import {logError} from '../util/errors';
import {MAX_TAGS_FOR_ANALYTICS_TAB} from '../util/tags';

const fetchingTags = () => {
  return {
    type: 'FETCHING_TAGS',
  };
};

const fetchingTagDecisions = () => {
  return {
    type: 'FETCHING_TAG_DECISIONS',
  };
};

const fetchingTagsFailed = () => {
  return {
    type: 'FETCHING_TAGS_FAILED',
  };
};

const fetchingTagDecisionsFailed = () => {
  return {
    type: 'FETCHING_TAG_DECISIONS_FAILED',
  };
};

const tagDecisionsLoaded = (decisions) => {
  return {
    type: 'TAG_DECISIONS_LOADED',
    decisions,
  };
};

const tagsLoaded = (tags) => {
  return {
    type: 'TAGS_LOADED',
    tags,
  };
};

const loadTags = (onlyWithScores) => async (dispatch, getState) => {
  try {
    dispatch(fetchingTags());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('getTags');
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      onlyWithScores,
    });

    return dispatch(tagsLoaded(data));
  } catch (e) {
    dispatch(fetchingTagsFailed());
    return logError(e);
  }
};

const loadTagDecisions = (tag) => async (dispatch, getState) => {
  try {
    dispatch(fetchingTagDecisions());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('getTagDecisions');
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      tag,
    });

    return dispatch(tagDecisionsLoaded(data));
  } catch (e) {
    dispatch(fetchingTagDecisionsFailed());
    return logError(e);
  }
};

const showTag = (tag) => {
  return {
    type: 'SHOW_TAG',
    tag,
  };
};

const currentTagUpdated = (tag) => {
  return {
    type: 'CURRENT_TAG_UPDATED',
    tag,
  };
};

const syncCurrentTag = (docSnapshot) => async (dispatch) => {
  dispatch(
    currentTagUpdated({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    })
  );
};

const fetchingTagSearchResults = () => {
  return {
    type: 'FETCHING_TAG_SEARCH_RESULTS',
  };
};

const fetchingTagSearchResultsFailed = () => {
  return {
    type: 'FETCHING_TAG_SEARCH_RESULTS_FAILED',
  };
};

const tagSearchResultsUpdated = (results) => {
  return {
    type: 'TAG_SEARCH_RESULTS_UPDATED',
    results,
  };
};

const searchTags = (searchQuery) => async (dispatch, getState) => {
  try {
    dispatch(fetchingTagSearchResults());
    const {searchKey} = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('searchTags');
    const {data: results} = await httpsCallable({
      searchKey,
      searchQuery,
    });

    return dispatch(tagSearchResultsUpdated(results));
  } catch (e) {
    dispatch(fetchingTagSearchResultsFailed());
    return logError(e);
  }
};

const clearTagSearch = () => {
  return {
    type: 'CLEAR_TAG_SEARCH',
  };
};

const syncTagsForAnalytics = (querySnapshot) => (dispatch) => {
  const list = [];
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      list.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  dispatch(tagsLoaded(list));
};

const registerTagsWithScoresListener = (dispatch, userRef) => {
  return userRef
    .collection('Tags')
    .where('avgAccuracyScore', '>', 0)
    .orderBy('avgAccuracyScore', 'desc')
    .limit(MAX_TAGS_FOR_ANALYTICS_TAB)
    .onSnapshot((snapshot) =>
      dispatch(syncTagsForAnalytics(snapshot, dispatch, tagsLoaded))
    );
};

export {
  loadTags,
  loadTagDecisions,
  showTag,
  searchTags,
  syncCurrentTag,
  clearTagSearch,
  registerTagsWithScoresListener,
  syncTagsForAnalytics,
};
