import {Link, useRouteMatch} from 'react-router-dom';
import {
  BellIcon, 
  CalendarIcon, 
  QuestionMarkCircleIcon,
  CreditCardIcon,
  ClipboardDocumentCheckIcon,
  KeyIcon
} from '@heroicons/react/24/outline';

const AccountNav = ({user}) => {
  const match = useRouteMatch("/account/:section");
  const page = (match && match.params.section) || 'notifications';
  const gettingStartedStepsStatuses = user.gettingStartedSteps;

  return (
    <div className="py-6 px-2 sm:px-6 md:py-0 md:px-0 md:col-span-4">
      <div className="mb-3 font-semibold">{user.email}</div>
      <nav className="space-y-1" aria-label="Sidebar">
        <Link to="/account/notifications" className={`settings-link ${page === 'notifications' ? 'active' : ''}`} aria-current="page">
          <BellIcon className={`settings-link-icon ${page === 'notifications' ? 'active' : ''} h-12 w-12`} />
          <span className="truncate">
            Notifications
          </span>
        </Link>

        <Link to="/account/calendar" className={`settings-link ${page === 'calendar' ? 'active' : ''}`} aria-current="page">
          <CalendarIcon className={`settings-link-icon ${page === 'calendar' ? 'active' : ''} h-12 w-12`} />
          <span className="truncate">
            Calendar
          </span>
        </Link>


        <Link to="/account/api_key" className={`settings-link ${page === 'api_key' ? 'active' : ''}`} aria-current="page">
          <KeyIcon className={`settings-link-icon ${page === 'api_key' ? 'active' : ''} h-12 w-12`} />
          <span className="truncate">
            API Key
          </span>
        </Link>

        {gettingStartedStepsStatuses && !gettingStartedStepsStatuses.isCompleted && (
          <Link to="/account/getting_started" className={`settings-link ${page === 'getting_started' ? 'active' : ''}`} aria-current="page">
            <ClipboardDocumentCheckIcon className={`settings-link-icon ${page === 'getting_started' ? 'active' : ''} h-12 w-12`} />
            <span className="truncate">
              Getting Started
            </span>
          </Link>
        )}

        <Link to="/account/subscription" className={`settings-link ${page === 'subscription' ? 'active' : ''}`}>
          <CreditCardIcon className={`settings-link-icon ${page === 'subscription' ? 'active' : ''} h-12 w-12`} />
          <span className="truncate">
            Subscription
          </span>
        </Link>

        <Link to="/account/support" className={`settings-link ${page === 'support' ? 'active' : ''}`}>
          <QuestionMarkCircleIcon className={`settings-link-icon ${page === 'support' ? 'active' : ''} h-12 w-12`} />
          <span className="truncate">
            Support
          </span>
        </Link>
      </nav>
    </div>
  )
}

export default AccountNav;