import firebase from '../util/firebase';

const initialState = {
  isLoggedIn: false,
  requestedLoginLink: false,
  email: '',
  notificationSettings: {
    pushNotifications: true,
    emailNotifications: true,
    reviewReminders: true,
    decisionReminders: true,
  },
  tags: [],
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOGGED_IN':
      if (!firebase.auth().currentUser) {
        // means this was fired from a different tab and we need to refresh window
        window.location.reload();
      }
      return {
        ...state,
        isLoggedIn: true,
        requestedLoginLink: false,
        ...action.user,
      };

    case 'SET_LOGIN_EMAIL':
      return {
        ...state,
        email: action.email,
      };

    case 'SENT_LOGIN_CODE_VIA_EMAIL':
      return {
        ...state,
        requestedLoginLink: true,
      };

    case 'RECEIVED_USER':
      return {
        ...state,
        ...action.user,
      };

    case 'RECEIVED_SEARCH_KEY':
      return {
        ...state,
        searchKey: action.searchKey,
      };

    case 'REGISTERED_DEVICE_TOKEN':
      return {
        ...state,
        deviceToken: action.token,
      };

    case 'UNREGISTERED_DEVICE_TOKEN':
      return {
        ...state,
        deviceToken: null,
      };

    case 'RECEIVED_USER_SUBSCRIPTIONS':
      return {
        ...state,
        subscriptionInfo: action.info,
      };

    case 'UPDATED_USER_NOTIFICATION_SETTINGS':
      return {
        ...state,
        notificationSettings: action.settings,
      };

    case 'RECEIVED_USER_TAGS':
      return {
        ...state,
        tags: action.tags,
      };
    
    case 'SKIPPED_GETTING_STARTED_STEP':
      return {
        ...state,
        gettingStartedSteps: {
          ...state.gettingStartedSteps,
          [`${action.step}`]: 'skipped',
        }
      };

    case 'LOGGED_OUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default user;
