import {Transition} from '@headlessui/react';

const SidePanel = ({isVisible, children}) => {
  return (
    <Transition
      appear
      show={isVisible}
      enter="transform transition ease-in-out duration-500 sm:duration-700"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-500 sm:duration-700"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      className="fixed inset-0 overflow-hidden"
    >
      <div className="absolute inset-0 overflow-hidden">
        <section className="absolute inset-y-0 pl-0 sm:pl-10 max-w-full right-0 flex" aria-labelledby="slide-over-heading">
          <div className="w-screen max-w-md">
            <div className="side-panel">
              {children}
            </div>
          </div>
        </section>
      </div>
    </Transition>
  )
}

export default SidePanel;