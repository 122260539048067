import firebase from '../util/firebase';
import {logOut} from './login';
import {logError} from '../util/errors';
import {getSubscriberInfo} from '../util/subscriptions';

function receiveUser(userId, json) {
  return {
    type: 'RECEIVED_USER',
    user: {
      uid: userId,
      ...json,
    },
  };
}

const syncUser = (querySnapshot) => (dispatch) => {
  // if querySnapshot is null, should log user out
  if (querySnapshot) {
    const data = querySnapshot.data();
    dispatch(receiveUser(querySnapshot.id, data));
  } else {
    dispatch(logOut());
  }
};

const receivedUserTags = (tags) => {
  return {
    type: 'RECEIVED_USER_TAGS',
    tags,
  };
}

const syncUserTags = (querySnapshot) => (dispatch) => {
  const list = [];
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      list.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  dispatch(receivedUserTags(list));
}

const registeredUserLocaleData = () => {
  return {
    type: 'REGISTERED_USER_LOCALE_DATA',
  };
};

const registerUserLocaleData = (data) => async (dispatch, getState) => {
  try {
    const currentUser = getState().user;
    await firebase.functions().httpsCallable('registerUserLocaleInfo')({
      userId: currentUser.uid,
      ...data,
    });

    return dispatch(registeredUserLocaleData());
  } catch (e) {
    return logError(e);
  }
};

const updatedUserNotificationSettings = (settings) => {
  return {
    type: 'UPDATED_USER_NOTIFICATION_SETTINGS',
    settings,
  };
};

const updateUserNotificationSettings = (notificationSettings) => async (
  dispatch,
  getState
) => {
  // Trigger update instantly for better UI
  dispatch(updatedUserNotificationSettings(notificationSettings));

  try {
    const currentUser = getState().user;
    await firebase.functions().httpsCallable('updateUserNotificationSettings')({
      userId: currentUser.uid,
      notificationSettings,
    });

    return dispatch(updatedUserNotificationSettings(notificationSettings));
  } catch (e) {
    return logError(e);
  }
};

const receivedUserSubscriptions = (info) => {
  return {
    type: 'RECEIVED_USER_SUBSCRIPTIONS',
    info,
  };
};

const getUserSubscription = () => async (dispatch, getState) => {
  try {
    const currentUser = getState().user;
    const purchaserInfo = await getSubscriberInfo(currentUser);
    // send subscription info to server for keeping track of
    const httpsCallable = firebase.functions().httpsCallable('syncUserSubscription');
    await httpsCallable({
      userId: currentUser.uid,
      subscriptionInfo: purchaserInfo,
    });

    return true;
  } catch (e) {
    return logError(e);
  }
};

const updateUserData = async ({id, additionalInfo, credential}) => {
  try {
    const httpsCallable = firebase.functions().httpsCallable('updateUserData');
    await httpsCallable({
      userId: id,
      additionalInfo,
      credential
    });

    return true;
  } catch (e) {
    return logError(e);
  }
}

const updateUserWithCredentialData = (credentialData) => {
  updateUserData({
    id: credentialData.user.uid, 
    additionalInfo: credentialData.additionalUserInfo,
    authCredential: credentialData.credential,
  });
}

const toggleGettingStartedMenu = (minimized) => {
  return {
    type: 'TOGGLED_GETTING_STARTED_MENU',
    isMinimized: !minimized,
  }
}

const dismissGettingStartedMenu = () => {
  return {
    type: 'DISMISSED_GETTING_STARTED_MENU',
  }
}

const skippedGettingStartedStep = (step) => {
  return {
    type: 'SKIPPED_GETTING_STARTED_STEP',
    step
  };
}

const skipGettingStartedStep = (step) => async (dispatch, getState) => {
  try {
    dispatch(skippedGettingStartedStep(step));
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('skipGettingStartedStep');
    await httpsCallable({
      userId: currentUser.uid,
      step
    });

    return true;
  } catch (e) {
    return logError(e);
  }
};

const generateApiKey = () => async (dispatch, getState) => {
  try {
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('createNewApiKey');
    await httpsCallable({
      userId: currentUser.uid,
    });

    return true;
  } catch (e) {
    return logError(e);
  }
};

export {
  syncUser,
  syncUserTags,
  updateUserData,
  updateUserWithCredentialData,
  registerUserLocaleData,
  getUserSubscription,
  receivedUserSubscriptions,
  updateUserNotificationSettings,
  toggleGettingStartedMenu,
  skipGettingStartedStep,
  dismissGettingStartedMenu,
  generateApiKey,
};
