import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Switch} from '@headlessui/react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import TextArea from '../../common/TextArea';
import SelectInput from '../../common/SelectInput';

import ReviewOutcomeEstimatesTable from './ReviewOutcomeEstimatesTable';

import {getSkillVsLuckForWeight} from '../../../util/skillLuck';

const ReviewForm = ({
  review, 
  onUpdate,
  isDraft,
  isLoading,
}) => {
  const {skill, luck} = getSkillVsLuckForWeight(review.skillLuckWeight);

  const [reviewAgain, setReviewAgain] = useState(
    !!review.monthsToNextReview
  );

  const reviewPeriodOptions = useSelector(
    (store) => store.config.reviewPeriods
  );

  const handleUpdateOutcomeEstimates = (idx, value) => {
    const updatedOutcomeEstimates = review.outcomeEstimates;
    updatedOutcomeEstimates[idx] = {
      ...updatedOutcomeEstimates[idx],
      itHappened: value,
    };

    onUpdate({
      ...review,
      outcomeEstimates: updatedOutcomeEstimates
    });
  }

  const handleToggleReviewAgain = (value) => {
    setReviewAgain(value);
    if (value) {
      onUpdate({
        ...review, 
        monthsToNextReview: value || reviewPeriodOptions[0].value
      })
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="px-4 divide-y divide-gray-200 sm:px-6">
        <div className="space-y-6 pt-2 pb-5">
          <div className="form-control">
            <TextArea
              label="What actually happened?"
              name="actualOutcome"
              placeholder="Enter the details about what happened"
              value={review.actualOutcome}
              onChange={(event) => onUpdate({...review, actualOutcome: event.target.value})}
              disabled={isLoading}
            />
          </div>

          {/* only review outcome estimates if some were made */}
          {review.outcomeEstimates && review.outcomeEstimates.length > 0 && (
            <div>
              <ReviewOutcomeEstimatesTable 
                outcomeEstimates={review.outcomeEstimates} 
                disabled={!isDraft} 
                onChange={(idx, value) => handleUpdateOutcomeEstimates(idx, value)}
              />
              {!isDraft && (
                <p className="text-gray-500 text-sm mt-3 text-center flex items-center justify-center">
                  <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Outcomes cannot be changed after creation.
                </p>
              )}
            </div>
          )}

          {/* only review skill vs luck if estimation was made */}
          {review.skillLuckWeight && (
            <div className="mt-6">
              <div className="form-control">
                <div className="sm:col-span-6">
                  <label htmlFor="skillLuckWeight" className="form-field-label">
                    How much of the actual outcome(s) do you think was due to your skill vs your luck?
                  </label>
                  <div className="mt-3">
                    <div className="text-center pt-2 pb-3 px-2 flex items-start justify-center">
                      <div className="text-center">
                        <p className="text-3xl font-bold">{skill}%</p>
                        <p className="text-sm font-semibold">Skill</p>
                      </div>
                      <p className="text-3xl font-bold mx-3">/</p>
                      <div className="text-center">
                        <p className="text-3xl font-bold">{luck}%</p>
                        <p className="text-sm font-semibold">Luck</p>
                      </div>
                    </div>
                    {!isDraft && (
                      <p className="text-gray-500 text-sm mb-3 text-center flex items-center justify-center">
                        <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Scores cannot be changed after creation.
                      </p>
                    )}
                    <Slider 
                      disabled={!isDraft || isLoading}
                      min={0}
                      max={1}
                      step={.05}
                      value={review.skillLuckWeight}
                      onChange={(value) => {
                        onUpdate({...review, skillLuckWeight: value});
                      }}
                      onAfterChange={(value) =>
                        onUpdate({...review, skillLuckWeight: value})
                      }
                      handleStyle={{width: 10, height: 30, marginTop: -14, borderRadius: 5}}
                      className="slider-neutral"
                    />
                    <div className="flex justify-between items-center mt-1">
                      <p>Skill</p>
                      <p>Luck</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mt-6">
            <div className="form-control">
              <div className="sm:col-span-6">
                <label htmlFor="accuracyScore" className="form-field-label">
                  How would you rate the accuracy of your expected outcome for this decision overall?
                </label>
                <div className="mt-3">
                  <div className="text-center pt-2 pb-3 px-2">
                    <p className="text-3xl font-bold">
                      {review.accuracyScore}
                    </p>
                  </div>
                  {!isDraft && (
                    <p className="text-gray-500 text-sm mb-3 text-center flex items-center justify-center">
                      <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Scores cannot be changed after creation.
                    </p>
                  )}
                  <Slider 
                    disabled={!isDraft || isLoading}
                    min={0}
                    max={10}
                    step={1}
                    value={review.accuracyScore}
                    onChange={(value) => {
                      onUpdate({...review, accuracyScore: value});
                    }}
                    onAfterChange={(value) =>
                      onUpdate({...review, accuracyScore: value})
                    }
                    handleStyle={{width: 20, height: 20, marginTop: -8}}
                  />
                  <div className="flex justify-between items-center">
                    <p>0</p>
                    <p>10</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="form-control">
              <TextArea
                label="What did you learn?"
                name="learnings"
                placeholder="Enter your learnings and review"
                value={review.learningsAndReview}
                onChange={(event) => onUpdate({...review, learningsAndReview: event.target.value})}
                disabled={isLoading}
              />
            </div>
          </div>

          {isDraft && review.status !=='published' && (
            <div className="mt-6">
              <div className="form-control">
                <div className="sm:col-span-6">
                  <label htmlFor="reviewPeriod" className="form-field-label">
                    Would you like to schedule another review?
                  </label>
                  <div className="mt-1">
                    <Switch
                      disabled={isLoading}
                      checked={reviewAgain}
                      onChange={(value) => handleToggleReviewAgain(value)}
                      className={`${
                        reviewAgain ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700'
                      } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                      <span className="sr-only">Enable review</span>
                      <span
                        className={`${
                          reviewAgain ? 'translate-x-5' : 'translate-x-0'
                        } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          )}

          {reviewAgain && (
            <div className="mt-6">
              <div className="form-control">
                <div className="sm:col-span-6">
                  <label htmlFor="reviewPeriod" className="form-field-label">
                    When would you like to review this again?
                  </label>
                  <div className="mt-1">
                    <SelectInput
                      name="reviewPeriod"
                      value={review.monthsToNextReview}
                      onChange={(event) => {
                        onUpdate({
                          ...review, 
                          monthsToNextReview: event.target.value || reviewPeriodOptions[0].value
                        })
                      }}
                      options={reviewPeriodOptions.map(opt => ({
                        ...opt,
                        label: `in ${opt.label}`
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewForm;
