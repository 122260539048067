const initialState = {
  isLoading: false,
  loadingFailed: false,
  searchResults: [],
};

function search(state = initialState, action) {
  switch (action.type) {
    case 'FETCHING_SEARCH_RESULTS':
      return {
        ...state,
        isLoading: true,
        loadingFailed: false,
      };

    case 'FETCHING_SEARCH_RESULTS_FAILED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      };

    case 'SEARCH_RESULTS_UPDATED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: false,
        searchResults: action.results,
      };

    case 'CLEAR_SEARCH':
      return initialState;

    case 'LOGGED_OUT':
      return initialState;

    default:
      return state;
  }
}

export default search;
