const initialState = {
  isVisible: false,
  message: null,
};

function paywall(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_PAYWALL':
      return {
        isVisible: true,
        ...action.paywall,
      };
    case 'HIDE_PAYWALL':
      return initialState;
    default:
      return state;
  }
}

export default paywall;
