import {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DateTime} from 'luxon';
import {orderBy} from 'lodash';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import EmptyState from '../../common/EmptyState';
import LoadingContainer from '../../common/LoadingContainer';
import ConnectGoogleCalendarButton from '../../common/ConnectGoogleCalendarButton';

import {
  hideConnectCalendar,
  fetchCalendarEvents
} from '../../../actions/calendar';

import {showCreateDecision} from '../../../util/navigation';

const CalendarEvents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const datePickerRef = useRef();
  const eventsContainerRef = useRef();
  const user = useSelector((store) => store.user);
  const calendar = useSelector((store) => store.calendar);
  const calendarSources = user.calendarSources;
  const [eventsDate, setEventsDate] = useState(null);
  const [datePickerIsVisible, setDatePickerVisibility] = useState(false);
  const eventsAreLoading = useSelector((store) => store.calendar.isLoading);
  const calIsConnected = calendarSources && Object.keys(calendarSources).length > 0;
  const [isLeftShadowVisible, setLeftShadowVisibility] = useState(false);
  const [isRightShadowVisible, setRightShadowVisibility] = useState(false);

  useEffect(() => {
    if (calIsConnected) {
      dispatch(fetchCalendarEvents(eventsDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsDate, calIsConnected]);

  useEffect(() => {
    updateEventContainerShadows();
  }, [calendar])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setDatePickerVisibility(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  const updateEventContainerShadows = () => {
    if (eventsContainerRef.current) {
      const {scrollLeft, offsetWidth, scrollWidth} = eventsContainerRef.current;
      const rightOffset = offsetWidth + scrollLeft + 5;

      if (scrollLeft === 0) {
        setLeftShadowVisibility(false);
        if (rightOffset < scrollWidth) {
          setRightShadowVisibility(true);
        } else {
          setRightShadowVisibility(false);
        }
      } else {
        setLeftShadowVisibility(true);
        if (rightOffset < scrollWidth || rightOffset === scrollWidth) {
          setRightShadowVisibility(true);
        } else {
          setRightShadowVisibility(false);
        }
      }
    }
  }

  const handleEventsScroll = () => {
    updateEventContainerShadows();
  }

  const addDecisionForEvent = (event) => {
    showCreateDecision(history, null, {
      title: event.title, 
      context: `${event.description 
        ? event.description : ''}${event.attendees && event.attendees.length > 0 
          ? `\nwith ${event.attendees.map(attendee => `${attendee.email} `)}` : ''}${event.link 
          ? `\n\nEvent link: ${event.link}` : ''}`
    });
  }

  const dismiss = () => {
    dispatch(hideConnectCalendar());
  }

  const renderConnectCalendar = () => {
    if (calendar.isVisible) {
      return (
        <div className="flex flex-col justify-center items-center py-3 sm:py-5 px-4 border border-dashed my-3 mx-4 sm:mx-0 relative dark:border-gray-700">
          <button onClick={dismiss} className="absolute top-2 right-2 text-gray-500 hover:text-gray-600 dark:hover:text-gray-400">
            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <ConnectGoogleCalendarButton />
        </div>
      );
    }

    return null;
  }

  const toggleDatePicker = () => {
    setDatePickerVisibility(visibility => !visibility);
  }

  const getCurrentDateString = () => {
    const dateTime = eventsDate 
      ? DateTime.fromMillis(eventsDate)
      : DateTime.local();

    return DateTime.local().hasSame(dateTime, 'day') ? dateTime.toRelativeCalendar() : dateTime.toLocaleString(DateTime.DATE_FULL);
  }

  const handleSelectDate = (date) => {
    const dateTime = DateTime.fromJSDate(date);
    setEventsDate(dateTime.toMillis());
    setDatePickerVisibility(false);
  }

  const renderDatePicker = () => {
    if (datePickerIsVisible) {
      return (
        <div ref={datePickerRef} className="date-picker-wrapper">
          <DayPicker
            month={eventsDate ? DateTime.fromMillis(eventsDate).toJSDate() : DateTime.local().toJSDate()}
            selectedDays={eventsDate ? DateTime.fromMillis(eventsDate).toJSDate() : DateTime.local().toJSDate()}
            onDayClick={handleSelectDate}
          />
        </div>
      );
    }

    return null;
  }

  const renderCalendarEvents = () => {
    return (
      <div className="divide-y divide-gray-200 dark:divide-gray-700 py-4 px-4 sm:px-0 sm:pb-0">
        <div className="relative">
          <button onClick={toggleDatePicker} className="text-xs text-gray-500 dark:text-gray-300 pb-2 flex items-center focus:outline-none">
            Your events for {getCurrentDateString()}
            <svg className="w-3 h-3 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {renderDatePicker()}
        </div>
        {eventsAreLoading 
          ? <LoadingContainer size="medium" />
          : (
            calendar.events.length > 0 ? (
              <div 
                ref={eventsContainerRef} 
                onScroll={handleEventsScroll} 
                onLoad={updateEventContainerShadows}
                className="calendar-events-wrap"
              >
                <div className={`sticky left-0 ${isLeftShadowVisible ? '' : 'hidden'}`}>
                  <div className="shadow-left"></div>
                </div>
                {orderBy(calendar.events, ['start']).map((event) => {
                  return (
                    <div 
                      key={event.id} 
                      onClick={() => addDecisionForEvent(event)} 
                      className="card cursor-pointer min-w-11/12 sm:min-w-1/2 md:min-w-1/3 mr-3"
                    >
                      <div className="card-body flex flex-col flex-1 justify-between">
                        <div>
                          <p className="font-bold truncate">{event.title}</p>
                          <p className="text-sm font-gray-500">
                            {event.isAllDayEvent 
                              ? 'All Day' 
                              : `${DateTime.fromMillis(event.start).toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromMillis(event.end).toLocaleString(DateTime.TIME_SIMPLE)}`
                            }
                          </p>
                        </div>
                        <div className="mt-2">
                          <button className="link text-sm">
                            Add Decision
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className={`sticky right-0 ${isRightShadowVisible ? '' : 'hidden'}`}>
                  <div className="shadow-right"></div>
                </div>
              </div>
            ) : (
              <EmptyState
                size="sm"
                description="No events found for this date."
              />
            )
          )
        }
      </div>
    )
  }

  return calIsConnected ? renderCalendarEvents() : renderConnectCalendar();
}

export default CalendarEvents;