/* global gapi */

import {WEB_APP_URL} from '../util/constants';
import firebase from '../util/firebase';
import {logError} from '../util/errors';

const hideConnectCalendar = () => {
  return {
    type: 'HIDE_CONNECT_CALENDAR'
  }
};

const linkedUserCalendar = (provider) => {
  return {
    type: 'LINKED_CALENDAR',
    provider,
  }
}

const unlinkedUserCalendar = (provider) => {
  return {
    type: 'UNLINKED_CALENDAR',
    provider,
  }
}

const fetchingCalendarEvents = () => {
  return {
    type: 'FETCHING_CALENDAR_EVENTS',
  };
}

const loadingCalendarEventsFailed = () => {
  return {
    type: 'LOADING_CALENDAR_EVENTS_FAILED',
  };
}

const updatedSelectedEventCalendars = () => {
  return {
    type: 'UPDATED_SELECTED_EVENT_CALENDARS',
  };
}

const fetchCalendarEvents = (date) => async (dispatch, getState) => {
  try {
    dispatch(fetchingCalendarEvents());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('fetchCalendarEvents');
    const {data: events} = await httpsCallable({
      userId: currentUser.uid,
      date
    });
  
    return dispatch({
      type: 'RECEIVED_CALENDAR_EVENTS',
      events,
    });
  } catch (e) {
    logError(e);
    dispatch(loadingCalendarEventsFailed());
  }
}

const linkUserCalendar = (providerName) => async (dispatch) => {
  if (providerName === 'google') {
    const googleAuth = gapi.auth2.getAuthInstance();
    const {code} = await googleAuth.grantOfflineAccess();

    await connectGoogleCalendar(code, WEB_APP_URL);

    dispatch(fetchCalendarEvents());

    return dispatch(linkedUserCalendar(providerName));
  }

  return;
}

const removeCalendarProvider = (provider) => async (dispatch, getState) => {
  const currentUser = getState().user;
  const httpsCallable = firebase.functions().httpsCallable('removeCalendarProvider');
  await httpsCallable({
    userId: currentUser.uid,
    provider,
  });
  return dispatch(unlinkedUserCalendar(provider));
}

const connectGoogleCalendar = async (code, redirectUrl) => {
  const httpsCallable = firebase.functions().httpsCallable('connectGoogleCalendar');
  return httpsCallable({
    userId: firebase.auth().currentUser.uid,
    code,
    redirectUrl,
  });
};

const updateSelectedEventCalendarForSource = (source, calendars) => async (dispatch) => {
  const httpsCallable = firebase.functions().httpsCallable('updateSelectedEventCalendarsForSource');
  await httpsCallable({
    userId: firebase.auth().currentUser.uid,
    source,
    selectedCalendars: calendars,
  });
  return dispatch(updatedSelectedEventCalendars());
}

export {
  linkUserCalendar,
  removeCalendarProvider,
  hideConnectCalendar,
  fetchCalendarEvents,
  updateSelectedEventCalendarForSource,
};