import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Prompt, useHistory, useParams} from 'react-router-dom';
import {isEqual} from 'lodash';

import PageContainer from '../../common/PageContainer';

import DecisionsBreadcrumbs from './DecisionsBreadcrumbs';
import DecisionForm from './DecisionForm';

import firebase from '../../../util/firebase';
import {DRAFT_STATUS, initialOutcomeEstimate} from '../../../util/decisions';
import {logAndShowError} from '../../../util/errors';
import {logScreenView} from '../../../util/analytics';
import {shouldShowPaywall} from '../../../util/subscriptions';
import {isDefined} from '../../../util/helpers';

import {
  saveDecision,
  deleteDecision,
  syncCurrentDecision,
} from '../../../actions/decisions';
import {
  showLoadingModal,
  hideLoadingModal,
} from '../../../actions/loadingModal';
import {displayToast} from '../../../actions/toasts';
import {showPaywall} from '../../../actions/subscriptions';

const EditDecision = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const {decisionId} = useParams();
  if (!decisionId) {
    // redirect if there is no decision to lookup
    history.push('/');
  }
  const showDecision = useSelector((store) => store.decisions.showDecision);
  let initialDecision;
  if (showDecision && showDecision.id === decisionId) {
    initialDecision = showDecision;
  }
  const [isSaved, setIsSaved] = useState(false);
  const [decision, updateDecision] = useState({
    ...initialDecision,
    // ensures any draft before the change over has outcome estimates set up
    outcomeEstimates: initialDecision.outcomeEstimates
      ? initialDecision.outcomeEstimates : [initialOutcomeEstimate]
  });

  const decisionRef = firebase.firestore()
    .collection('Users')
    .doc(user.uid)
    .collection('Decisions')
    .doc(decisionId);

  useEffect(() => {
    const decisionUnsubscribe = decisionRef.onSnapshot((docSnapshot) =>
      dispatch(syncCurrentDecision(docSnapshot))
    );

    logScreenView('EditDecision');

    return () => {
      decisionUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!decision) {
    return null;
  }

  const changesMade = () => {
    return !isEqual(decision, initialDecision);
  }

  const isValidDecision = () => {
    let valid = true;
    const requiredFields = [
      'title',
      'context',
      'expectedOutcome',
      'monthsToNextReview',
    ];
    requiredFields.forEach((field) => {
      const value = decision[field];
      if (!isDefined(value) || value === '') {
        valid = false;
      }
    });

    return valid;
  };

  const save = async (status) => {
    const decisionData = {
      ...decision,
      status,
    };
    dispatch(showLoadingModal());
    try {
      if (status === 'published') {
        const requiresSubscription = await shouldShowPaywall(user);
        if (requiresSubscription) {
          dispatch(hideLoadingModal());
          dispatch(showPaywall({
            message: 'Subscribe to create more decisions',
          }));
          return;
        }
      }
      await dispatch(saveDecision(decisionData));
      dispatch(hideLoadingModal());
      setIsSaved(true);
      if (status === DRAFT_STATUS) {
        history.push('/');
      } else {
        history.push(`/decisions/${decisionData.id}`);
      }
      dispatch(
        displayToast({
          type: 'success',
          message: `${
            status === 'draft'
              ? 'Draft saved'
              : 'Decision saved'
          }!`,
        })
      );
    } catch (err) {
      dispatch(hideLoadingModal());
      logAndShowError(err);
    }
  }

  const handleDeleteDecision = async () => {
    dispatch(showLoadingModal());
    try {
      await dispatch(deleteDecision(decision.id));
      setIsSaved(true);
      dispatch(
        displayToast({
          type: 'success',
          message: 'Decision removed!',
        })
      );
      dispatch(hideLoadingModal());
      history.push('/');
    } catch (e) {
      dispatch(hideLoadingModal());
      setIsSaved(false);
      logAndShowError(e);
    }
  };

  const handleSaveDecision = async () => {
    if (isValidDecision()) {
      save('published');
    } else {
      showErrors();
    }
  };

  const confirmDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this decision?');
    if (confirmed) {
      handleDeleteDecision();
    }
  };

  const showErrors = () => {
    const errorString =
      "Looks like you're missing a few things. You can add them and try again or Save as Draft and come back to it later.";

    alert(errorString);
  };

  return (
    <PageContainer 
      title="Edit Decision"
      breadcrumbs={<DecisionsBreadcrumbs history={history} />}
    >
      <Prompt
        when={changesMade() && !isSaved}
        message="Looks like you've made some changes. Are you sure you want to navigate away without saving?"
      />
      <DecisionForm
        decision={decision}
        onUpdate={updateDecision}
        isEditing={true}
        isDraft={decision.status === DRAFT_STATUS}
        isSaveDraftDisabled={!changesMade()}
        isSaveDisabled={changesMade() ? !isValidDecision() : true}
        onDelete={confirmDelete}
        onSave={handleSaveDecision}
        onSaveDraft={() => save('draft')}
      />
    </PageContainer>
  )
}

export default EditDecision;