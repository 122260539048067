const initialState = {
  isVisible: true,
  isLoading: false,
  events: [],
};

function calendar(state = initialState, action) {
  switch (action.type) {
    case 'FETCHING_CALENDAR_EVENTS':
      return {
        ...state,
        isLoading: true,
      };

    case 'LOADING_CALENDAR_EVENTS_FAILED':
      return {
        ...state,
        isLoading: false,
      };

    case 'RECEIVED_CALENDAR_EVENTS':
      return {
        ...state,
        isLoading: false,
        events: action.events,
      };

    case 'HIDE_CONNECT_CALENDAR':
      return {
        ...state,
        isVisible: false,
      };

    case 'LOGGED_OUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default calendar;
