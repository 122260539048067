
const showCreateDecision = (history, state, params) => {
  history.push(`/decisions/new${params 
    ? `?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}` 
    : ''}`, state);
}

const showDecisionView = (history, decisionId, state) => {
  history.push(`/decisions/${decisionId}`, state);
}

const showEditDecisionView = (history, decisionId, state) => {
  history.push(`/decisions/${decisionId}/edit`, state);
}

const showEditDecisionTagsView = (history, decisionId, state) => {
  history.push(`/decisions/${decisionId}/edit_tags`, state);
}

const showCreateReview = (history, decisionId, state) => {
  history.push(`/decisions/${decisionId}/reviews/new`, state);
}

const showReviewDraftsView = (history, decisionId, state) => {
  history.push(`/decisions/${decisionId}/reviews/drafts`, state);
}

const showScheduleReview = (history, decisionId, state) => {
  history.push(`/decisions/${decisionId}/schedule_review`, state);
};

const showTagView = (history, tag, state) => {
  history.push(`/tags/${tag}`, state);
}

const showTagAnalytics = (history, tag, state) => {
  history.push(`/analyze/tags/${tag}`, state);
}

const showTagsAnalytics = (history, state) => {
  history.push(`/analyze/tags`, state);
}

const getBreadcrumbForPath = (path) => {
  console.log(path);
  switch (path) {
    case '/': 
      return {
        title: 'Decisions',
        path,
      }
    case '/decisions/new':
      return {
        title: 'Add Decision',
        path,
      }
    case '/to_review':
      return {
        title: 'Decisions To Review',
        path,
      }
    case '/scheduled': 
      return {
        title: 'Scheduled Decisions',
        path,
      }
    case '/past': 
      return {
        title: 'Past Decisions',
        path,
      }
    default:
      const pathParts = path.split('/');
      if (pathParts[1] === 'tags') {
        return {
          title: `Tag: ${pathParts[pathParts.length - 1]}`,
          path,
        }
      }
      if (pathParts[pathParts.length - 1] === 'edit') {
        return {
          title: 'Edit Decision',
          path,
        }
      }
      return {
        title: 'Decision Details',
        path
      }
  }
}

export {
  showCreateDecision,
  showDecisionView,
  showEditDecisionView,
  showEditDecisionTagsView,
  showCreateReview,
  showReviewDraftsView,
  showScheduleReview,
  showTagView,
  showTagAnalytics,
  showTagsAnalytics,
  getBreadcrumbForPath,
};