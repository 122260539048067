const initialState = {
  showDecision: null,
  outstandingDecisionsToReviewCount: 0,
};

function decisions(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_DECISION':
      return {
        ...state,
        showDecision: action.decision,
      };

    case 'CURRENT_DECISION_UPDATED':
      return {
        ...state,
        showDecision: action.decision,
      };

    case 'DECISION_SAVED':
      return {
        ...state,
        showDecision:
        state.showDecision && action.decision.id === state.showDecision.id
            ? action.decision
            : state.showDecision,
      };

    case 'UPDATED_OUTSTANDING_DECISIONS_TO_REVIEW_COUNT':
      return {
        ...state,
        outstandingDecisionsToReviewCount: action.count,
      };

    case 'LOGGED_OUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default decisions;
