import {DateTime} from 'luxon';
import {useEffect} from 'react';

import {
  loadingScheduledForReviewDecisions,
  loadingToReviewDecisions,
  loadingPastDecisions,
  loadingDraftDecisions
} from '../actions/decisions';

const MAX_DRAFT_DECISIONS_HOME_TAB = 4;
const MAX_TO_REVIEW_DECISIONS_HOME_TAB = 6;
const MAX_PAST_DECISIONS_HOME_TAB = 1;

const PUBLISHED_STATUS = 'published';
const DRAFT_STATUS = 'draft';

const defaultReviewPeriods = [
  {value: '1', label: '1 month'},
  {value: '3', label: '3 months'},
  {value: '6', label: '6 months'},
  {value: '12', label: '1 year'},
];

const defaultDecisionFeelings = [
  {emoji: 'smiley', value: 'very good'},
  {emoji: 'slightly_smiling_face', value: 'good'},
  {emoji: 'neutral', value: 'neutral'},
  {emoji: 'pensive', value: 'bad'},
  {emoji: 'tired_face', value: 'very bad'},
];

const defaultEditBufferPeriod = {
  decisions: {
    amount: 2,
    interval: 'days',
  },
  reviews: {
    amount: 2,
    interval: 'days',
  },
};

const initialOutcomeEstimate = {
  text: '',
  probability: null,
};

const initialDecisionState = {
  title: '',
  context: '',
  expectedOutcome: '',
  skillLuckWeight: null,
  tags: [],
  monthsToNextReview: null,
  nextReviewDate: null,
  lastReviewedOn: null,
  status: 'draft',
  outcomeEstimates: [initialOutcomeEstimate]
};

const isDueForReview = (decision) => {
  return (decision.nextReviewDate < DateTime.local() || (decision.nextReviewDate && DateTime.local().hasSame(decision.nextReviewDate, 'day')))
}

const useDecisionsInitialLoadFetch = (decisions, type, dispatch) => {
  let dispatchAction = () => null;
  switch (type) {
    case 'past':
      dispatchAction = loadingPastDecisions;
      break;
    case 'to_review':
      dispatchAction = loadingToReviewDecisions;
      break;
    case 'scheduled':
      dispatchAction = loadingScheduledForReviewDecisions;
      break;
    default:
      dispatchAction = loadingDraftDecisions;
  }

  useEffect(() => {
    // Show an initial loading indicator if no decisions yet
    // this can happen if the user has yet to add any of these decisions or
    // they just signed in and we're performing the first load
    if (decisions.list.length === 0) {
      dispatch(dispatchAction());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const renderSkillVsLuckExplainer = () => {
  return (
    <>
      <p className="mb-2">
        The Skill vs Luck measurement is a way to judge how accurate you are in evaluating how much the outcome of a decision 
        you are making is due to skill, and how much of it is due to luck.
      </p>
      <p className="mb-2">
        Most of us tend to say things that turn out well because of our talent or skills, but when things turn out badly, 
        it's because of bad luck. This measurement now gives you a chance to make an honest evaluation of an outcome's weight 
        due to skill vs luck.
      </p>
      <p>
        If you'd like to learn more about this topic, check out the book{' '}
        <a href="https://www.amazon.com/Success-Equation-Untangling-Business-Investing/dp/1422184234" target="_blank" rel="noreferrer">
          <i>The Success Equation</i>
        </a>.
      </p>
    </>
  );
}

const renderBrierScoreExplainer = () => {
  return (
    <>
      <p className="mb-2">
        A <a href="https://en.wikipedia.org/wiki/Brier_score" target="_blank" rel="noreferrer">Brier score</a> is a measurement used 
        to judge the accuracy of probabilistic forecasts.
      </p>
      <p className="mb-2">
        To calculate it, we take the probabilities you estimated for each outcome (divided by 100 so that they are between 0 and 1). 
        Next, we take the difference between your estimate and what happened (where 1 is used when the event happened and 0 when it did not).
        Then, we square the differences for each of your estimates and add them all up, and take the average for a final score. 
        With Brier scores, like golf, lower is better.
      </p>
      <p className="mb-2">
        For example, if you estimated an event had a 70% of happening and it did actually happen, your score would be (1 – 0.7)<sup>2</sup> = 0.09. 
        The best (lowest) possible Brier score is 0, and the worst (highest) possible Brier score is 1.
      </p>
    </>
  );
}

export {
  defaultReviewPeriods,
  defaultDecisionFeelings,
  defaultEditBufferPeriod,
  initialDecisionState,
  PUBLISHED_STATUS,
  DRAFT_STATUS,
  MAX_DRAFT_DECISIONS_HOME_TAB,
  MAX_PAST_DECISIONS_HOME_TAB,
  MAX_TO_REVIEW_DECISIONS_HOME_TAB,
  isDueForReview,
  useDecisionsInitialLoadFetch,
  initialOutcomeEstimate,
  renderBrierScoreExplainer,
  renderSkillVsLuckExplainer,
};
