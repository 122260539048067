import {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import Dashboard from './components/dashboard/Dashboard';
import OAuthAuthorization from './components/oauth/OAuthAuthorization';
import Login from './components/login/Login';
import ScrollToTop from './components/common/ScrollToTop';
import LoadingModal from './components/common/LoadingModal';

import firebase from './util/firebase';

import {hideLoadingModal} from './actions/loadingModal';
import {loadRemoteConfig} from './actions/config';
import {logOut} from './actions/login';
import {hidePaywall} from './actions/subscriptions';

const App = () => {
  const isLoadingModalVisible = useSelector((store) => store.loadingModal.isVisible);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const authUnsubscribe = firebase.auth().onAuthStateChanged(onAuthChange);
    dispatch(loadRemoteConfig());

    // ensure no blocking modals
    dispatch(hideLoadingModal());
    dispatch(hidePaywall());

    return () => {
      authUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAuthChange = async (u) => {
    if (u) {
      // force refresh to make sure user account is still active
      await firebase.auth().currentUser.reload();
      return;
    } else {
      // if user is still logged in, sign them out
      if (user && user.isLoggedIn) {
        dispatch(logOut());
      }
    }
  }

  return (
    <Router>
      <ScrollToTop />
      <main id="app" className="app min-h-screen bg-white dark:bg-black">
        <Switch>
          <Route path="/login" render={({location, match, history}) => {
              if (user && user.isLoggedIn) {
                return (
                  <Redirect
                    to={{
                      pathname: '/',
                      state: { from: location }
                    }}
                  />
                )
              } else {
                return <Login location={location} match={match} history={history} />
              }
          }} />
          <Route path="/oauth/authorize" render={({location, match, history}) => {
            if (user && user.isLoggedIn) {
              return <OAuthAuthorization location={location} history={history} match={match} />
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: location }
                  }}
                />
              )
            }
          }} />
          <Route render={({location, match, history}) => {
            if (user && user.isLoggedIn) {
              return <Dashboard location={location} match={match} history={history} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: location }
                  }}
                />
              )
            }
          }} />
        </Switch>
      </main>
      <LoadingModal isVisible={isLoadingModalVisible} />
    </Router>
  );
}

export default App;
