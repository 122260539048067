import LoginWithAppleButton from './LoginWithAppleButton';
import LoginWithGoogleButton from './LoginWithGoogleButton';
import LoginWithEmail from './LoginWithEmail';

const LoginForm = () => {
  return (
    <div className="p-4 bg-white dark:bg-gray-900 border sm:border-gray-200 sm:dark:border-gray-800 sm:rounded-xl sm:p-5">
      <div className="divide-y divide-gray-200 dark:divide-gray-800 space-y-5">
        <h2 className="base-text text-center sm:text-4xl">Login</h2>
        <div className="divide-y divide-gray-200 dark:divide-gray-800 leading-6 space-y-4">
          <div className="pt-6">
            <p className="base-text text-gray-500 pb-3">Choose a method below to login in order to save and sync your journal to the cloud.</p>
            <div className="my-3">
              <LoginWithAppleButton />
            </div>
            <div className="my-3">
              <LoginWithGoogleButton />
            </div>
            <LoginWithEmail />
          </div>
          <div className="pt-6 text-xs text-gray-500 text-center">
            <p>
              By continuing, you agree to our 
              <a href="https://decisionjournalapp.com/privacy" target="_blank" rel="noreferrer">
                {' '}Privacy Policy{' '}
              </a> 
              and <a href="https://decisionjournalapp.com/terms" target="_blank" rel="noreferrer">
                Terms of Service
              </a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LoginForm;