const initialState = {
  isLoading: false,
  offerings: [],
};

function subscriptions(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_SUBSCRIPTIONS':
      return {
        ...state,
        isLoading: true,
      };
    case 'RECEIVED_SUBSCRIPTION_OFFERINGS':
      return {
        ...state,
        isLoading: false,
        offerings: action.offerings,
      };
    default:
      return state;
  }
}

export default subscriptions;
