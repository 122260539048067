import PageHeader from './PageHeader';

const PageContainer = ({
  title, 
  headerActionItems, 
  headerAdditionalClasses, 
  breadcrumbs,
  children
}) => {
  return (
    <div className="py-3 sm:py-7">
      {title && (
        <PageHeader 
          title={title} 
          breadcrumbs={breadcrumbs} 
          additionalClasses={headerAdditionalClasses} 
          actionItems={headerActionItems} 
        />
      )}
      <div className="max-w-4xl mx-auto sm:px-6 lg:px-8">
        {children}
      </div>
    </div>
  )
}

export default PageContainer;