const initialState = {
  isVisible: true,
  isMinimized: false,
};

const gettingStartedMenu = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'TOGGLED_GETTING_STARTED_MENU':
      return {
        ...state,
        isMinimized: action.isMinimized,
      };

    case 'DISMISSED_GETTING_STARTED_MENU':
      return {
        ...state,
        isVisible: false,
      };

    case 'LOGGED_OUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default gettingStartedMenu;