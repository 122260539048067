const TextArea = ({
  autoFocus,
  label,
  name,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className="sm:col-span-6">
      {label && (
        <label htmlFor={name} className="form-field-label">
          {label}
        </label>
      )}
      <div className="mt-1">
        <textarea 
          autoFocus={autoFocus} 
          id={name} 
          name={name} 
          rows="5" 
          className="text-area" 
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        ></textarea>
      </div>
    </div>
  )
}

export default TextArea;