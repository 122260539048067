import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import { InboxIcon } from '@heroicons/react/24/outline';

import EmptyState from '../../common/EmptyState';
import LoadingContainer from '../../common/LoadingContainer';

import DecisionsList from './DecisionsList';

import firebase from '../../../util/firebase';
import {showDecisionView} from '../../../util/navigation';
import {logScreenView} from '../../../util/analytics';
import {useDecisionsInitialLoadFetch} from '../../../util/decisions';

import {
  syncPastDecisions,
} from '../../../actions/decisions';

const PastDecisions = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userRef = firebase.firestore().collection('Users').doc(user.uid);
  const decisions = useSelector((store) => store.pastDecisions);

  useDecisionsInitialLoadFetch(decisions, 'past', dispatch);

  useEffect(() => {
    const decisionsUnsubscribe = userRef
      .collection('Decisions')
      .where('status', '==', 'published')
      .where('monthsToNextReview', '==', null)
      .where('nextReviewDate', '==', null)
      .orderBy('lastReviewedOn', 'desc')
      .onSnapshot((snapshot) => dispatch(syncPastDecisions(snapshot)));

    logScreenView('PastDecisions');      

    return () => {
      decisionsUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (decisions.isLoading) {
    return <LoadingContainer />;
  }

  return (
    <>
      {decisions.list.length > 0 ? (
        <DecisionsList 
          decisions={decisions.list} 
          decisionType="past" 
          onClickDecision={(decision) => showDecisionView(history, decision.id, {prevLocation: location.pathname})} 
        />
      ): (
        <EmptyState 
          icon={<InboxIcon className="h-12 w-12 text-gray-400" />}
          title="No Past Decisions" 
          description="All your past decisions will show up here." 
        />
      )}
    </>
  )
}

export default PastDecisions;