import GettingStartedSteps from '../../common/GettingStartedSteps';

const GettingStartedSettings = () => {
  return (
    <div className="card">
      <h3 className="card-title">Getting Started</h3>
      <div className="card-body">
        <GettingStartedSteps />
      </div>
    </div>
  )
}

export default GettingStartedSettings;