import axios from 'axios';
import {round, map, sortBy, find, capitalize} from 'lodash';

import {REVENUE_CAT_PUBLIC_KEY} from './constants';
import firebase from './firebase';
import {logError} from './errors';

const revenueCatOptions = {
  headers: {
    'X-Platform': 'stripe',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${REVENUE_CAT_PUBLIC_KEY}`
  }
};

const getProductInfo = (offering) => {
  const {product} = offering;
  const price = product.price.unit_amount / 100;
  const interval = product.price.recurring.interval;

  const name = `$${price}/${capitalize(interval)}`;

  return { 
    title: name,
    priceString: `$${price}`,
    intervalString: capitalize(interval + 'ly'),
    trialPeriod: {
      intro_price_period_unit: 'days',
      intro_price: 0,
      intro_price_period_number_of_units: product.price.recurring.trial_period_days
    }
  };
}

const getOfferingForProductIdentifier = (productIdentifier, offerings) => {
  return find(
    offerings,
    (offer) => offer.product.id === productIdentifier
  );
};

const getMonthlyPrice = (offering, includeOffering = false) => {
  const {product} = offering;
  const price = product.price.unit_amount / 100;
  const interval = product.price.recurring.interval;
  let finalPrice = null;

  switch (interval) {
    case 'month':
      finalPrice = round(price, 2);
      break;
    case 'year':
      finalPrice = round(price / 12, 2);
      break;
    default:
      finalPrice = null;
  }

  return includeOffering
    ? {
        offering,
        price: finalPrice,
      }
    : finalPrice;
};

const isMostValuableOffer = (offering, allOfferings) => {
  if (allOfferings.length <= 1) {
    return false;
  }
  const offeringMonthlyPrice = getMonthlyPrice(offering);

  const allOfferingsMonthlyPrice = map(allOfferings, (o) =>
    getMonthlyPrice(o)
  );
  const bestOfferingMonthlyPrice = allOfferingsMonthlyPrice.sort()[0];

  return offeringMonthlyPrice === bestOfferingMonthlyPrice;
};

const getMostValuableOffer = (allOfferings) => {
  const allOfferingsWithMonthlyPrice = allOfferings.map(getMonthlyPrice, true);
  const bestOffering = sortBy(allOfferingsWithMonthlyPrice, 'price')[0];

  return bestOffering ? bestOffering.offering : null;
};

const hasFullAccess = async (user) => {
  return user.subscriptionInfo && user.subscriptionInfo.entitlements 
    && user.subscriptionInfo.entitlements.active.paid 
    && user.subscriptionInfo.entitlements.active.paid.isActive;
};

const shouldShowPaywall = async (user) => {
  const isSubscribed = await hasFullAccess(user);
  if (isSubscribed) {
    return false;
  }

  return user.requiresPaidTier;
};

// Revenue Cat REST API calls
const getSubscriberInfo = async (user) => {
  const url = `https://api.revenuecat.com/v1/subscribers/${user.uid}`;
  const res = await axios.get(url, revenueCatOptions);
  return res.data.subscriber;
}

const updateSubscriberInfo = async (user) => {
  try {
    const url = `https://api.revenuecat.com/v1/subscribers/${user.uid}/attributes`;
    await axios.post(url, {
      attributes: {
        "$email": {
          value: user.email || '',
        },
        "$displayName": {
          value: user.name || '',
        }
      }
    }, revenueCatOptions);
    return true;
  } catch (e) {
    logError(e);
  }
}

const getOfferings = async (user) => {
  const url = `https://api.revenuecat.com/v1/subscribers/${user.uid}/offerings`;
  
  const res = await axios.get(url, revenueCatOptions);
  const currentOfferingId = res.data.current_offering_id;
  const currentOffering = find(res.data.offerings, (o) => o.identifier === currentOfferingId);
  const productIds = map(currentOffering.packages, 'platform_product_identifier');

  // fetch products info
  const httpsCallable = firebase.functions().httpsCallable('getStripeProducts');
  const {data: products} = await httpsCallable({
    userId: user.uid,
    productIds
  });
  return {
    ...currentOffering,
    packages: currentOffering.packages.map((p) => ({
      ...p,
      product: find(products, (prod) => prod.id === p.platform_product_identifier)
    }))
  };
}

const getExistingSubscription = (subscriptionInfo, offerings) => {
  const entitlementExists = subscriptionInfo && subscriptionInfo.entitlements && subscriptionInfo.entitlements.active;
  const existingSubscription = entitlementExists && subscriptionInfo.entitlements.active.paid;

  const existingSubscriptionOffering = entitlementExists && existingSubscription
    ? getOfferingForProductIdentifier(
        subscriptionInfo.entitlements.active.paid.productIdentifier,
        offerings
      )
    : null;

  return {
    existingSubscription,
    existingSubscriptionOffering,
  };
}

export {
  isMostValuableOffer,
  getMonthlyPrice,
  getMostValuableOffer,
  getOfferingForProductIdentifier,
  hasFullAccess,
  shouldShowPaywall,
  getOfferings,
  getSubscriberInfo,
  getProductInfo,
  getExistingSubscription,
  updateSubscriberInfo,
};
