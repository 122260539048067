import {DateTime} from 'luxon';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import TagsList from './TagsList';

import {showTag} from '../../../actions/tags';

import {dueForReview} from '../../../util/copy';
import {showTagView} from '../../../util/navigation';

const DecisionCard = ({decision, type, onClick}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickTag = (tag) => {
    dispatch(showTag(tag));
    showTagView(history, tag);
  }

  const renderCalendarIcon = () => (
    <svg className="w-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
  )

  const renderImportantIcon = () => (
    <svg className="w-4 mr-1 mt-0.5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
    </svg>
  )

  const renderDateInfo = () => {
    let dateString;
    let isDueForReview = false;
    switch (type) {
      case 'in_progress':
        const dateTime = DateTime.fromMillis(decision.updatedAt);
        const formattedString = DateTime.local().hasSame(dateTime, 'day') ? dateTime.toRelative() : dateTime.toLocaleString();
        dateString = `Last Modified: ${formattedString}`;
        break;
      case 'to_review':
        if (decision.nextReviewDate) {
          const reviewDate = DateTime.fromMillis(decision.nextReviewDate);
          isDueForReview = (reviewDate < DateTime.local()) || DateTime.local().hasSame(decision.nextReviewDate, 'day');
  
          dateString = decision.nextReviewDate
            ? isDueForReview
              ? dueForReview
              : `Review Scheduled for ${DateTime.fromMillis(
                  decision.nextReviewDate
                ).toLocaleString(DateTime.DATE_SHORT)}`
            : null;
        } else {
          dateString = null;
        }
        break;
      default:
        if (decision.lastReviewedOn) {
          dateString = `Last Reviewed ${DateTime.fromMillis(decision.lastReviewedOn).toLocaleString(DateTime.DATE_SHORT)}`;
        } else {
          dateString = `Not yet reviewed`;
        }
    }

    return (
      <p className="text-sm flex text-gray-500 dark:text-gray-400 mt-3 items-start">
        {isDueForReview ? renderImportantIcon() : renderCalendarIcon()}
        {dateString}
      </p>
    );
  }

  return (
    <div onClick={onClick} className="card cursor-pointer">
      <div className="card-body">
        <h4>{decision.title && decision.title !== '' ? decision.title : 'Untitled'}</h4>
        {renderDateInfo()}
        {decision.tags && decision.tags.length > 0 && (
          <div>
            <TagsList tags={decision.tags} onClick={(tag) => onClickTag(tag)} />
          </div>
        )}
      </div>
    </div>
  )
}

export default DecisionCard;