import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {DateTime} from 'luxon';
import { InboxIcon } from '@heroicons/react/24/outline';

import EmptyState from '../../common/EmptyState';
import LoadingContainer from '../../common/LoadingContainer';

import DecisionsList from './DecisionsList';

import firebase from '../../../util/firebase';
import {showDecisionView} from '../../../util/navigation';
import {logScreenView} from '../../../util/analytics';
import {useDecisionsInitialLoadFetch} from '../../../util/decisions';

import {
  syncScheduledDecisions
} from '../../../actions/decisions';

const ScheduledDecisions = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userRef = firebase.firestore().collection('Users').doc(user.uid);
  const decisions = useSelector((store) => store.scheduledForReviewDecisions);

  useDecisionsInitialLoadFetch(decisions, 'scheduled', dispatch);

  useEffect(() => {
    const decisionsUnsubscribe = userRef
      .collection('Decisions')
      .where('status', '==', 'published')
      .where('nextReviewDate', '>', DateTime.local().endOf('day').toMillis())
      .orderBy('nextReviewDate', 'asc')
      .onSnapshot((snapshot) =>
        dispatch(syncScheduledDecisions(snapshot))
      );
    
    logScreenView('ScheduledDecisions');

    return () => {
      decisionsUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (decisions.isLoading) {
    return <LoadingContainer />;
  }

  return (
    <>
      {decisions.list.length > 0 ? (
        <DecisionsList 
          decisions={decisions.list} 
          decisionType="to_review" 
          onClickDecision={(decision) => showDecisionView(history, decision.id, {prevLocation: location.pathname})} 
        />
      ) : (
        <EmptyState 
          icon={<InboxIcon className="h-12 w-12 text-gray-400" />}
          title="No Decisions Scheduled for Review" 
          description="When you have decisions scheduled for review, they will show up here." 
        />
      )}
    </>
  )
}

export default ScheduledDecisions;