const initialState = {
  visible: false,
  type: null,
  message: null,
  position: null,
  icon: null,
  showDismiss: false,
  autoDismissAfter: null,
};

function toast(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        visible: true,
        ...action.toast,
      };
    case 'HIDE_TOAST':
      return initialState;
    default:
      return state;
  }
}

export default toast;
