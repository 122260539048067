import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {DateTime} from 'luxon';
import {orderBy, round} from 'lodash';

import NotFound from '../NotFound';

import TagsList from './TagsList';
import EditTagsModal from './EditTagsModal';
import ScheduleReviewModal from './ScheduleReviewModal';
import AdditionalDecisionActionsMenu from './AdditionalDecisionActionsMenu';
import DecisionsBreadcrumbs from './DecisionsBreadcrumbs';

import ReviewCard from '../reviews/ReviewCard';
import CreateReview from '../reviews/CreateReview';
import DraftReviews from '../reviews/DraftReviews';
import EditReview from '../reviews/EditReview';

import TimeSeriesGraph from '../analyze/TimeSeriesGraph';

import PageContainer from '../../common/PageContainer';
import Emoji from '../../common/Emoji';
import LoadingContainer from '../../common/LoadingContainer';
import EmptyState from '../../common/EmptyState';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import SidePanel from '../../common/SidePanel';
import ExplainerModal from '../../common/ExplainerModal';

import {dueForReview} from '../../../util/copy';
import firebase from '../../../util/firebase';
import {isDueForReview, renderBrierScoreExplainer} from '../../../util/decisions';
import {mapToEmoji} from '../../../util/emojis';
import {
  timeSeriesPeriodOptions,
  getInfoFromTimeSeries,
} from '../../../util/timeSeries';
import {
  showEditDecisionView,
  showTagView,
} from '../../../util/navigation';
import {getSkillVsLuckForWeight, getSkillVsLuckDifferenceSummary} from '../../../util/skillLuck';
import {isDefined} from '../../../util/helpers';

import {showTag} from '../../../actions/tags';
import {syncCurrentDecision} from '../../../actions/decisions';
import {
  syncDecisionReviews,
  syncDraftReviews,
} from '../../../actions/reviews';
import { logError } from '../../../util/errors';

const DecisionDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const {decisionId} = useParams();
  if (!decisionId) {
    // redirect if there is no decision to lookup
    history.push('/');
  }
  const showDecision = useSelector((store) => store.decisions.showDecision);
  let decision;
  let notFound = useRef(false);
  if (showDecision && (showDecision.id === decisionId)) {
    decision = showDecision;
  }

  const reviews = useSelector((store) =>
    orderBy(store.reviews.list, 'createdAt', 'desc')
  );
  const editBufferPeriodValues = useSelector(
    (store) => store.config.editBufferPeriod.decisions
  );
  const drafts = useSelector((store) => store.reviews.drafts);
  const reviewsDecisionId = useSelector((store) => store.reviews.decisionId);
  const [reviewsLoading, updateReviewsLoading] = useState(false);
  const [timeSeriesTimePeriodIndex, setTimeSeriesTimePeriodIndex] = useState(0);
  const [isSidePanelVisible, setSidePanelVisibility] = useState(false);
  const [sidePanelView, setSidePanelView] = useState(null);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isBrierScoreExplainerVisible, setBrierScoreExplainerVisibility] = useState(false);
  const [modalView, setModalView] = useState(null);
  const [editReview, setEditReview] = useState(null);

  const decisionRef = firebase.firestore()
    .collection('Users')
    .doc(user.uid)
    .collection('Decisions')
    .doc(decisionId);

  const reviewsRef = firebase.firestore()
    .collection('Users')
    .doc(user.uid)
    .collection('Decisions')
    .doc(decisionId)
    .collection('Reviews')
    .where('status', '==', 'published');

  const draftReviewsRef = firebase.firestore()
    .collection('Users')
    .doc(user.uid)
    .collection('Decisions')
    .doc(decisionId)
    .collection('Reviews')
    .where('status', '==', 'draft')
    .limit(1);

  const timeSeriesData = getInfoFromTimeSeries(
    reviews,
    timeSeriesPeriodOptions[timeSeriesTimePeriodIndex]
  );

  useEffect(() => {
    const decisionUnsubscribe = decisionRef.onSnapshot((docSnapshot) => {
      if (docSnapshot.exists) {
        dispatch(syncCurrentDecision(docSnapshot));
      } else {
        notFound.current = true;
      }
    }, (error) => {
      logError(error);
    });

    return () => {
      decisionUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (decision) {
      const reviewsUnsubscribe = reviewsRef.onSnapshot((querySnapshot) =>
        dispatch(syncDecisionReviews(decision, querySnapshot))
      );
      const draftReviewsUnsubscribe = draftReviewsRef.onSnapshot(
        (querySnapshot) => dispatch(syncDraftReviews(decision, querySnapshot))
      );

      return () => {
        reviewsUnsubscribe();
        draftReviewsUnsubscribe();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decision]);

  useEffect(() => {
    if (decision && reviewsDecisionId === decision.id) {
      updateReviewsLoading(false);
    }
  }, [reviews, reviewsDecisionId, decision]);

  if (!decision && !notFound.current) {
    return null;
  } else if (!decision && notFound.current) {
    return <NotFound />;
  }

  const renderModalContent = () => {
    if (modalView === 'scheduleReview') {
      return (
        <ScheduleReviewModal
          decision={decision}
          onDismiss={() => toggleScheduleReview(false)}
        />
      )
    }

    if (modalView === 'editTags') {
      return (
        <EditTagsModal
          decision={decision}
          onDismiss={() => toggleEditDecisionTagsView(false)}
        />
      );
    }

    return null;
  }

  const renderSidePanelContent = () => {
    if (sidePanelView === 'createReview') {
      return (
        <CreateReview 
          onDismiss={() => toggleCreateReview(false)} 
          decision={decision} 
        />
      );
    }
    if (sidePanelView === 'draftReviews') {
      return (
        <DraftReviews 
          onDismiss={() => toggleDraftReviews(false)} 
          decision={decision} 
          onShow={(review) => {
            toggleDraftReviews(false);
            setEditReview(review);
            toggleEditDraftReview(true);
          }}
        />
      );
    }
    if (sidePanelView === 'editDraft') {
      return (
        <EditReview
          decision={decision}
          review={editReview}
          onBack={() => {
            toggleDraftReviews(true);
            setEditReview(null);
          }}
          onDismiss={() => toggleEditDraftReview(false)}
        />
      );
    }

    return null;
  }

  const toggleCreateReview = (show) => {
    setSidePanelView('createReview');
    setSidePanelVisibility(show);
  }

  const toggleScheduleReview = (show) => {
    setModalView('scheduleReview');
    setModalVisibility(show);
  }

  const toggleEditDecisionTagsView = (show) => {
    setModalView('editTags');
    setModalVisibility(show);
  }

  const toggleEditDraftReview = (show) => {
    setSidePanelView('editDraft');
    setSidePanelVisibility(show);
  }

  const toggleDraftReviews = (show) => {
    setSidePanelView('draftReviews');
    setSidePanelVisibility(show);
  }

  const onClickTag = (tag) => {
    dispatch(showTag(tag));
    showTagView(history, tag);
  }

  const renderScheduleReview = () => {
    return (
      <div
        onClick={() => toggleScheduleReview(true)}>
        <p className="text-indigo-700 cursor-pointer">Schedule Review</p>
      </div>
    );
  };

  const renderDrafts = () => {
    return (
      drafts.length > 0 && (
        <div className="text-center">
          <p className="text-indigo-700 cursor-pointer" onClick={() => toggleDraftReviews(true)}>
            View Drafts
          </p>
        </div>
      )
    );
  };

  const renderReviews = () => {
    if (reviewsLoading || reviewsDecisionId !== decisionId) {
      return <LoadingContainer />;
    }

    if (reviews.length === 0) {
      return (
        <>
          <EmptyState
            size="sm"
            description={
              decision.nextReviewDate
                ? isDueForReview(decision)
                  ? dueForReview
                  : `You have a review scheduled for\n${DateTime.fromMillis(
                      decision.nextReviewDate
                    ).toLocaleString(DateTime.DATE_FULL)}`
                : 'No review scheduled.'
            }>
            {decision.nextReviewDate && isDueForReview(decision) && (
              <div className="sm:hidden object-center fixed bottom-5 shadow-lg left-5 right-5">
                <Button 
                  additionalClasses={`btn-primary btn-block object-center`}
                  onClick={() => toggleCreateReview(true)}
                  label="Add Review"
                />
              </div>
            )}
            {!decision.nextReviewDate && renderScheduleReview()}
            {renderDrafts()}
          </EmptyState>
        </>
      );
    }

    return (
      <div className="min-h-full">
        {decision.nextReviewDate && (
          <EmptyState
            size="sm"
            description={
              isDueForReview(decision)
                ? dueForReview
                : `Next Review Scheduled for\n${DateTime.fromMillis(
                    decision.nextReviewDate
                  ).toLocaleString(DateTime.DATE_FULL)}`
            }>
            {!decision.nextReviewDate && renderScheduleReview()}
            {renderDrafts()}
          </EmptyState>
        )}
        <div className="mt-4 grid grid-cols-1 space-y-4 px-4 sm:px-0">
          {reviews.map((review) => (
            <ReviewCard
              key={review.id}
              review={review}
              decision={decision}
            />
          ))}
        </div>
      </div>
    );
  }

  const renderDecisionActions = () => {
    const additionalOptions = [{
      label: `${decision.nextReviewDate ? 'Re-Schedule' : 'Schedule'} Review`,
      onClick: () => toggleScheduleReview(true),
    }];

    let editBufferPeriod
    if (decision.decisionDate) {
      editBufferPeriod = DateTime.fromMillis(decision.decisionDate).plus({
        [editBufferPeriodValues.interval]: editBufferPeriodValues.amount
      });
    } else {
      editBufferPeriod = DateTime.local().plus({
        [editBufferPeriodValues.interval]: editBufferPeriodValues.amount
      });
    }

    const today = DateTime.local();
    if (
      today < editBufferPeriod &&
      reviews.length === 0
    ) {
      additionalOptions.push({
        label: "Edit Decision",
        onClick: () => {
          showEditDecisionView(
            history, decisionId, {
              prevLocation: history.location.state && history.location.state.prevLocation 
                ? [history.location.state.prevLocation, location.pathname]
                : location.pathname
            }
          );
        }
      });
    } else {
      additionalOptions.push(
        {
          label: "Edit Tags",
          onClick: () => toggleEditDecisionTagsView(true)
        }
      );
    }

    if (drafts.length > 0) {
      additionalOptions.push(
        {
          label: "Review Drafts",
          onClick: () => toggleDraftReviews(true)
        }
      );
    }

    const actions = [];
    if (decision.nextReviewDate && isDueForReview(decision)) {
      actions.push(<Button 
        additionalClasses={`btn-primary ml-3 hidden sm:block`}
        onClick={() => toggleCreateReview(true)}
        label="Add Review"
      />)
    } else {
      additionalOptions.push({
        label: 'Add Review Now',
        onClick: () => {
          toggleCreateReview(true);
        }
      });
    }

    actions.unshift(<AdditionalDecisionActionsMenu options={additionalOptions} />);

    return actions;
  }

  return (
    <>
    <PageContainer 
      title={decision.title}
      headerActionItems={renderDecisionActions()}
      breadcrumbs={<DecisionsBreadcrumbs history={history} />}
    >
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        <DecisionInfo decision={decision} onClickTag={onClickTag} />
        {decision.numReviews > 1 && (
          <div>
            <div className="flex justify-between items-center py-6 px-4 sm:px-0 sm:mt-0">
              <h3>Overall Accuracy Score</h3>
              <h3>
                {round(decision.avgAccuracyScore, 1)}
              </h3>
            </div>
            {timeSeriesData.length > 1 && (
              <div className="px-4 sm:px-0">
                <TimeSeriesGraph
                  isLoading={false}
                  data={timeSeriesData}
                  timePeriodIndex={timeSeriesTimePeriodIndex}
                  handleChangeTimePeriodIndex={(selectedIndex) => {
                    setTimeSeriesTimePeriodIndex(
                      selectedIndex
                    );
                  }}
                />
              </div>
            )}
          </div>
        )}
        {isDefined(decision.avgBrierScore) && (
          <div className="flex justify-between items-center py-6 px-4 sm:px-0 sm:mt-0">
            <div className="flex items-center">
              <h3>
                Brier Score
              </h3>
              <span onClick={() => setBrierScoreExplainerVisibility(true)}>
                <svg className="w-5 h-5 ml-1 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>
            <h3>
              {round(decision.avgBrierScore, 2)}
            </h3>
          </div>
        )}
        {isDefined(decision.avgSkillLuckDifference) && (
          <div className="py-6 px-4 sm:px-0">
            <h3>Skill vs Luck</h3>
            <p className="mt-3">
              For this decision, you {getSkillVsLuckDifferenceSummary(decision.avgSkillLuckDifference)}
            </p>
          </div>
        )}
        <div className="py-6">
          <h3 className="px-4 sm:px-0 sm:mt-0">Reviews</h3>
          {renderReviews()}
        </div>
      </div>
      <SidePanel
        isVisible={isSidePanelVisible}
        onDismiss={() => setSidePanelVisibility(false)}
      >
        {renderSidePanelContent()}
      </SidePanel>
      <Modal isVisible={isModalVisible} onDismiss={() => setModalVisibility(false)}>
        {renderModalContent()}
      </Modal>
      <ExplainerModal 
        isVisible={isBrierScoreExplainerVisible}
        onDismiss={() => setBrierScoreExplainerVisibility(false)} 
        title="Brier Score"
      >
        {renderBrierScoreExplainer()}
      </ExplainerModal>
    </PageContainer>
    </>
  )
}

const DecisionInfo = ({decision, onClickTag}) => {
  const decisionDate = decision.decisionDate ? DateTime.fromMillis(decision.decisionDate) : DateTime.local();
  const {skill, luck} = getSkillVsLuckForWeight(decision.skillLuckWeight);
  const feelingOptions = useSelector((store) => store.config.decisionFeelings);

  return (
    <div className="pb-6 px-4 sm:px-0 sm:mt-0 grid grid-cols-1 space-y-4">
      <p className="mt-2 text-gray-500 text-sm">
        Made on: {decisionDate.toLocaleString(DateTime.DATE_FULL)}
      </p>
      {decision && decision.tags.length > 0 && (
        <TagsList tags={decision.tags} onClick={(tag) => onClickTag(tag)} />
      )}
      <DecisionField label="The Situation" value={decision.context} />
      {decision.initialFeeling && feelingOptions && feelingOptions.map((o) => o.value).includes(decision.initialFeeling) && (
        <DecisionField label="Initial Feeling">
          <Emoji name={mapToEmoji(decision.initialFeeling)} size="lg" />
        </DecisionField>
      )}
      <DecisionField
        label="Expected Outcome"
        value={decision.expectedOutcome}
      />
      {decision.outcomeEstimates && decision.outcomeEstimates.length > 0 && (
        <DecisionField
          label="Outcome Estimates"
        >
          <OutcomeEstimatesTable outcomeEstimates={decision.outcomeEstimates} />
        </DecisionField>
      )}
      {decision && isDefined(decision.skillLuckWeight) && (
        <DecisionField label="Outcome Estimate for Skill vs Luck">
          <div className="py-2 px-0 flex items-start">
            <div className="text-center">
              <p className="text-xl font-bold">{skill}%</p>
              <p className="text-sm font-semibold">Skill</p>
            </div>
            <p className="text-xl font-bold mx-3">/</p>
            <div className="text-center">
              <p className="text-xl font-bold">{luck}%</p>
              <p className="text-sm font-semibold">Luck</p>
            </div>
          </div>
        </DecisionField>
      )}
    </div>
  );
};

const DecisionField = ({label, value, children}) => {
  return (
    <div>
      <p className="font-bold mb-2">{label}</p>
      {children || <p className="whitespace-pre-wrap">{value}</p>}
    </div>
  );
};

const OutcomeEstimatesTable = ({outcomeEstimates}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="table-wrap">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th scope="col" className="th text-left">
                    Outcome
                  </th>
                  <th scope="col" className="th text-right">
                    Probability
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {outcomeEstimates.map((outcomeEstimate, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="td">{outcomeEstimate.text}</td>
                      <td className="td font-semibold text-right">{round(outcomeEstimate.probability * 100, 0)}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DecisionDetails;