import {useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../../common/Button';
import SidePanelHeader from '../../common/SidePanelHeader';

import ReviewForm from './ReviewForm';

import {initialReviewState} from '../../../util/reviews';
import {logAndShowError} from '../../../util/errors';
import {shouldShowPaywall} from '../../../util/subscriptions';
import {isDefined} from '../../../util/helpers';

import {
  showLoadingModal,
  hideLoadingModal,
} from '../../../actions/loadingModal';
import {showPaywall} from '../../../actions/subscriptions';
import {displayToast} from '../../../actions/toasts';
import {saveReview} from '../../../actions/reviews';

const CreateReview = ({
  onDismiss, 
  decision,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const gettingStartedStepsStatuses = user.gettingStartedSteps;
  const isVisible = useSelector((store) => store.gettingStartedMenu && store.gettingStartedMenu.isVisible);
  const [review, updateReview] = useState({
    ...initialReviewState,
    skillLuckWeight: decision.skillLuckWeight,
    outcomeEstimates: decision.outcomeEstimates,
  });
  const changesMade = !_.isEqual(review, {
    ...initialReviewState,
    skillLuckWeight: decision.skillLuckWeight,
    outcomeEstimates: decision.outcomeEstimates,
  });

  const isValidReview = () => {
    let valid = true;
    const requiredFields = [
      'actualOutcome',
      'learningsAndReview',
      'accuracyScore',
    ];
    requiredFields.forEach((field) => {
      const value = review[field];
      if (!isDefined(value) || value === '') {
        valid = false;
      }
    });

    return valid;
  };

  const save = async (status) => {
    const reviewData = {
      ...review,
      status,
    };
    dispatch(showLoadingModal());
    try {
      if (status === 'published') {
        const requiresSubscription = await shouldShowPaywall(user);
        if (requiresSubscription) {
          dispatch(hideLoadingModal());
          dispatch(showPaywall({
            message: 'Subscribe to create more reviews',
          }));
          return;
        }
      }
      await dispatch(saveReview(decision, reviewData));
      dispatch(hideLoadingModal());
      dismiss();
      dispatch(
        displayToast({
          type: 'success',
          message: `${
            status === 'draft'
              ? 'Draft saved'
              : 'Review added'
          }!`,
        })
      );
    } catch (err) {
      dispatch(hideLoadingModal());
      logAndShowError(err);
    }
  };

  const handleDismiss = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (changesMade) {
      const dismissConfirmed = window.confirm("Looks like you've made some changes. Are you sure you want to navigate away without saving?");
      if (dismissConfirmed) {
        dismiss();
      }
    } else {
      dismiss();
    }
  }

  const dismiss = () => {
    updateReview(initialReviewState);
    onDismiss();
  }

  const renderFooter = () => {
    const extraPadding = (gettingStartedStepsStatuses && !gettingStartedStepsStatuses.isCompleted) && isVisible;

    return (
      <div className={`flex-shrink-0 px-4 py-4 flex justify-end space-x-3 ${extraPadding ? 'pb-16 sm:pb-20' : ''}`}>
        <div className="flex items-center justify-between">
          <Button
            label="Save Draft"
            onClick={(event) => {
              event.preventDefault(); 
              save('draft');
            }}
            isDisabled={!changesMade}
            additionalClasses="mr-3"
          />
          <Button
            label="Add Review"
            onClick={(event) => {
              event.preventDefault(); 
              save('published');
            }}
            isDisabled={!changesMade || !isValidReview()}
            additionalClasses="btn-primary"
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader title="Add Review" onDismiss={handleDismiss} />
        <ReviewForm decision={decision} review={review} onUpdate={updateReview} isDraft={true} />
      </div>
      {renderFooter()}
    </>
  );
}

export default CreateReview;