import _ from 'lodash';

const initialState = {};

const convertConfig = (result, parameter, key) => {
  const configs = result;
  configs[key] = JSON.parse(parameter.asString());
  return configs;
};

const config = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'RECEIVED_REMOTE_CONFIG':
      return _.reduce(action.configs, convertConfig, {});
    default:
      return state;
  }
}

export default config;
