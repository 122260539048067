import {
  setUser,
  resetUser,
  logLogin,
  logEvent,
  timeEvent,
} from '../util/analytics';

const track = (action) => {
  switch (action.type) {
    case 'LOGGED_IN':
      setUser(action.user);
      logLogin({method: action.provider});
      break;
    case 'LOGGED_OUT':
      resetUser();
      logEvent('logged_out');
      break;
    case 'SENT_LOGIN_CODE_VIA_EMAIL':
      logEvent('sent_email_login_code', {email: action.email});
      timeEvent('logged_in');
      break;
    case 'RECEIVED_USER':
      setUser(action.user);
      break;
    case 'DECISION_SAVED':
      if (action.decision.status === 'published') {
        logEvent('created_decision', {decisionId: action.decision.id});
      } else {
        logEvent('saved_decision_draft', {decisionId: action.decision.id});
      }
      break;
    case 'DECISION_DELETED':
      logEvent('draft_decision_deleted', {decisionId: action.decisionId});
      break;
    case 'REVIEW_SAVED':
      if (action.review.status === 'published') {
        logEvent('created_review', {reviewId: action.review.id});
      } else {
        logEvent('saved_review_draft', {reviewId: action.review.id});
      }
      break;
    case 'REVIEW_DELETED':
      logEvent('draft_review_deleted', {
        decisionId: action.decisionId,
        reviewId: action.reviewId,
      });
      break;
    case 'SUBSCRIBED_TO_SUBSCRIPTION':
      logEvent('subscribed_to_subscription', {
        subscription: action.subscription,
      });
      break;
    case 'UPDATED_USER_NOTIFICATION_SETTINGS':
      logEvent('updated_user_notification_settings', action.settings);
      break;
    case 'LINKED_CALENDAR':
      logEvent('linked_calendar', {provider: action.provider});
      break;
    case 'UNLINKED_CALENDAR':
      logEvent('unlinked_calendar', {provider: action.provider});
      break;      
    case 'HIDE_CONNECT_CALENDAR':
      logEvent('dismissed_connect_calendar');
      break;
    case 'TOGGLED_GETTING_STARTED_MENU':
      logEvent('toggled_getting_started_menu', {wasMinimized: !action.isMinimized});
      break;
    case 'SKIPPED_GETTING_STARTED_STEP':
      logEvent('skipped_getting_started_step', {step: action.step});
      break;
    case 'DISMISSED_GETTING_STARTED_MENU':
      logEvent('dismissed_getting_started_menu');
      break;
    default:
      break;
  }
}

export default track;
