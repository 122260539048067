const ENV = process.env.REACT_APP_ENV;
const SUPPORT_EMAIL = 'support@decisionjournalapp.com';
const SUPPORT_URL = 'https://decisionjournalapp.com';
const PRIVACY_POLICY_URL = 'https://decisionjournalapp.com/privacy';
const TERMS_OF_SERVICE_URL = 'https://decisionjournalapp.com/terms';
const GUIDES_URL = 'https://decisionjournalapp.com/guides';
const REVENUE_CAT_PUBLIC_KEY = process.env.REACT_APP_REVENUE_CAT_PUBLIC_KEY;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL;
const API_URL = process.env.REACT_APP_API_URL;
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_CALENDAR_CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID;

export {
  ENV,
  SUPPORT_EMAIL,
  SUPPORT_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  GUIDES_URL,
  REVENUE_CAT_PUBLIC_KEY,
  STRIPE_PUBLIC_KEY,
  WEB_APP_URL,
  API_URL,
  MIXPANEL_TOKEN,
  SENTRY_DSN,
  GOOGLE_API_KEY,
  GOOGLE_CALENDAR_CLIENT_ID,
};
