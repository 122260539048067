const initialState = {
  isVisible: false,
};

const loadinModal = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SHOW_LOADING_MODAL':
      return {
        ...state,
        isVisible: true,
      }

    case 'HIDE_LOADING_MODAL':
      return {
        ...state,
        isVisible: false,
      }

    default:
      return state;
  }
}

export default loadinModal;
