import {
  Axis,
  Grid,
  LineSeries,
  XYChart,
  Tooltip,
  buildChartTheme,
} from '@visx/xychart';
import {ParentSize} from '@visx/responsive';
import { MarkerCircle } from '@visx/marker';
import {parseInt} from 'lodash';

import LoadingContainer from '../../common/LoadingContainer';
import EmptyState from '../../common/EmptyState';
import SegmentedControl from '../../common/SegmentedControl';

import {
  timeSeriesPeriodOptions,
} from '../../../util/timeSeries';

const NUM_OF_TICKS = 4;

const TimeSeriesGraph = ({
  showFilters,
  filters,
  maxFiltersLength,
  onFilterChange,
  selectedChartFilter,
  data,
  isLoading,
  timePeriodIndex,
  handleChangeTimePeriodIndex,
  type,
}) => {
  const changeTimePeriodIndex = (selectedIndex) => {
    handleChangeTimePeriodIndex(selectedIndex);
  };

  const updateTimeSeriesFilter = (tag) => {
    onFilterChange(tag);
  };

  const theme = buildChartTheme({
    colors: ['rgb(79, 70, 229)'],
    tickLength: 5,
  });

  const renderLoading = () => {
    return <LoadingContainer size="large" />;
  }

  return (
    <div className="">
      <>
        <div>
          <SegmentedControl
            values={timeSeriesPeriodOptions}
            selectedIndex={timePeriodIndex}
            onChange={changeTimePeriodIndex}
          />
        </div>
        <div style={{height: 400}}>
          {isLoading ? renderLoading() 
          : (!isLoading && data.length < 1) ? (
            <EmptyState
              title="Needs more data"
              description="Add a couple more reviews to see a graph of scores over time."
            />
          ) : (
            <ParentSize>
              {({width}) => (
                <XYChart 
                  theme={theme}
                  height={400}
                  width={width}
                  xScale={{ 
                    type: 'band',
                    paddingInner: 0.8,
                    round: false,
                  }} 
                  yScale={{ 
                    type: 'linear', 
                    domain: type === 'brier' 
                      ? [1, 0]
                      : type === 'skillLuck'
                        ? [-1, 1]
                        : [0, 10],
                    round: false,
                  }}
                  margin={{top: 30, left: type === 'skillLuck' ? 120 : 30, right: 30, bottom: 30}}
                >
                  <Grid 
                    columns={false} 
                    rows={false} 
                    numTicks={NUM_OF_TICKS} 
                    stroke="currentColor" 
                    className="text-gray-500 dark:text-white"
                  />
                  <MarkerCircle id="marker-circle" fill="rgb(79, 70, 229)" size={2.4} refX={2.4} />
                  {type === 'skillLuck' ? (
                    <Grid 
                      columns={false} 
                      rows={true} 
                      numTicks={1} 
                      stroke="currentColor" 
                      className="text-gray-500 dark:text-white"
                    />
                  ) : null}
                  <LineSeries
                    dataKey="score"
                    data={data}
                    xAccessor={(d) => d.date}
                    yAccessor={(d) => d.score}
                    markerMid={data.length > 1 ? "url(#marker-circle)" : ""}
                    markerStart={data.length > 1 ? "url(#marker-circle)" : ""}
                    markerEnd={"url(#marker-circle)"}
                  />
                  <Axis
                    key={`time-axis`}
                    orientation="bottom"
                    numTicks={NUM_OF_TICKS}
                    axisClassName="text-gray-500 dark:text-white"
                    axisLineClassName="text-gray-500 dark:text-white"
                    labelClassName="text-gray-500 dark:text-white"
                    stroke="currentColor"
                    tickComponent={({ x, y, formattedValue }) => (
                      <g className="visx-group visx-axis-tick" transform="translate(0, 0)">
                        <line className="visx-line" x1={x} y1="0" x2={x} y2="5" fill="transparent" shapeRendering="crispEdges" stroke="currentColor" strokeLinecap="square"></line>
                        <svg x="0" y="0.125em" className="text-xs font-light">
                          <text transform="" x={x} y="16" pointerEvents="none" stroke="currentColor" textAnchor="middle">
                            <tspan x={x} dy="0em">{formattedValue}</tspan>
                          </text>
                        </svg>
                      </g>
                    )}
                  />
                  <Axis
                    key={`score-axis`}
                    orientation="left"
                    numTicks={type === 'skillLuck' ? 2 : NUM_OF_TICKS} 
                    axisClassName="text-gray-500 dark:text-white"
                    axisLineClassName="text-gray-500 dark:text-white"
                    labelClassName="text-gray-500 dark:text-white"
                    stroke="currentColor"
                    tickComponent={({ x, y, formattedValue }) => (
                      <g className="visx-group visx-axis-tick" transform="translate(0, 0)">
                        <line className="visx-line" x1={0} y1={y} x2={x} y2={y} fill="transparent" shapeRendering="crispEdges" stroke="currentColor" strokeLinecap="square"></line>
                        <svg x="-0.25em" y="0.25em" className="text-xs overflow-visible">
                          <text className="text-xs font-light" transform="" x={x - 5} y={y}  pointerEvents="none" stroke="currentColor" textAnchor="end">
                            <tspan x={x - 0} dy="0em">
                              {type === 'skillLuck' 
                                ? parseInt(formattedValue) > 0 
                                  ? 'Underestimate Skill' 
                                  : parseInt(formattedValue) === 0 
                                    ? 'Accurate'
                                    : 'Underestimate Luck'
                                : formattedValue}
                            </tspan>
                          </text>
                        </svg>
                      </g>
                    )}
                  />
                  <Tooltip
                    snapTooltipToDatumX
                    snapTooltipToDatumY
                    showVerticalCrosshair
                    showSeriesGlyphs
                    unstyled
                    className="bg-white dark:bg-gray-800 rounded py-1 px-2 absolute shadow"
                    renderTooltip={({ tooltipData }) => (
                      <div className="text-gray-900 dark:text-white">
                        <span className="font-bold text-sm">
                          {tooltipData.nearestDatum.datum.date}
                          {': '}
                          {tooltipData.nearestDatum.datum.score}
                        </span>
                      </div>
                    )}
                    verticalCrosshairStyle={{
                      strokeWidth: 1,
                      stroke: 'currentColor',
                      strokeDasharray: [5, 5]
                    }}
                  />
                </XYChart>
              )}
            </ParentSize>
          )}
        </div>
        {showFilters && (
          <div className="flex space-x-3 items-center mt-3 overflow-x-auto">
            <span style={{minWidth: 'fit-content'}} className="font-semibold mr-3 text-sm flex-1">Select Tag:</span>
            <ChartFilterButton
              isSelected={selectedChartFilter === null}
              label="All"
              onClick={() => updateTimeSeriesFilter(null)}
            />
            {filters.slice(0, maxFiltersLength).map((tag) => {
              return (
                <ChartFilterButton
                  key={tag.id}
                  isTag
                  isSelected={selectedChartFilter === tag.text}
                  label={tag.text}
                  onClick={() => updateTimeSeriesFilter(tag.text)}
                />
              );
            })}
          </div>
        )}
      </>
    </div>
  );
};

const ChartFilterButton = ({label, onClick, isSelected, isTag}) => {
  return (
    <div className={`chart-filter-button ${isSelected ? 'bg-indigo-600 text-white' : ''}`} onClick={onClick}>
      {isTag && (
        <svg className="h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
      )}
      <p className="flex-1 ">
        {label}
      </p>
    </div>
  );
};

export default TimeSeriesGraph;
