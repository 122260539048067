import {useState, useCallback} from 'react';
import {debounce, delay} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import EmptyState from '../../common/EmptyState';
import LoadingContainer from '../../common/LoadingContainer';

import DraftDecisionSearchResult from './DraftDecisionSearchResult';
import ToReviewDecisionSearchResult from './ToReviewDecisionSearchResult';
import PastDecisionSearchResult from './PastDecisionSearchResult';

import {
  showDecisionView,
  showEditDecisionView,
} from '../../../util/navigation';
import {logSearch, logSelectedContent} from '../../../util/analytics';

import {showDecision} from '../../../actions/decisions';
import {searchDecisions} from '../../../actions/search';
import {clearSearch} from '../../../actions/search';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const Search = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchQuery, updateSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const isLoading = useSelector((store) => store.search.isLoading);
  const searchResults = useSelector((store) => store.search.searchResults);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(
    debounce((text) => dispatch(searchDecisions(text)), 100),
    []
  );

  const handleUpdateSearchQuery = (event) => {
    const text = event.target.value;
    updateSearchQuery(text);
    if (text && text !== '') {
      logSearch({search_term: text});
      debouncedQuery(text);
    } else {
      dispatch(clearSearch());
    }
  };

  const delayHide = () => {
    // delay needed to ensure if user clicks the menu
    // the menu receives the click before the blur
    // 120ms is shortest time that it'll work
    if (showResults) {
      delay(() => setShowResults(false), 150);
    } else {
      setShowResults(false);
    }
  }

  const renderEmptyState = () => {
    return (
      <EmptyState
        size="sm"
        withoutBorder
        icon={searchQuery === '' ? null : <ExclamationTriangleIcon className="h-10 w-10 text-gray-400" />}
        title={searchQuery === '' ? null : 'No Results'}
        description={
          searchQuery === ''
            ? 'Search for your decisions above'
            : `No results were found for: ${searchQuery}`
        }
      />
    );
  };

  const onShowDecision = (decision) => {
    logSelectedContent({content_type: 'search_result', item_id: decision.id});
    updateSearchQuery('');
    dispatch(clearSearch());
    dispatch(showDecision(decision));
    showDecisionView(history, decision.id);
  };

  const onShowDraft = (decision) => {
    logSelectedContent({content_type: 'search_result', item_id: decision.id});
    updateSearchQuery('');
    dispatch(clearSearch());
    dispatch(showDecision(decision));
    showEditDecisionView(history, decision.id);
  };

  const renderResultRow = (result) => {
    if (result.status === 'draft') {
      return (
        <DraftDecisionSearchResult
          key={result.id}
          decision={result}
          onClick={() => onShowDraft(result)}
        />
      );
    }
    if (result.nextReviewDate) {
      return (
        <ToReviewDecisionSearchResult
          key={result.id}
          decision={result}
          onClick={() => onShowDecision(result)}
        />
      );
    }
    return (
      <PastDecisionSearchResult
        key={result.id}
        decision={result}
        onClick={() => onShowDecision(result)}
      />
    );
  };

  const renderResultList = () => {
    const isFilled = searchQuery && searchQuery !== '';
    if (showResults && isFilled) {
      return (
        <div className="search-results-container">
          {isLoading ? (
            <LoadingContainer />
          ) : searchResults.length > 0 ? (
            searchResults.map(renderResultRow)
          ) : renderEmptyState()}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="nav-search-container relative">
      <div className="max-w-lg w-full lg:max-w-xs">
        <label htmlFor="search" className="sr-only">Search</label>
        <div className="nav-search-icon">
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
          </div>
          <input 
            id="search" 
            className="nav-search" 
            placeholder="Search" 
            type="search" 
            name="search" 
            onFocus={() => setShowResults(true)}
            onBlur={delayHide}
            value={searchQuery}
            onChange={handleUpdateSearchQuery}
          />
        </div>
        {renderResultList()}
      </div>
    </div>
  );
}

export default Search;