import firebase from '../util/firebase';
import {logError} from '../util/errors';
import {updateUserWithCredentialData} from './user';
import {DateTime} from 'luxon';

const receivedSearchKey = (searchKey) => {
  return {
    type: 'RECEIVED_SEARCH_KEY',
    searchKey,
  };
};

const getUserSearchKey = (userId) => async (dispatch) => {
  try {
    const httpsCallable = firebase.functions().httpsCallable('getSearchKey');
    const response = await httpsCallable({userId});

    return dispatch(receivedSearchKey(response.data));
  } catch (e) {
    return logError(e);
  }
};

const sentLoginCodeViaEmail = (email) => {
  return {
    type: 'SENT_LOGIN_CODE_VIA_EMAIL',
    email,
  };
};

const logOut = () => async (dispatch) => {
  try {
    await firebase.auth().signOut();
    return dispatch({
      type: 'LOGGED_OUT',
    });
  } catch (e) {
    return logError(e);
  }
};

const logOutWithPrompt = () => (dispatch) => {
  // eslint-disable-next-line no-restricted-globals
  const confirmLogOut = confirm('Are you sure you want to log out?');
  if (confirmLogOut) {
    dispatch(logOut());
  }
};

const loginUser = (user, provider) => async (dispatch) => {
  dispatch(getUserSearchKey(user.uid));
  return dispatch({
    type: 'LOGGED_IN',
    user,
    provider,
  });
};

const createNewUserFromCredentials = async (userCredential) => {
  if (userCredential.additionalUserInfo.isNewUser) {
    try {
      // Ensure db user by creating them right away
      const httpsCallable = firebase.functions().httpsCallable('createNewUser');
      const response = await httpsCallable({
        userId: userCredential.user.uid,
      });
      return response;
    } catch (e) {
      return logError(e);
    }
  }
}

const loginUserWithApple = () => async (dispatch) => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  const userCredential = await firebase.auth().signInWithPopup(provider);
  await createNewUserFromCredentials(userCredential);
  updateUserWithCredentialData(userCredential);
  return dispatch(loginUser(userCredential.user.toJSON(), 'apple'));
}

const loginUserWithGoogle = () => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const userCredential = await firebase.auth().signInWithPopup(provider);
  await createNewUserFromCredentials(userCredential);
  updateUserWithCredentialData(userCredential);
  return dispatch(loginUser(userCredential.user.toJSON(), 'google'));
}

const loginUserWithMicrosoft = () => async (dispatch) => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  provider.addScope('email');
  provider.addScope('profile');
  const userCredential = await firebase.auth().signInWithPopup(provider);
  await createNewUserFromCredentials(userCredential);
  updateUserWithCredentialData(userCredential);
  return dispatch(loginUser(userCredential.user.toJSON(), 'microsoft'));
}

export {
  loginUserWithGoogle,
  loginUserWithApple,
  loginUserWithMicrosoft,
  loginUser,
  logOut,
  logOutWithPrompt,
  sentLoginCodeViaEmail,
  createNewUserFromCredentials,
};