import {combineReducers} from 'redux';

import calendar from './calendar';
import config from './config';
import decisions from './decisions';
import draftDecisions from './draftDecisions';
import pastDecisions from './pastDecisions';
import scheduledForReviewDecisions from './scheduledForReviewDecisions';
import toReviewDecisions from './toReviewDecisions';
import user from './user';
import search from './search';
import tags from './tags';
import tagSearch from './tagSearch';
import toasts from './toasts';
import reviews from './reviews';
import loadingModal from './loadingModal';
import timeSeries from './timeSeries';
import tagTimeSeries from './tagTimeSeries';
import subscriptions from './subscriptions';
import paywall from './paywall';
import gettingStartedMenu from './gettingStartedMenu';

export default combineReducers({
  calendar,
  config,
  decisions,
  draftDecisions,
  pastDecisions,
  scheduledForReviewDecisions,
  toReviewDecisions,
  user,
  search,
  tags,
  tagSearch,
  toasts,
  reviews,
  loadingModal,
  timeSeries,
  tagTimeSeries,
  subscriptions,
  paywall,
  gettingStartedMenu,
});
