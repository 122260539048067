import Tag from './Tag';

const TagsList = ({tags, onRemove, onClick}) => {
  const handleOnClick = (e, tag) => {
    e.stopPropagation();
    onClick(tag);
  }

  if (tags.length > 0) {
    return (
      <div className="flex flex-wrap mt-3">
        {tags.map((tag) => {
          const tagComponent = <Tag key={tag} tag={tag} onRemove={onRemove} />;

          if (onClick) {
            return (
              <span key={tag} className="cursor-pointer" onClick={(e) => handleOnClick(e, tag)}>
                {tagComponent}
              </span>
            );
          }

          return tagComponent;
        })}
      </div>
    );
  }

  return null;
};

export default TagsList;
