import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useLocation} from 'react-router-dom';

import LoadingContainer from '../../common/LoadingContainer';
import PageContainer from '../../common/PageContainer';
import EmptyState from '../../common/EmptyState';

import DecisionCard from '../decisions/DecisionCard';

import {showDecision} from '../../../actions/decisions';
import {loadTagDecisions} from '../../../actions/tags';

import {showDecisionView} from '../../../util/navigation';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const TagView = ({history}) => {
  const location = useLocation();
  const {tag} = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.tags.isLoading);
  const decisions = useSelector((store) => store.tags.decisions);

  useEffect(() => {
    dispatch(loadTagDecisions(tag));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const renderEmptyState = () => {
    return (
      <EmptyState
        icon={<ExclamationTriangleIcon className="h-12 w-12 text-gray-400" />}
        title="No Results"
        description="No decisions were found for this tag."
      />
    );
  };

  const onShowDecision = (decision) => {
    dispatch(showDecision(decision));
    showDecisionView(history, decision.id, {prevLocation: location.pathname});
  };

  const renderDecision = (decision) => {
    if (decision.nextReviewDate) {
      return (
        <DecisionCard
          key={decision.id}
          type="to_review"
          decision={decision}
          onClick={() => onShowDecision(decision)}
        />
      );
    }
    return (
      <DecisionCard
        key={decision.id}
        type="past"
        decision={decision}
        onClick={() => onShowDecision(decision)}
      />
    );
  };

  return (
    <PageContainer title={`Tag: ${tag}`}>
      <div className="px-4 sm:px-0 py-8">
        {isLoading ? (
          <LoadingContainer />
        ) : (
          decisions.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {decisions.map(renderDecision)}
            </div>
          ) : renderEmptyState()
        )}
      </div>
    </PageContainer>
  );
};

export default TagView;