import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Prompt, useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';

import PageContainer from '../../common/PageContainer';

import DecisionsBreadcrumbs from './DecisionsBreadcrumbs';
import DecisionForm from './DecisionForm';

import {initialDecisionState, DRAFT_STATUS} from '../../../util/decisions';
import {logAndShowError} from '../../../util/errors';
import {shouldShowPaywall} from '../../../util/subscriptions';
import {isDefined} from '../../../util/helpers';

import {saveDecision} from '../../../actions/decisions';
import {displayToast} from '../../../actions/toasts';
import {showPaywall} from '../../../actions/subscriptions';
import {
  showLoadingModal,
  hideLoadingModal
} from '../../../actions/loadingModal';

const CreateDecision = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const initialDecision = initialDecisionState;
  const query = new URLSearchParams(location.search);
  const user = useSelector((store) => store.user);
  const [isSaved, setIsSaved] = useState(false);
  const [decision, updateDecision] = useState(initialDecision);
  const changesMade = !_.isEqual(decision, initialDecision);

  useEffect(() => {
    if (query.get('title') || query.get('context')) {
      updateDecision({...decision, title: query.get('title'), context: query.get('context')});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidDecision = () => {
    let valid = true;
    const requiredFields = [
      'title',
      'context',
      'expectedOutcome',
      'monthsToNextReview',
    ];
    requiredFields.forEach((field) => {
      const value = decision[field];
      if (!isDefined(value) || value === '') {
        valid = false;
      }
    });

    return valid;
  };

  const save = async (status) => {
    const decisionData = {
      ...decision,
      status,
    };
    dispatch(showLoadingModal());
    try {
      if (status === 'published') {
        const requiresSubscription = await shouldShowPaywall(user);
        if (requiresSubscription) {
          dispatch(hideLoadingModal());
          dispatch(showPaywall({
            message: 'Subscribe to create more decisions',
          }));
          return;
        }
      }
      await dispatch(saveDecision(decisionData));
      dispatch(hideLoadingModal());
      setIsSaved(true);
      if (status === DRAFT_STATUS) {
        history.push('/');
      } else {
        history.push('/scheduled');
      }
      dispatch(
        displayToast({
          type: 'success',
          message: `${
            status === 'draft'
              ? 'Draft saved'
              : 'Decision added'
          }!`,
        })
      );
    } catch (err) {
      dispatch(hideLoadingModal());
      logAndShowError(err);
    }
  }

  const handleSaveDecision = async () => {
    if (isValidDecision()) {
      save('published');
    } else {
      showErrors();
    }
  };

  const showErrors = () => {
    const errorString =
      "Looks like you're missing a few things. You can add them and try again or Save as Draft and come back to it later.";

    alert(errorString);
  };

  return (
    <PageContainer 
      title="Add Decision"
      breadcrumbs={<DecisionsBreadcrumbs history={history} />}
    >
      <Prompt
        when={changesMade && !isSaved}
        message="Looks like you've made some changes. Are you sure you want to navigate away without saving?"
      />
      <DecisionForm 
        decision={decision}
        onUpdate={updateDecision}
        isEditing={false}
        isDraft={true}
        isSaveDraftDisabled={!changesMade}
        isSaveDisabled={changesMade ? !isValidDecision() : true}
        onSave={handleSaveDecision}
        onSaveDraft={() => save('draft')}
      />
    </PageContainer>
  )
}

export default CreateDecision;