import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import { InboxIcon } from '@heroicons/react/24/outline';

import EmptyState from '../../common/EmptyState';
import Button from '../../common/Button';
import LoadingContainer from '../../common/LoadingContainer';

import DecisionsList from './DecisionsList';

import firebase from '../../../util/firebase';
import {showCreateDecision, showEditDecisionView} from '../../../util/navigation';
import {logScreenView} from '../../../util/analytics';
import {useDecisionsInitialLoadFetch} from '../../../util/decisions';

import {
  syncDraftDecisions, 
  showDecision, 
} from '../../../actions/decisions';

const InProgressDecisions = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const decisions = useSelector((store) => store.draftDecisions);
  const userRef = firebase.firestore().collection('Users').doc(user.uid);

  useDecisionsInitialLoadFetch(decisions, 'in_progress', dispatch);

  useEffect(() => {
    const draftsUnsubscribe = userRef.collection('Decisions')
      .where('status', '==', 'draft')
      .orderBy('updatedAt', 'desc')
      .onSnapshot((snapshot) => dispatch(syncDraftDecisions(snapshot)));

    logScreenView('InProgressDecisions');

    return () => {
      draftsUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showEditDecision = (decision) => {
    dispatch(showDecision(decision));
    showEditDecisionView(history, decision.id, {prevLocation: location.pathname});
  }

  if (decisions.isLoading) {
    return <LoadingContainer />;
  }

  return (
    <>
      {decisions.list.length > 0 ? (
        <DecisionsList decisions={decisions.list} decisionType="in_progress" onClickDecision={showEditDecision} />
      ): (
        <EmptyState 
          icon={<InboxIcon className="h-12 w-12 text-gray-400" />}
          title="No Drafts" 
          description="When you save decision drafts they will show up here. Add one now by clicking the button below" 
          action={(
            <Button label="Add a Decision" additionalClasses="btn-primary" onClick={() => showCreateDecision(history)} />
          )}
        />
      )}
    </>
  )
}

export default InProgressDecisions;