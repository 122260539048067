import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {SENTRY_DSN, ENV} from './constants';

const setupErrorTracking = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: ENV,
    tracesSampleRate: 0.25,
  });
}

/**
 * Maps errors to a title and message to show the end user
 * @param {Error} error - error object
 */
const mapMessagesForErrors = (error) => {
  let title;
  let message;
  switch (error.code) {
    case 'auth/invalid-email':
      title = 'Invalid Email';
      message = 'The email used is invalid. Please try again.';
      break;
    case 'auth/invalid-action-code':
      title = 'Link Expired';
      message =
        'The link has expired. Please re-request an email link to login.';
      break;
    default:
      title = 'Error';
      message = error.message;
  }

  return {
    title,
    message,
  };
};

/**
 * Logs the error and shows an alert to the user
 * @param {Error} error - error object
 */
const logAndShowError = (error) => {
  logError(error);
  const {message} = mapMessagesForErrors(error);
  alert(message);
};

/**
 * Logs an error
 * @param {Error} error - error object
 */
const logError = (error) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
};

export {setupErrorTracking, logError, logAndShowError};
