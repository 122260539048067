import {DateTime} from 'luxon';

import firebase from '../util/firebase';

import {loadOutstandingDecisionsToReviewCount} from './decisions';

const decisionReviewsUpdate = (decision, reviews) => {
  return {
    type: 'REVIEWS_UPDATED',
    decision,
    reviews,
  };
};

const syncDecisionReviews = (decision, querySnapshot) => (dispatch) => {
  const list = [];
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      list.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  dispatch(decisionReviewsUpdate(decision, list));
};

const draftReviewsUpdate = (decision, drafts) => {
  return {
    type: 'DRAFT_REVIEWS_UPDATED',
    decision,
    drafts,
  };
};

const syncDraftReviews = (decision, querySnapshot) => (dispatch) => {
  const list = [];
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      list.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  dispatch(draftReviewsUpdate(decision, list));
};

const reviewSaved = (review) => {
  return {
    type: 'REVIEW_SAVED',
    review,
  };
};

const saveReview = (decision, reviewData, isUpdate) => async (
  dispatch,
  getState
) => {
  const currentUser = getState().user;
  const httpsCallable = firebase.functions().httpsCallable('saveReview');
  const {data: review} = await httpsCallable({
    userId: currentUser.uid,
    decisionId: decision.id,
    review: {
      ...reviewData,
      updatedAt: reviewData.updatedAt || DateTime.local().toMillis(),
      createdAt: reviewData.createdAt || DateTime.local().toMillis(),
    },
    isUpdate,
  });

  dispatch(reviewSaved(review));
  dispatch(loadOutstandingDecisionsToReviewCount());
};

const reviewDeleted = (decisionId, reviewId) => {
  return {
    type: 'REVIEW_DELETED',
    decisionId,
    reviewId,
  };
};

const deleteReview = (decisionId, reviewId) => async (dispatch, getState) => {
  // Dispatch the delete before waiting for server response for better animations
  dispatch(reviewDeleted(decisionId, reviewId));
  const currentUser = getState().user;
  const httpsCallable = firebase.functions().httpsCallable('deleteReview');
  return httpsCallable({
    userId: currentUser.uid,
    decisionId,
    reviewId,
  });
};

export {
  syncDecisionReviews,
  syncDraftReviews,
  saveReview,
  deleteReview,
};
