import {Link} from 'react-router-dom';
import { Menu } from '@headlessui/react';
import {useDispatch} from 'react-redux';

import UserProfileIcon from './UserProfileIcon';

import {logOutWithPrompt} from '../../../actions/login';

const UserMenu = () => {
  const dispatch = useDispatch();

  const logOutUser = (event) => {
    event.preventDefault();
    dispatch(logOutWithPrompt());
  }

  return (
    <div className="hidden sm:ml-6 sm:flex sm:items-center">
      <div className="ml-3 relative">
      <Menu>
        <span className="flex items-center">
          <span className="flex items-center relative mr-5">
            <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            <span id="updates" className="absolute left-0 bottom-0"></span>
          </span>
          <Menu.Button className="max-w-xs bg-white dark:bg-black flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-900" id="user-menu" aria-haspopup="true">
            <span className="sr-only">Open user menu</span>
            <UserProfileIcon />
          </Menu.Button>
        </span>
        <Menu.Items>
          <div className="dropdown-menu" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <Menu.Item>
              <Link className="dropdown-menu-item" to="/account">
                Your Account
              </Link>
            </Menu.Item>
            <Menu.Item>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="dropdown-menu-item" role="menuitem" onClick={logOutUser}>
                Log Out
              </a>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
      </div>
    </div>
  )
}

export default UserMenu;