import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';

import TagRowScore from './TagScoreRow';
import TimeSeriesGraph from './TimeSeriesGraph';
import AnalyzeTag from './AnalyzeTag';
import AnalyzeTags from './AnalyzeTags';
import TimeSeriesGraphNavMenu from './TimeSeriesGraphNavMenu';

import PageContainer from '../../common/PageContainer';
import SidePanel from '../../common/SidePanel';
import EmptyState from '../../common/EmptyState';
import ExplainerModal from '../../common/ExplainerModal';

import {
  showTagView,
} from '../../../util/navigation';
import {MAX_TAGS_FOR_ANALYTICS_TAB} from '../../../util/tags';
import {
  getInfoFromTimeSeries,
  timeSeriesPeriodOptions,
} from '../../../util/timeSeries';
import firebase from '../../../util/firebase';
import {getSkillVsLuckDifferenceSummary} from '../../../util/skillLuck';
import {renderBrierScoreExplainer, renderSkillVsLuckExplainer} from '../../../util/decisions';

import {
  showTag,
  syncTagsForAnalytics,
} from '../../../actions/tags';
import {loadTimeSeries} from '../../../actions/timeSeries';

const TOP_TAGS_LENGTH = 3;

const AnalyzeHome = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userRef = firebase.firestore().collection('Users').doc(user.uid);
  const tags = useSelector((store) => store.tags.list) || [];
  const [selectedChartFilter, setSelectedChartFilter] = useState(null);
  const [isSidePanelVisible, setSidePanelVisibility] = useState(false);
  const [sidePanelView, setSidePanelView] = useState(null);
  const [currentTag, setCurrentTag] = useState(null);
  const [currentTimeSeriesChart, setCurrentTimeSeriesChart] = useState('accuracy');
  const [isExplainerModalVisible, setExplainerModalVisibility] = useState(false);
  const [explainerModalType, setExplainerModalType] = useState(null);
  const timeSeriesStats = useSelector((store) => store.timeSeries.data);
  const timeSeriesIsLoading = useSelector(
    (store) => store.timeSeries.isLoading
  );
  const [timeSeriesTimePeriodIndex, setTimeSeriesTimePeriodIndex] = useState(0);

  useEffect(() => {
    dispatch(loadTimeSeries(selectedChartFilter));

    const tagsWithScoresUnsubscribe = userRef
      .collection('Tags')
      .where('avgAccuracyScore', '>=', 0)
      .orderBy('avgAccuracyScore', 'desc')
      .onSnapshot((snapshot) =>
        dispatch(syncTagsForAnalytics(snapshot))
      );

    return () => {
      tagsWithScoresUnsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeSeriesData = getInfoFromTimeSeries(
    timeSeriesStats,
    timeSeriesPeriodOptions[timeSeriesTimePeriodIndex],
    currentTimeSeriesChart === 'brier' 
      ? 'brierScore' 
      : currentTimeSeriesChart === 'skillLuck' ? 'skillLuckDifference' : 'accuracyScore'
  );

  const onClickTag = (tag) => {
    dispatch(showTag(tag));
    showTagView(history, tag);
  };

  const handleChartFilterChange = (tag) => {
    setSelectedChartFilter(tag);
    dispatch(loadTimeSeries(tag));
  };

  const toggleTagAnalyticsView = (show, tag) => {
    setCurrentTag(tag);
    setSidePanelView('analyzeTag');
    setSidePanelVisibility(show);
  };

  const toggleTagScoresView = (show) => {
    setSidePanelView('tagScores');
    setSidePanelVisibility(show);
  };

  const renderSidePanelContent = () => {
    if (sidePanelView === 'analyzeTag') {
      return (
        <AnalyzeTag
          tagId={currentTag.id}
          tagText={currentTag.text}
          onBack={() => {
            toggleTagScoresView(true);
            setCurrentTag(null);
          }}
          onDismiss={() => toggleTagAnalyticsView(false, currentTag)}
        />
      );
    }

    if (sidePanelView === 'tagScores') {
      return (
        <AnalyzeTags
          onShow={(tag) => {
            toggleTagScoresView(false);
            setCurrentTag(tag);
            toggleTagAnalyticsView(true, tag);
          }}
          onDismiss={() => toggleTagScoresView(false)}
        />
      )
    }

    return null;
  }

  return (
    <PageContainer title="Analyze">
      <>
        <div className="px-4 py-8 sm:px-0 space-y-3 sm:space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="card">
              <h3 className="card-title">Your Accuracy Score</h3>
              <div className="card-body">
                <div className="py-3 text-center flex-1 min-h-full justify-center flex flex-col">
                  <h4 className="text-5xl mb-2">
                    {user.avgAccuracyScore ? _.round(user.avgAccuracyScore, 1) : 'N/A'}
                  </h4>
                  <p className="text-sm">
                    {user.avgAccuracyScore
                        ? `For ${user.numReviews} ${user.numReviews === 1 ? 'decision' : 'decisions'}`
                        : 'Add some reviews to get a score.'}
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <h3 className="card-title">
                <span className="flex items-center">
                  Your Brier Score
                  <span onClick={() => {
                    setExplainerModalType('brier');
                    setExplainerModalVisibility(true);
                  }}>
                    <svg className="w-5 h-5 ml-1 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </span>
              </h3>
              <div className="card-body">
                <div className="py-3 text-center flex-1 min-h-full justify-center flex flex-col">
                  <h4 className="text-5xl mb-2">
                    {user.avgBrierScore ? _.round(user.avgBrierScore, 2) : 'N/A'}
                  </h4>
                  <p className="text-sm">
                    {user.avgBrierScore
                        ? `For ${user.numReviewsWithBrierScore} ${user.numReviewsWithBrierScore === 1 ? 'decision' : 'decisions'}`
                        : 'Add outcome estimates to your decisions and reviews to get a score.'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="card">
              <h3 className="card-title">
                <span className="flex items-center">
                  Skill vs Luck
                  <span onClick={() => {
                    setExplainerModalType('skillLuck');
                    setExplainerModalVisibility(true);
                  }}>
                    <svg className="w-5 h-5 ml-1 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </span>
              </h3>
              <div className="card-body">
                {user.avgSkillLuckDifference ? (
                  <div>
                    <p>Overall, you {getSkillVsLuckDifferenceSummary(user.avgSkillLuckDifference)}</p>
                  </div>
                ) : (
                  <EmptyState
                    title="Not enough data yet"
                    description="Once you've added a decision and review with skill vs luck weights, your results will show here."
                    size="sm"
                  />
                )}
              </div>
            </div>
            <div className="card">
              <h3 className="card-title">Top Tags</h3>
              {tags.length > 0 ? (
                <>
                  <div className="card-body divide-y divide-gray-200 dark:divide-gray-700 py-0">
                    {tags.slice(0, TOP_TAGS_LENGTH).map((tag) => {
                      return (
                        <TagRowScore
                          key={tag.text}
                          tag={tag}
                          onClickTag={onClickTag}
                          onClick={() => toggleTagAnalyticsView(true, tag)}
                        />
                      );
                    })}
                  </div>
                  {(tags.length > MAX_TAGS_FOR_ANALYTICS_TAB - 1) && (
                    <div className="card-footer">
                      <button className="flex items-center link" onClick={() => toggleTagScoresView(true)}>
                        See All
                        <svg className="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div className="card-body">
                  <EmptyState
                    title="Not enough data yet"
                    description="Add some more tags to your decisions to see your top performing tags here."
                    size="sm"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="card">
            <h3 className="card-title">Performance Over Time</h3>
            <div className="card-body">
              <TimeSeriesGraphNavMenu onChange={setCurrentTimeSeriesChart} currentSelection={currentTimeSeriesChart} />
              <TimeSeriesGraph
                showFilters
                filters={tags}
                onFilterChange={handleChartFilterChange}
                selectedChartFilter={selectedChartFilter}
                isLoading={timeSeriesIsLoading}
                data={timeSeriesData}
                timePeriodIndex={timeSeriesTimePeriodIndex}
                handleChangeTimePeriodIndex={(selectedIndex) => {
                  setTimeSeriesTimePeriodIndex(
                    selectedIndex
                  );
                }}
                type={currentTimeSeriesChart}
              />
            </div>
          </div>
        </div>
        <SidePanel
          isVisible={isSidePanelVisible}
          onDismiss={() => setSidePanelVisibility(false)}
        >
          {renderSidePanelContent()}
        </SidePanel>
      </>
      <ExplainerModal 
        isVisible={isExplainerModalVisible} 
        onDismiss={() => setExplainerModalVisibility(false)} 
        title={explainerModalType === 'brier' ? 'Brier Score' : 'Skill vs Luck'}
      >
        {explainerModalType === 'brier' ? renderBrierScoreExplainer() : renderSkillVsLuckExplainer()}
      </ExplainerModal>
    </PageContainer>
  );
}

export default AnalyzeHome;
