import firebase from '../util/firebase';
import {logError} from '../util/errors';

const fetchingSearchResults = () => {
  return {
    type: 'FETCHING_SEARCH_RESULTS',
  };
};

const fetchingSearchResultsFailed = () => {
  return {
    type: 'FETCHING_SEARCH_RESULTS_FAILED',
  };
};

const searchResultsUpdated = (results) => {
  return {
    type: 'SEARCH_RESULTS_UPDATED',
    results,
  };
};

const searchDecisions = (searchQuery) => async (dispatch, getState) => {
  try {
    dispatch(fetchingSearchResults());
    const {searchKey} = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('searchDecisions');
    const {data: results} = await httpsCallable({
      searchKey,
      searchQuery,
    });

    return dispatch(searchResultsUpdated(results));
  } catch (e) {
    dispatch(fetchingSearchResultsFailed());
    return logError(e);
  }
};

const clearSearch = () => {
  return {
    type: 'CLEAR_SEARCH',
  };
};

export {
  searchDecisions,
  clearSearch,
};
