import {Link, useRouteMatch, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import SelectInput from '../../common/SelectInput';

const DecisionsSubMenu = () => {
  const history = useHistory();
  const match = useRouteMatch("/:section");
  const page = (match && match.params.section) || 'in_progress';
  const decisionsToReview = useSelector((store) => store.decisions.outstandingDecisionsToReviewCount);

  const changePage = (page) => {
    if (page === 'in_progress') {
      history.push('/');
    } else {
      history.push(`/${page}`);
    }
  }

  return (
    <>
      <div className="px-4 sm:hidden">
        <SelectInput
          name="tabs"
          value={page}
          onChange={(event) => changePage(event.target.value)}
          options={[
            {value: "in_progress", label: "In Progress"},
            {value: "to_review", label: `To Review${decisionsToReview > 0 ? ' (' + decisionsToReview + ')' : ''}`},
            {value: "scheduled", label: "Scheduled for Review"},
            {value: "past", label: "Past"},
          ]}
        />
      </div>
      <div className="hidden sm:py-3 sm:block">
        <div className="page-sub-menu-wrap">
          <nav className="page-sub-menu" aria-label="Tabs">
            <Link to="/" className={`page-sub-menu-item ${page === 'in_progress' ? 'active' : ''}`}>
              In Progress
            </Link>
            <Link to="/to_review" className={`relative page-sub-menu-item ${page === 'to_review' ? 'active' : ''}`}>
              To Review
              {decisionsToReview > 0 && (
                <span className="indicator-badge">
                  {decisionsToReview}
                </span>
              )}
            </Link>
            <Link to="/scheduled" className={`page-sub-menu-item ${page === 'scheduled' ? 'active' : ''}`}>
              Scheduled for Review
            </Link>
            <Link to="/past" className={`page-sub-menu-item ${page === 'past' ? 'active' : ''}`}>
              Past
            </Link>
          </nav>
        </div>
      </div>
    </>
  )
}

export default DecisionsSubMenu;