import firebase from '../util/firebase';
import {
  defaultReviewPeriods,
  defaultDecisionFeelings,
  defaultEditBufferPeriod,
} from '../util/decisions';

const receivedConfig = (configs) => {
  return {
    type: 'RECEIVED_REMOTE_CONFIG',
    configs,
  };
};

const loadRemoteConfig = () => async (dispatch) => {
  firebase.remoteConfig().defaultConfig = {
    reviewPeriods: defaultReviewPeriods,
    decisionFeelings: defaultDecisionFeelings,
    editBufferPeriod: defaultEditBufferPeriod,
  }
  await firebase.remoteConfig().fetchAndActivate();

  const configValues = await firebase.remoteConfig().getAll();
  dispatch(receivedConfig(configValues));
};

// eslint-disable-next-line import/prefer-default-export
export {loadRemoteConfig};
