import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../../common/Button';
import SelectInput from '../../common/SelectInput';

import {logAndShowError} from '../../../util/errors';
import {shouldShowPaywall} from '../../../util/subscriptions';

import {showPaywall} from '../../../actions/subscriptions';
import {
  showLoadingModal,
  hideLoadingModal,
} from '../../../actions/loadingModal';
import {saveDecision} from '../../../actions/decisions';
import {displayToast} from '../../../actions/toasts';

const ScheduleReviewModal = ({decision, onDismiss}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const reviewPeriodOptions = useSelector(
    (store) => store.config.reviewPeriods
  );
  const [monthsToNextReview, updateMonthsToNextReview] = useState(
    reviewPeriodOptions[0].value
  ); // default to first option

  const handleDismiss = (e) => {
    e.preventDefault();
    onDismiss();
  }

  const save = async () => {
    const decisionData = {
      id: decision.id,
      monthsToNextReview,
    };
    dispatch(showLoadingModal());
    try {
      const requiresSubscription = await shouldShowPaywall(user);
      if (requiresSubscription) {
        dispatch(hideLoadingModal());
        dispatch(showPaywall(
          {
            message: 'Subscribe to schedule more reviews',
          },
        ));
        return;
      }
      await dispatch(saveDecision(decisionData));
      dispatch(hideLoadingModal());
      updateMonthsToNextReview(reviewPeriodOptions[0].value);
      onDismiss();
      dispatch(
        displayToast({
          type: 'success',
          message: 'Review scheduled!',
        })
      );
    } catch (err) {
      dispatch(hideLoadingModal());
      logAndShowError(err);
    }
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700">
      <div>
        <h3 className="modal-title">
          {decision.nextReviewDate ? 'Re-Schedule Review' : 'Schedule Review'}
        </h3>
        <div className="modal-body">
          <div className="form-control">
            <div className="sm:col-span-6">
              <label htmlFor="reviewPeriod" className="form-field-label">
                When would you like to review this decision?
              </label>
              <div className="mt-1">
                <SelectInput
                  name="reviewPeriod"
                  value={monthsToNextReview}
                  onChange={(event) => {
                    updateMonthsToNextReview(event.target.value || reviewPeriodOptions[0].value)
                  }}
                  options={reviewPeriodOptions.map(opt => ({
                    ...opt,
                    label: `in ${opt.label}`
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button label="Cancel" onClick={handleDismiss} additionalClasses="btn-block" />
        <Button label="Save" onClick={save} additionalClasses="btn-primary btn-block" />
      </div>
    </div>
  );
}

export default ScheduleReviewModal;