import {Link} from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="text-center justify-center items-center p-10 min-h-screen flex flex-col -mt-20">
      <h1 className="text-3xl sm:text-5xl mb-5">Whoopsies!</h1>
      <img src="/images/whoopsies.gif" alt="Whoopsies" />
      <p className="text-lg font-light mt-5">We couldn't find that page.</p>
      <div className="mt-5">
        <audio className="outline-none" controls src="https://firebasestorage.googleapis.com/v0/b/decision-journal-app.appspot.com/o/web_app%2Faudio%2Ftake-me-home.mp3?alt=media&token=a3391cda-e5e4-4c86-afcf-6e99b6ac2d20"></audio>
      </div>
      <div className="text-lg font-light mt-5">
        <Link to="/" className="btn btn-primary">Country roads, take me home</Link>
      </div>
    </div>
  )
}

export default NotFound;