/* eslint-disable import/no-anonymous-default-export */

import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {createStateSyncMiddleware, initMessageListener} from 'redux-state-sync';

import reducers from '../reducers';

import promise from './promise';
import array from './array';
import analytics from './analytics';

const logger = createLogger({
  collapsed: true,
  duration: true,
});

const stateSyncConfig = {
  // only listen to auth events between tabs
  whitelist: ['LOGGED_IN', 'LOGGED_OUT']
};

const stateSync = createStateSyncMiddleware(stateSyncConfig);

const createCustomStore = applyMiddleware(
  thunk,
  promise, 
  array, 
  analytics,
  stateSync,
  ...(process.env.NODE_ENV !== 'production' ? [logger] : [])
)(createStore);

const persistConfig = {
  key: `decisionJournal-${process.env.REACT_APP_ENV}`,
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  const store = createCustomStore(persistedReducer);
  initMessageListener(store);
  const persistor = persistStore(store);
  return { store, persistor };
};


