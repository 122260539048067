import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Button from '../common/Button';

import {
  showLoadingModal,
  hideLoadingModal,
} from '../../actions/loadingModal';
import {loginUserWithApple} from '../../actions/login';

import {logError, logAndShowError} from '../../util/errors';

const LoginWithAppleButton = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const loginWithApple = async () => {
    try {
      dispatch(showLoadingModal());
      await dispatch(loginUserWithApple());
      dispatch(hideLoadingModal());
      if (location.state && location.state.from) {
        const { from } = location.state;
        history.push(from);
      }
    } catch (e) {
      dispatch(hideLoadingModal());
      if (e.code === 'auth/popup-closed-by-user') {
        logError(e);
      } else {
        logAndShowError(e);
      }
    }
  }

  return (
    <Button 
      onClick={loginWithApple} 
      label="Continue with Apple" 
      // icon={<FontAwesomeIcon icon={faApple} />} 
      additionalClasses="btn-dark btn-block"
    />
  )
}

export default LoginWithAppleButton;