import React from 'react';
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

import './styles/index.css';

import configureStore from './store/configureStore';

import {setupErrorTracking} from './util/errors';
import {setupAnalytics} from './util/analytics';
import {initGapi} from './util/google';

const { store, persistor } = configureStore();

if (process.env.NODE_ENV === 'production') {
  setupErrorTracking();
}
setupAnalytics();
initGapi();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
