import { DateTime } from 'luxon';
import React from 'react';

const DraftReviewCell = ({review, onShow, onDelete}) => {
  const handleDelete = (e) => {
    e.preventDefault(); 
    onDelete();
  }

  return (
    <div className="py-3 flex items-center justify-between cursor-pointer">
      <div className="flex-1" onClick={() => onShow(review)}>
        <h4>Draft Review</h4>
        <div className="mt-3">
          <p className="text-sm text-gray-500">{`Last modified: ${DateTime.fromMillis(
            review.updatedAt
          ).toLocaleString()}`}</p>
        </div>
      </div>
      {onDelete && (
        <button onClick={handleDelete} className="rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2">
          <span className="sr-only">Delete draft</span>
          <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default DraftReviewCell;
