import {Transition} from '@headlessui/react';

const Modal = ({isVisible, children, onDismiss, size}) => {
  const handleDismiss = (e) => {
    e.preventDefault();
    onDismiss();
  }

  return (
    <Transition className="fixed z-10 inset-0 overflow-y-auto" show={isVisible}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 transition-opacity" 
        >
          <div className="modal-bg"></div>
        </Transition.Child>

        {/* Required to keep it centered vertically in the browser */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          className={`modal ${size === 'large' ? 'sm:max-w-xl' : ''}`}
        >
          <div className="absolute top-4 right-4 flex justify-end">
            <button onClick={handleDismiss} className="modal-dismiss">
              <span className="sr-only">Close modal</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          {children}
        </Transition.Child>
      </div>
    </Transition>
  )
}

export default Modal;