import React from 'react';
import {DateTime} from 'luxon';

import SearchResultCell from './SearchResultCell';

const DraftDecisionSearchResult = ({decision, onClick}) => {
  const date = decision.updatedAt || DateTime.local().toMillis();
  return (
    <SearchResultCell
      title={
        decision.title && decision.title !== '' ? decision.title : 'Untitled'
      }
      dateInfo={`Last Modified: ${DateTime.fromMillis(date).toRelativeCalendar()}`}
      onClick={onClick}
      isDraft
    />
  );
};

export default DraftDecisionSearchResult;
