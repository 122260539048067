import React from 'react';

import SearchResultCell from './SearchResultCell';

import {decisionReviewCalendarDay} from '../../../util/time';

const PastDecisionSearchResult = ({decision, onClick}) => {
  const reviewDate = decision.lastReviewedOn
    ? `Last Reviewed ${decisionReviewCalendarDay(decision.lastReviewedOn)}`
    : null;

  return (
    <SearchResultCell
      title={decision.title}
      onClick={onClick}
      dateInfo={reviewDate}
    />
  );
};

export default PastDecisionSearchResult;
