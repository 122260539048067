import {Link, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import UserProfileIcon from './UserProfileIcon';

import {logOutWithPrompt} from '../../../actions/login';

const SmallScreenNav = ({isVisible}) => {
  const match = useRouteMatch("/:section");
  const page = (match && match.params.section) || 'home';
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  const logOutUser = (event) => {
    event.preventDefault();
    dispatch(logOutWithPrompt());
  }

  return (
    <div className={`${!isVisible && 'hidden sm:hidden'}`}>
      <div className="pt-2 pb-3 space-y-1">
        <Link to="/" className={`small-nav menu-item ${page === 'home' && 'active'}`}>
          Decisions
        </Link>

        <Link to="/analyze" className={`small-nav menu-item ${page === 'analyze' && 'active'}`}>
          Analyze
        </Link>
      </div>
      <div className="pt-4 pb-3 border-t border-gray-200 dark:border-gray-800">
        <div className="flex items-center px-4">
          <div className="flex-shrink-0">
            <UserProfileIcon />
          </div>
          <div className="ml-3">
            <div className="text-base font-medium text-gray-800 dark:text-gray-500">{user.name}</div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">{user.email}</div>
          </div>
        </div>
        <div className="mt-3 space-y-1">
          <Link className={`small-nav sub-menu-item ${page === 'account' && 'active'}`} to="/account">
            Your Account
          </Link>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="small-nav sub-menu-item" onClick={logOutUser}>
            Log Out
          </a>
        </div>
      </div>
    </div>
  )
}

export default SmallScreenNav;