import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from '@headlessui/react';

import {hideToast} from '../../actions/toasts';
import { CheckIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const Toast = ({
  onDismiss,
}) => {
  const dispatch = useDispatch();
  const toast = useSelector((store) => store.toasts);

  useEffect(() => {
    let timer;
    if (toast.autoDismissAfter && toast.visible) {
      timer = setTimeout(() => dismiss(), toast.autoDismissAfter);
    }
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);

  const dismiss = () => {
    dispatch(hideToast());
    if (onDismiss) {
      onDismiss();
    }
  };

  const getColorClassForType = () => {
    switch (toast.type) {
      case 'success':
        return 'text-green-400';
      case 'error':
        return 'text-red-600';
      case 'connection':
        return 'text-red-600';
      case 'warning':
        return 'text-yellow-400';
      case 'info':
        return 'text-blue-400';
      default:
        return 'text-blue-400';
    }
  };

  const renderToastIcon = (iconType) => {
    switch(iconType) {
      case 'check':
        return <CheckIcon className={`h-6 w-6 ${getColorClassForType()}`} />;
      case 'exclamation-triangle':
        return <ExclamationTriangleIcon className={`h-6 w-6 ${getColorClassForType()}`} />;
      default:
        return <InformationCircleIcon className={`h-6 w-6 ${getColorClassForType()}`} />;
    }
  };

  return (
    <Transition
      show={toast.visible}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-10">
        <div className="max-w-sm w-full bg-white dark:bg-gray-900 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-center">
              {toast.icon && (
                <div className="flex-shrink-0">
                  {renderToastIcon(toast.icon)}
                </div>
              )}
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-semibold text-gray-900 dark:text-gray-200">
                  {toast.message}
                </p>
              </div>
              {toast.showDismiss && (
                <div className="ml-4 flex-shrink-0 flex">
                  <button onClick={dismiss} className="bg-white dark:bg-gray-900 text-gray-400 hover:text-gray-500 rounded-md inline-flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Close</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Toast;
