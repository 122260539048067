import {logError} from '../util/errors';
import {
  getOfferings,
} from '../util/subscriptions';

const receivedSubscriptionOfferings = (offerings) => {
  return {
    type: 'RECEIVED_SUBSCRIPTION_OFFERINGS',
    offerings,
  };
};

// get all available subscriptions
const getSubscriptionOfferings = () => async (dispatch, getState) => {
  try {
    const user = getState().user;
    const offerings = await getOfferings(user);
    if (
      offerings &&
      offerings.packages.length !== 0
    ) {
      return dispatch(
        receivedSubscriptionOfferings(offerings.packages)
      );
    }

    return dispatch(receivedSubscriptionOfferings([]));
  } catch (e) {
    return logError(e);
  }
};

const showPaywall = (paywall) => {
  return {
    type: 'SHOW_PAYWALL',
    paywall,
  };
};

const hidePaywall = () => {
  return {
    type: 'HIDE_PAYWALL',
  };
};

export {getSubscriptionOfferings, showPaywall, hidePaywall};
