import {round} from 'lodash';

import Tag from '../decisions/Tag';

const TagScoreRow = ({tag, onClickTag, onClick}) => {
  return (
    <div className="flex items-center justify-between py-2 cursor-pointer" onClick={() => onClick(tag)}>
      <div onClick={() => onClickTag(tag.text)}>
        <Tag tag={tag.text} />
      </div>
      <h2 className="xl font-semibold">
        {round(tag.avgAccuracyScore, 2)}
      </h2>
    </div>
  );
};

export default TagScoreRow;
