import {DateTime} from 'luxon';

const decisionReviewCalendarDay = (date) => {
  return DateTime.fromMillis(date).toLocaleString();
  // {
  //   sameDay: '[Today]',
  //   nextDay: '[Tomorrow]',
  //   nextWeek: 'dddd',
  //   lastDay: '[Yesterday]',
  //   lastWeek: '[Last] dddd',
  //   sameElse: 'MMM D, YYYY',
  // });
};

const formattedFullDate = (date) => {
  return DateTime.fromMillis(date).toFormat('MMMM D, yyyy');
};

export {decisionReviewCalendarDay, formattedFullDate};
