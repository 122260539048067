import firebase from '../util/firebase';
import {logError} from '../util/errors';

const fetchingTimeSeries = () => {
  return {
    type: 'FETCHING_TIME_SERIES',
  };
};

const fetchingTimeSeriesFailed = () => {
  return {
    type: 'FETCHING_TIME_SERIES_FAILED',
  };
};

const timeSeriesLoaded = (data) => {
  return {
    type: 'TIME_SERIES_LOADED',
    data,
  };
};

const loadTimeSeries = (filterByTag) => async (dispatch, getState) => {
  try {
    dispatch(fetchingTimeSeries());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('getTimeSeriesAnalytics');
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      filterByTag,
    });

    return dispatch(timeSeriesLoaded(data));
  } catch (e) {
    dispatch(fetchingTimeSeriesFailed());
    return logError(e);
  }
};

export {loadTimeSeries};
