const SidePanelHeader = ({title, onDismiss, onBack}) => {
  const handleGoBack = (e) => {
    e.preventDefault();
    onBack();
  }

  const handleDismiss = (e) => {
    e.preventDefault();
    onDismiss();
  }

  const renderTitle = () => {
    return (
      <h2 id="slide-over-heading" className="side-panel-title">
        {title}
      </h2>
    );
  }

  return (
    <div className="side-panel-header-wrap">
      <div className="side-panel-header">
        {onBack ? (
          <div className="flex items-center">
            <button onClick={handleGoBack} className="mr-3 rounded-md text-gray-500 hover:text-gray-600 focus:outline-none">
              <span className="sr-only">Go back</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </button>
            {renderTitle()}
          </div>
        ) : renderTitle()}
        <div className="side-panel-header-action">
          <button onClick={handleDismiss} className="dismiss-btn">
            <span className="sr-only">Close panel</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SidePanelHeader;