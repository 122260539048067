import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Switch,
  Route,
} from 'react-router-dom';

import Toast from '../common/Toast';
import Paywall from '../common/Paywall';

import NavigationMenu from './navbar/NavigationMenu';

import DecisionsHome from './decisions/DecisionsHome';
import CreateDecision from './decisions/CreateDecision';
import EditDecision from './decisions/EditDecision';
import DecisionDetails from './decisions/DecisionDetails';

import TagView from './tags/TagView';

import AnalyzeHome from './analyze/AnalyzeHome';

import AccountHome from './account/AccountHome';

import NotFound from './NotFound';
import GettingStartedMenu from './GettingStartedMenu';

import firebase from '../../util/firebase';

import {
  registerUserLocaleData,
  getUserSubscription,
  syncUser,
  syncUserTags,
} from '../../actions/user';
import {loadRemoteConfig} from '../../actions/config';
import {getSubscriptionOfferings} from '../../actions/subscriptions';
import {loadHomeDecisionData} from '../../actions/decisions';

const Dashboard = ({history}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userRef = firebase.firestore().collection('Users').doc(user.uid);

  useEffect(() => {
    // load initial app wide data
    const userUnsubscribe = userRef.onSnapshot((snapshot) =>
      dispatch(syncUser(snapshot))
    );
    const userTagsUnsubscribe = userRef.collection('Tags')
      .where('usageCount', '>', 0).limit(10).onSnapshot((snapshot) =>
        dispatch(syncUserTags(snapshot))
      );
    loadInitialData();

    return () => {
      userUnsubscribe();
      userTagsUnsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInitialData = async () => {
    dispatch(getSubscriptionOfferings());
    dispatch(loadRemoteConfig());
    dispatch(loadHomeDecisionData());
    const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
    dispatch(registerUserLocaleData({timeZone}));
    dispatch(getUserSubscription());
  }

  return (
    <>
      <Toast />
      <NavigationMenu history={history} />
      <Switch>
        <Route exact path="/account(|/subscription|/support|/notifications|/calendar|/getting_started|/api_key)">
          <AccountHome history={history} />
        </Route>
        <Route exact path="/analyze">
          <AnalyzeHome history={history}  />
        </Route>
        <Route exact path="/tags/:tag">
          <TagView history={history} />
        </Route>
        <Route exact path="/decisions/new">
          <CreateDecision history={history}  />
        </Route>
        <Route exact path="/decisions/:decisionId/edit">
          <EditDecision history={history} />
        </Route>
        <Route exact path="/decisions/:decisionId">
          <DecisionDetails history={history} />
        </Route>
        <Route exact path="/(|decisions|in_progress|to_review|scheduled|past)">
          <DecisionsHome history={history} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <Paywall />
      <GettingStartedMenu />
    </>
  )
}

export default Dashboard;