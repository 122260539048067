import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {showLoadingModal, hideLoadingModal} from '../../../actions/loadingModal';
import {generateApiKey} from '../../../actions/user';
import {logEvent} from '../../../util/analytics';
import {logAndShowError} from '../../../util/errors';
import { EyeIcon, EyeSlashIcon, KeyIcon } from '@heroicons/react/24/outline';

const KeyContainer = ({apiKey}) => {
  const [showKey, setShowKey] = useState(false);

  const toggleVisibility = () => {
    setShowKey(!showKey)
  };

  return (
    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
      <span style={{paddingRight: '6px'}} className="dark:text-white text-gray-700">
        {showKey ? apiKey : '***********************************'}
      </span>
      <button type="button" onClick={toggleVisibility} className="outline-none focus:outline-none">
        {showKey ? (
          <EyeSlashIcon className="h-5 w-5 dark:text-gray-400 text-gray-500" />
        ) : (
          <EyeIcon className="h-5 w-5 dark:text-gray-400 text-gray-500" />
        )}
      </button>
    </div>
  )
}

const ApiKeySettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  const handleGenerateApiKey = async () => {
    logEvent('tapped_generate_api_key');
    try {
      dispatch(showLoadingModal());
      await dispatch(generateApiKey());
      dispatch(hideLoadingModal());
    } catch (e) {
      dispatch(hideLoadingModal());
      logAndShowError(e);
    }
  };

  return (
    <div className="card">
      <h3 className="card-title">API Key</h3>
      <div className="card-body divide-y space-y-5 divide-gray-400 dark:divide-gray-700">
        <div className="text-sm space-y-2">
          <p>
            {user.privateApiKey ? 'Your private API key is listed below.' : 'To generate an API key, click the button below.'} Please note that this key is sensitive and should not be shared with anyone else.
          </p>
          <p>
            Be sure to not use it in any public places. If you believe someone has gained access to your key, please generate a new one, which will remove the old one. We may automatically do this on your behalf if we detect suspicious activity.
          </p>
        </div>
        <div className="pt-5 w-full gap-x-4">
          {user.privateApiKey && (
            <div className="w-full flex mb-6">
              <dt className="pr-5">
                <span className="sr-only">API Key</span>
                <KeyIcon className="h-6 w-5 dark:text-gray-400 text-gray-500" aria-hidden="true" />
              </dt>
              <dd className="text-sm font-medium leading-6 text-gray-900 flex flex-1">
                <KeyContainer apiKey={user.privateApiKey.key} />
              </dd>
            </div>
          )}
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <button
              className="btn btn-primary"
              onClick={handleGenerateApiKey}
            >
              Generate API Key
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiKeySettings;