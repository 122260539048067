const initialState = {
  isLoading: false,
  decisionId: null,
  list: [],
  drafts: [],
};

function reviews(state = initialState, action) {
  switch (action.type) {
    case 'REVIEWS_UPDATED':
      return {
        ...state,
        isLoading: false,
        list: action.reviews,
        decisionId: action.decision.id,
      };

    case 'DRAFT_REVIEWS_UPDATED':
      return {
        ...state,
        drafts: action.drafts,
      };

    case 'REVIEW_DELETED':
      return {
        ...state,
        drafts:
          state.decisionId === action.decisionId
            ? state.drafts.filter((draft) => draft.id !== action.reviewId)
            : state.drafts,
      };

    case 'LOGGED_OUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default reviews;
