// Keep track of emojis used for mapping purposes
const mapToEmoji = (value) => {
  switch (value) {
    case 'very good':
      return 'smiley';
    case 'good':
      return 'slightly_smiling_face';
    case 'neutral':
      return 'neutral_face';
    case 'bad':
      return 'pensive';
    case 'very bad':
      return 'tired_face';
    default:
      return '';
  }
};

module.exports = {
  mapToEmoji,
};
