import {useState} from 'react';
import { DateTime } from 'luxon';
import {useSelector} from 'react-redux';

import EditReview from './EditReview';
import ReviewOutcomeEstimatesTable from './ReviewOutcomeEstimatesTable';

import {getSkillVsLuckForWeight} from '../../../util/skillLuck';
import {isDefined} from '../../../util/helpers';

const ReviewCard = ({review, decision}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const editBufferPeriodValues = useSelector(
    (store) => store.config.editBufferPeriod.reviews
  );
  let isEditable;
  if (review.reviewDate) {
    const editBufferPeriod = DateTime.fromMillis(review.reviewDate).plus({ 
      [editBufferPeriodValues.interval]: editBufferPeriodValues.amount
    });
    const today = DateTime.local();
    isEditable = today < editBufferPeriod; 
  }

  return (
    <div className="card">
      {showEditForm ? (
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          <EditReview
            decision={decision}
            review={review}
            onDismiss={() => setShowEditForm(false)}
          />
        </div>
      ) : (
        <ReviewDetails review={review} isEditable={isEditable} onShowEdit={() => setShowEditForm(true)} />
      )}
    </div>
  );
};

const ReviewDetails = ({review, isEditable, onShowEdit}) => {
  const {skill, luck} = getSkillVsLuckForWeight(review.skillLuckWeight);

  return (
    <div className="card-body space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="text-lg">
          {review.reviewDate && DateTime.fromMillis(review.reviewDate).toFormat('DDD')}
        </h4>
        <p className="text-gray-500 font-semibold text-right">
          Overall Accuracy Score: {review.accuracyScore}
        </p>
      </div>
      <ReviewSection label="Outcome" value={review.actualOutcome} />
      {review && review.outcomeEstimates && review.outcomeEstimates.length > 0 && (
        <ReviewSection label="Outcome Estimates">
          <ReviewOutcomeEstimatesTable outcomeEstimates={review.outcomeEstimates} disabled={true} readonly={true} />
        </ReviewSection>
      )}
      {review && isDefined(review.skillLuckWeight) && (
        <ReviewSection label="Actual Outcome Skill vs Luck">
          <div className="py-2 px-0 flex items-start">
            <div className="text-center">
              <p className="text-xl font-bold">{skill}%</p>
              <p className="text-sm font-semibold">Skill</p>
            </div>
            <p className="text-xl font-bold mx-3">/</p>
            <div className="text-center">
              <p className="text-xl font-bold">{luck}%</p>
              <p className="text-sm font-semibold">Luck</p>
            </div>
          </div>
        </ReviewSection>
      )}
      <ReviewSection label="Learnings" value={review.learningsAndReview} />
      {isEditable && (
        <div className="text-center">
          <div onClick={onShowEdit} className="text-indigo-600 cursor-pointer">Edit Review</div>
        </div>
      )}
    </div>
  )
}

const ReviewSection = ({label, value, children}) => {
  return (
    <div>
      <p className="font-bold mb-2">{label}</p>
      {value && <p className="whitespace-pre-wrap">{value}</p>}
      {children}
    </div>
  );
};

export default ReviewCard;
