import {Menu} from '@headlessui/react';

const AdditionalDecisionActionsMenu = ({options}) => {
  return (
    <div className="sm:ml-6 sm:flex sm:items-center">
      <div className="ml-3 relative">
      <Menu>
        <Menu.Button className="items-center options-toggle-btn" id="additional-decision-actions" aria-haspopup="true">
          <span className="sr-only">Additional actions</span>
          <span className="hidden sm:inline-block">Actions</span>
          <svg className="hidden sm:inline-block -mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
          <svg className="sm:hidden h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
          </svg>
        </Menu.Button>
        <Menu.Items>
          <div className="dropdown-menu" role="menu" aria-orientation="vertical" aria-labelledby="additional-decision-actions">
            {options.map((option) => {
              return (
                <Menu.Item key={option.label}>
                  <span className="dropdown-menu-item" onClick={option.onClick}>
                    {option.label}
                  </span>
                </Menu.Item>
              )
            })}
          </div>
        </Menu.Items>
      </Menu>
      </div>
    </div>
  )
}

export default AdditionalDecisionActionsMenu;