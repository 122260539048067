import mixpanel from 'mixpanel-browser';

import firebase from './firebase';
import {MIXPANEL_TOKEN} from './constants';
import {getSubscriberInfo, updateSubscriberInfo} from './subscriptions';

const setupAnalytics = async () => {
  firebase.analytics().setAnalyticsCollectionEnabled(true);
  mixpanel.init(MIXPANEL_TOKEN);
};

const logLogin = ({method}) => {
  mixpanel.track('logged_in', {method});
  firebase.analytics().logEvent('login', {method});
};

const logSearch = (params = {}) => {
  mixpanel.track('searched', params);
  firebase.analytics().logEvent('search', params);
};

const logEvent = (event, params = {}) => {
  mixpanel.track(event, params);
  firebase.analytics().logEvent(event, params);
};

const logSelectedContent = (params = {}) => {
  mixpanel.track('selected_content', params);
  firebase.analytics().logEvent('select_content', params);
};

const logScreenView = (screenName, params = {}) => {
  mixpanel.track(`Viewed ${screenName} Screen`, params);
  firebase.analytics().setCurrentScreen(screenName, params);
};

const setUser = async (user) => {
  const userProperties = {
    email: user.email || '',
    name: user.name || '',
    emailNotifications:
      user.notificationSettings && user.notificationSettings.emailNotifications
        ? 'true'
        : 'false',
    pushNotifications:
      user.notificationSettings && user.notificationSettings.pushNotifications
        ? 'true'
        : 'false',
    reviewReminders:
      user.notificationSettings && user.notificationSettings.reviewReminders
        ? 'true'
        : 'false',
    decisionReminders:
      user.notificationSettings && user.notificationSettings.decisionReminders
        ? 'true'
        : 'false',
  };

  mixpanel.identify(user.uid);
  mixpanel.people.set({
    ...userProperties,
    $email: userProperties.email,
    $name: userProperties.name,
  });

  firebase.analytics().setUserId(user.uid);
  firebase.analytics().setUserProperties(userProperties);

  // Set user for purchases
  await getSubscriberInfo(user);
  await updateSubscriberInfo(user);
};

const resetUser = () => {
  // Resets anything related to the user if necessary on log out
};

const timeEvent = (event) => {
  mixpanel.time_event(event);
};

const sendWebVitalsToAnalytics = (vitals) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(vitals);
  } else {
    const { id, name, delta } = vitals;
    firebase.analytics().logEvent(name, {
      event_category: 'Web Vitals',
      value: Math.round(name === 'CLS' ? delta * 1000 : delta),
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate
    });
  }
}

export {
  setupAnalytics,
  logLogin,
  logSearch,
  logEvent,
  logScreenView,
  setUser,
  resetUser,
  logSelectedContent,
  timeEvent,
  sendWebVitalsToAnalytics,
};
