import {useState, useCallback} from 'react';
import {debounce, includes, delay} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import LoadingContainer from '../../common/LoadingContainer';

import TagsList from './TagsList';

import {logEvent} from '../../../util/analytics';

import {searchTags, clearTagSearch} from '../../../actions/tags';

const TagInput = ({tagsList, onAddTag, onRemoveTag, isDisabled}) => {
  const dispatch = useDispatch();
  const [currentTag, updateCurrentTag] = useState('');
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const isLoading = useSelector((store) => store.tagSearch.isLoading);
  const searchResults = useSelector((store) => store.tagSearch.searchResults);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(
    debounce((text) => dispatch(searchTags(text)), 300),
    []
  );

  const delayHideAutocomplete = () => {
    // delay needed to ensure if user clicks the menu
    // the menu receives the click before the blur
    // 120ms is shortest time that it'll work
    if (showAutocomplete) {
      delay(() => setShowAutocomplete(false), 120);
    } else {
      setShowAutocomplete(false);
    }
  }

  const handleUpdateTagInputText = (event) => {
    const text = event.target.value;
    updateCurrentTag(text);
    if (text && text !== '') {
      debouncedQuery(text);
    } else {
      dispatch(clearTagSearch());
    }
  };

  const handleSubmitTagInput = () => {
    updateCurrentTag('');
    if (currentTag && currentTag !== '' && !includes(tagsList, currentTag)) {
      onAddTag(currentTag);
    }
    dispatch(clearTagSearch());
  };

  const chooseExistingTag = (tag) => {
    logEvent('selected_existing_tag', {tag});
    updateCurrentTag('');
    onAddTag(tag);
    dispatch(clearTagSearch());
  };

  const renderResultRow = (result) => {
    return (
      <div
        key={result.value}
        className="input-results-item"
        onClick={() => chooseExistingTag(result.value)}>
        {result.value}
      </div>
    );
  };

  const renderNewTagRow = () => {
    return (
      <div className="input-results-item" onClick={handleSubmitTagInput}>
        + Create New Tag
      </div>
    );
  };

  const renderResultList = () => {
    const isFilled = currentTag && currentTag !== '';
    if (showAutocomplete && isFilled) {
      return (
        <div className="input-results-list">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {isLoading ? (
              <LoadingContainer size="small" />
            ) : (
              searchResults.map(renderResultRow)
            )}
            {renderNewTagRow()}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="">
      <div className="form-control">
        <div className="sm:col-span-6">
          <label htmlFor="tags" className="form-field-label">
            Add tags
          </label>
          <div className="mt-1">
            <input 
              disabled={isDisabled}
              type="text" 
              name="tags" 
              id="tags" 
              placeholder="Enter a tag" 
              className="text-field"
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  handleSubmitTagInput();
                }
              }}
              onFocus={() => setShowAutocomplete(true)}
              onBlur={delayHideAutocomplete}
              value={currentTag}
              onChange={handleUpdateTagInputText}
            />
            {renderResultList()}
          </div>
        </div>
      </div>
      <TagsList tags={tagsList} onRemove={(tag) => onRemoveTag(tag)} />
    </div>
  );
};

export default TagInput;