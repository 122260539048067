import {DateTime} from 'luxon';
import { map, groupBy, sum, sortBy, round } from 'lodash';

import {isDefined} from './helpers';

const timeSeriesPeriodOptions = ['Monthly', 'Yearly'];

const formatTimeSeriesLabel = (date, timePeriod, type = 'short') => {
  const stringFormat = type === 'short' ? "MMM yyyy" : 'MMMM yyyy';
  const dateAsInt = parseInt(date, 10);
  if (timePeriod === 'Monthly') {
    return DateTime.fromMillis(dateAsInt).toFormat(stringFormat);
  }
  return DateTime.fromMillis(dateAsInt).toFormat('yyyy');
};

const getDataTimePeriod = (data, timePeriod) => {
  if (timePeriod === 'Monthly') {
    return data.reviewDate 
      ? DateTime.fromMillis(data.reviewDate).startOf('month').toMillis()
      : DateTime.local().startOf('month').toMillis();
  }
  return data.reviewDate 
    ? DateTime.fromMillis(data.reviewDate).startOf('year').toMillis()
    : DateTime.local().startOf('month').toMillis();
};

const getFormattedDataForTimeSeries = (data, timePeriod, dataKey) => {
  const filteredData = data.filter(obj => isDefined(obj[dataKey]));
  const byTimePeriods = groupBy(filteredData, (d) =>
    getDataTimePeriod(d, timePeriod)
  );
  const updatedData = map(byTimePeriods, (tp, key) => {
    const periodScores = map(tp, dataKey);
    const score = round(sum(periodScores) / periodScores.length, 2);
    return {
      date: formatTimeSeriesLabel(key, timePeriod),
      score,
    };
  });
  return updatedData;
}

const getInfoFromTimeSeries = (data, timePeriod, dataKey = 'accuracyScore') => {
  const sortedData = sortBy(data, 'reviewDate');
  const formattedData = getFormattedDataForTimeSeries(sortedData, timePeriod, dataKey);
  return formattedData;
};

export {
  getInfoFromTimeSeries,
  formatTimeSeriesLabel,
  timeSeriesPeriodOptions,
};
