import {useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../../common/Button';
import SidePanelHeader from '../../common/SidePanelHeader';

import ReviewForm from './ReviewForm';

import {logAndShowError} from '../../../util/errors';
import {shouldShowPaywall} from '../../../util/subscriptions';
import {isDefined} from '../../../util/helpers';

import {
  saveReview, 
  deleteReview,
} from '../../../actions/reviews';
import {
  showLoadingModal,
  hideLoadingModal,
} from '../../../actions/loadingModal';
import {displayToast} from '../../../actions/toasts';
import {showPaywall} from '../../../actions/subscriptions';

const EditReview = ({
  onDismiss,
  onBack,
  decision,
  review: initialReview,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const gettingStartedStepsStatuses = user.gettingStartedSteps;
  const isVisible = useSelector((store) => store.gettingStartedMenu && store.gettingStartedMenu.isVisible);
  const [review, updateReview] = useState(initialReview);
  const changesMade = !_.isEqual(review, initialReview);
  const isDraft = initialReview.status === 'draft';

  const isValidReview = () => {
    let valid = true;
    const requiredFields = [
      'actualOutcome',
      'learningsAndReview',
      'accuracyScore',
    ];
    requiredFields.forEach((field) => {
      const value = review[field];
      if (!isDefined(value) || value === '') {
        valid = false;
      }
    });

    return valid;
  };

  const save = async (status) => {
    const reviewData = {
      ...review,
      status,
    };
    dispatch(showLoadingModal());
    try {
      if (status === 'published') {
        const requiresSubscription = await shouldShowPaywall(user);
        if (requiresSubscription) {
          dispatch(hideLoadingModal());
          dispatch(showPaywall({
            message: 'Subscribe to create more reviews',
          }));
          return;
        }
      }
      await dispatch(saveReview(decision, reviewData));
      dispatch(hideLoadingModal());
      dismiss();
      dispatch(
        displayToast({
          type: 'success',
          message: `${
            status === 'draft'
              ? 'Draft saved'
              : isDraft ? 'Review saved' : 'Review added'
          }!`,
        })
      );
    } catch (err) {
      dispatch(hideLoadingModal());
      logAndShowError(err);
    }
  };

  const handleGoBack = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (changesMade) {
      const dismissConfirmed = window.confirm("Looks like you've made some changes. Are you sure you want to navigate away without saving?");
      if (dismissConfirmed) {
        onBack();
      }
    } else {
      onBack();
    }
  }

  const handleDismiss = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (changesMade) {
      const dismissConfirmed = window.confirm("Looks like you've made some changes. Are you sure you want to navigate away without saving?");
      if (dismissConfirmed) {
        dismiss();
      }
    } else {
      dismiss();
    }
  }

  const dismiss = () => {
    onDismiss();
  }

  const onDelete = () => {
    const confirmedDelete = window.confirm('Are you sure you want to delete this draft?');
    if (confirmedDelete) {
      dispatch(deleteReview(decision.id, review.id));
      dismiss();
      dispatch(
        displayToast({
          type: 'success',
          message: `${isDraft ? 'Draft deleted!' : 'Decision deleted!'}`,
        })
      );
    }
  }

  const renderFooter = () => {
    const extraPadding = gettingStartedStepsStatuses && !gettingStartedStepsStatuses.isCompleted && isVisible;

    return (
      <div 
        className={`flex-shrink-0 px-4 py-4 flex justify-between items-center space-x-3 sticky bottom-0 bg-white dark:bg-gray-900 rounded-b-xl ${extraPadding ? 'pb-16 sm:pb-20' : ''}`}
      >
        <div className="flex items-center">
          {!isDraft && (
            <Button
              label="Cancel"
              onClick={handleDismiss}
              additionalClasses="mr-3"
            />
          )}
          <Button
            label="Delete"
            onClick={(event) => {
              event.preventDefault(); 
              onDelete();
            }}
            additionalClasses="bg-red-600 mr-3 text-white"
          />
        </div>
        <div className="flex items-center justify-end">
          {isDraft && (
            <Button
              label="Save Draft"
              onClick={(event) => {
                event.preventDefault(); 
                save('draft');
              }}
              isDisabled={!changesMade}
              additionalClasses="mr-3"
            />
          )}
          <Button
            label={`${!isDraft ? 'Save Review' : 'Add Review'}`}
            onClick={(event) => {
              event.preventDefault(); 
              save('published');
            }}
            isDisabled={!changesMade || !isValidReview()}
            additionalClasses="btn-primary"
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`flex-1 overflow-y-auto ${isDraft ? 'h-0' : ''}`}>
        <SidePanelHeader title="Edit Review" onDismiss={handleDismiss} onBack={onBack ? handleGoBack : null} />
        <ReviewForm review={review} onUpdate={updateReview} isDraft={isDraft} />
      </div>
      {renderFooter()}
    </>
  );
}

export default EditReview;