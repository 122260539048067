import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import Button from '../common/Button';

import {
  showLoadingModal,
  hideLoadingModal,
} from '../../actions/loadingModal';
import {
  sentLoginCodeViaEmail, 
  loginUser,
  createNewUserFromCredentials
} from '../../actions/login';

import firebase from '../../util/firebase';
import {
  logError, 
  logAndShowError,
} from '../../util/errors';
import {WEB_APP_URL} from '../../util/constants';

const LoginWithEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((store) => store.user);
  const [email, updateEmail] = useState('');

  useEffect(() => {
    // Check if user is coming from an email login link
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      loginUserWithEmail(window.location.href);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginUserWithEmail = async (url) => {
    try {
      dispatch(showLoadingModal());
      let email = user.email;
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again.
        email = window.prompt('Please confirm your email address.');
      }
      const userCredential = await firebase.auth().signInWithEmailLink(email, url);
      await createNewUserFromCredentials(userCredential);
      await dispatch(loginUser(userCredential.user.toJSON(), 'emailLink'));
      dispatch(hideLoadingModal());
      if (location.state && location.state.from) {
        const { from } = location.state;
        history.push(from);
      }
    } catch (e) {
      dispatch(hideLoadingModal());
      logAndShowError(e);
    }
  }

  const isValid = () => {
    return !!email && email !== '';
  }

  const loginWithEmail = async () => {
    // avoids accidental whitespace when using autocomplete
    const trimmedEmail = email.trim();

    dispatch({
      type: 'SET_LOGIN_EMAIL',
      email: trimmedEmail,
    });

    try {
      dispatch(showLoadingModal());
      const actionCodeSettings = {
        android: {
          installApp: true,
          packageName: process.env.REACT_APP_PACKAGE_NAME,
        },
        handleCodeInApp: true,
        iOS: {
          bundleId: process.env.REACT_APP_APP_IDENTIFIER,
        },
        url: `${WEB_APP_URL}/login?email=${trimmedEmail}`,
      };
      await firebase.auth().sendSignInLinkToEmail(trimmedEmail, actionCodeSettings);
      dispatch(sentLoginCodeViaEmail(trimmedEmail));

      dispatch(hideLoadingModal());

      // Show pending email link screen
      history.push('/login/email_confirmation');
    } catch (e) {
      dispatch(hideLoadingModal());
      if (e.code === 'auth/invalid-email') {
        logAndShowError(e);
      } else {
        // shouldn't ever happen
        logError(e);
      }
    }
  };

  return (
    <div>
      <div className="py-3">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300 dark:border-gray-800"></div>
          </div>
          <div className="relative flex justify-center text-sm text-gray-500">
            <span className="px-2 bg-white dark:bg-gray-900 base-text">
              or Continue with Email
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <input 
          id="email" 
          name="email" 
          type="email" 
          autoComplete="email" 
          placeholder="Email address" 
          required 
          className="text-field" 
          value={email}
          onChange={(event) => updateEmail(event.target.value)}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              loginWithEmail();
            }
          }}
        />
        <div className="my-3">
          <Button 
            onClick={loginWithEmail}
            label="Login"
            additionalClasses="btn-primary btn-block" 
            isDisabled={!isValid()}
          />
        </div>
      </div>
    </div>
  )
}

export default LoginWithEmail;