

const EmptyState = ({title, description, action, icon, size, withoutBorder, children}) => {
  return (
    <div className={`${size === 'sm' ? 'py-3' : 'py-8'} px-4 sm:px-0`}>
      <div className={`${size === 'sm' ? 'h-30' : 'h-80'} ${withoutBorder ? '' : 'border border-dashed border-gray-200 dark:border-gray-700'}`}>
        <div className="min-h-full p-6 flex flex-col justify-center items-center max-w-xl mx-auto space-y-5">
          {icon}
          {title && <h3 className="text-center text-gray-500">{title}</h3>}
          {description && <p className="text-center text-gray-400">{description}</p>}
          {action && (
            <div className="flex items-center justify-center">
              {action}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default EmptyState;