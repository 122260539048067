const SelectInput = ({
  name,
  value,
  onChange,
  options,
  showEmpty
}) => {
  return (
    <>
      <label htmlFor={name} className="sr-only">Select</label>
      <select 
        id={name} 
        name={name} 
        className="select-input"
        value={value || ''}
        onChange={onChange}
      >
        {showEmpty && <option>Select</option>}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>{option.label}</option>
          )
        })}
      </select>
    </>
  )
}

export default SelectInput;