import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import Modal from './Modal';
import Button from './Button';

import SubscriptionFeatures from '../dashboard/account/SubscriptionFeatures';

import {hidePaywall} from '../../actions/subscriptions';

const Paywall = ({
  onDismiss,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const paywall = useSelector((store) => store.paywall);

  const dismiss = () => {
    dispatch(hidePaywall());
    if (onDismiss) {
      onDismiss();
    }
  };

  const goToSubscription = (e) => {
    if (e) {
      e.preventDefault();
    }
    dismiss();
    history.push('/account/subscription');
  }

  return (
    <Modal isVisible={paywall.isVisible} onDismiss={dismiss}>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        <div>
          <h3 className="modal-title sm:text-3xl">
            Upgrade your account!
          </h3>
          <div className="modal-body">
            {paywall.message && (
              <div className="py-4">
                <div className="rounded-md bg-indigo-50 p-4">
                  <div className="flex text-center justify-center">
                    <div className="">
                      <svg className="h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-indigo-700">
                        {paywall.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="text-center sm:text-lg font-bold mb-3">
              Upgrade to unlock:
            </div>
            <SubscriptionFeatures />
          </div>
        </div>
        <div className="modal-footer">
          <Button label="Upgrade your account" onClick={goToSubscription} additionalClasses="btn-primary btn-block" />
        </div>
      </div>
    </Modal>
  );
};

export default Paywall;
