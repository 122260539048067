import {useSelector} from 'react-redux';
import {Switch} from '@headlessui/react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {round} from 'lodash';

import Button from '../../common/Button';
import TextField from '../../common/TextField';
import TextArea from '../../common/TextArea';
import SelectInput from '../../common/SelectInput';

import TagInput from './TagInput';

import {initialOutcomeEstimate} from '../../../util/decisions';
import {getSkillVsLuckForWeight} from '../../../util/skillLuck';
import {logEvent} from '../../../util/analytics';
import {isDefined} from '../../../util/helpers';

const DecisionForm = ({
  decision,
  onUpdate,
  isEditing,
  isDraft,
  isLoading,
  isSaveDisabled,
  isSaveDraftDisabled,
  onDelete,
  onSave,
  onSaveDraft,
}) => {
  const reviewPeriodOptions = useSelector(
    (store) => store.config.reviewPeriods
  );
  const {skill, luck} = getSkillVsLuckForWeight(decision.skillLuckWeight);

  const handleToggleSkillLuck = (value) => {
    logEvent('toggled_skill_luck_tracking', {value});
    let skillLuckWeight = null;
    if (value) {
      skillLuckWeight = 0.5;
    }

    onUpdate({...decision, skillLuckWeight})
  }

  const handleAddAnotherOutcomeEstimate = (e) => {
    logEvent('clicked_add_another_outcome_estimate');
    if (e) {
      e.preventDefault();
    }
    const updatedOutcomeEstimates = [...decision.outcomeEstimates, initialOutcomeEstimate];
    onUpdate({...decision, outcomeEstimates: updatedOutcomeEstimates})
  }

  const handleRemoveOutcomeEstimate = (e, idx) => {
    logEvent('clicked_remove_outcome_estimate');
    if (e) {
      e.preventDefault();
    }
    const updatedOutcomeEstimates = [...decision.outcomeEstimates];
    updatedOutcomeEstimates.splice(idx, 1);
    if (updatedOutcomeEstimates.length === 0) {
      updatedOutcomeEstimates.push(initialOutcomeEstimate);
    }
    onUpdate({...decision, outcomeEstimates: updatedOutcomeEstimates})
  }

  const handleUpdateOutcomeEstimate = (idx, field, value) => {
    const updatedOutcomeEstimates = decision.outcomeEstimates;
    updatedOutcomeEstimates[idx] = {
      ...updatedOutcomeEstimates[idx],
      [field]: value
    };
    onUpdate({...decision, outcomeEstimates: updatedOutcomeEstimates});
  }

  return (
    <div className="px-4 pt-8 sm:px-0">
      <form className="">
        <div className="space-y-8">

          <div className="form-control">
            <div className="sm:col-span-6">
              <TextField
                autoFocus
                label="What decision are you making?"
                name="decision"
                placeholder="Enter the decision"
                value={decision.title}
                onChange={(event) => onUpdate({...decision, title: event.target.value})}
              />
            </div>
          </div>

          <div className="mt-6">
            <TagInput
              tagsList={decision.tags}
              onAddTag={(tag) =>
                onUpdate({
                  ...decision,
                  tags: [...decision.tags, tag],
                })
              }
              onRemoveTag={(tag) =>
                onUpdate({
                  ...decision,
                  tags: decision.tags.filter((t) => t !== tag),
                })
              }
            />
          </div>

          <div className="mt-6">
            <div className="form-control">
              <TextArea
                label="What's the situation/context?"
                name="context"
                placeholder="Explain the situation/context"
                value={decision.context}
                onChange={(event) => onUpdate({...decision, context: event.target.value})}
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="form-control">
              <TextArea
                label="What do you expect the outcome to be?"
                name="expectedOutcome"
                placeholder="Enter the details about what you expect to happen"
                value={decision.expectedOutcome}
                onChange={(event) => onUpdate({...decision, expectedOutcome: event.target.value})}
              />
            </div>

            <div className="mt-4">
              <label className="form-field-label">
                Estimate outcome probabilities
              </label>
              <div className="space-y-3">
              {decision.outcomeEstimates.map((outcomeEstimate, idx) => {
                return (
                  <div key={idx} className="form-control items-end">
                    <div className="sm:col-span-4">
                      <TextField
                        name={`outcomeEstimateText_${idx}`}
                        placeholder="Enter the specific outcome and estimate its probability"
                        value={outcomeEstimate.text}
                        onChange={(event) => handleUpdateOutcomeEstimate(idx, 'text', event.target.value)}
                      />
                    </div>
                    <div className="sm:col-span-2 flex items-center">
                      <TextField
                        name={`outcomeEstimateProb_${idx}`}
                        placeholder=""
                        type="number"
                        min={0}
                        max={100}
                        value={outcomeEstimate.probability ? round(outcomeEstimate.probability * 100, 0) : ''}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value <= 100) {
                            handleUpdateOutcomeEstimate(idx, 'probability', value / 100);
                          }
                        }}
                        attachmentRight={(
                          <span className="input-attachment-right">
                            %
                          </span>
                        )}
                      />
                      <div className="ml-3">
                        <Button onClick={(e) => handleRemoveOutcomeEstimate(e, idx)} additionalClasses="text-red-500 btn-link">
                          <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
              </div>

              <div className="mt-2">
                <p className="text-sm text-gray-500 flex items-start">
                  <svg className="w-4 h-4 mt-0.5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Leave empty if you don't want to estimate specific outcomes and their probabilities.
                </p>
              </div>
              <div className="mt-5">
                <Button 
                  additionalClasses="btn-link link" 
                  onClick={handleAddAnotherOutcomeEstimate} 
                  label="Add Another" 
                />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="form-control">
              <div className="sm:col-span-6">
                <label htmlFor="reviewPeriod" className="form-field-label">
                  Would you like to estimate the role of skill vs luck in your outcome?
                </label>
                <div className="mt-1">
                  <Switch
                    disabled={isLoading}
                    checked={isDefined(decision.skillLuckWeight)}
                    onChange={(value) => handleToggleSkillLuck(value)}
                    className={`${
                      isDefined(decision.skillLuckWeight) ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700'
                    } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    <span className="sr-only">Enable Skill vs Luck</span>
                    <span
                      className={`${
                        isDefined(decision.skillLuckWeight) ? 'translate-x-5' : 'translate-x-0'
                      } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0`}
                    />
                  </Switch>
                </div>
              </div>
            </div>

            {isDefined(decision.skillLuckWeight) && (
              <div className="mt-3">
                <div className="form-control">
                  <div className="sm:col-span-6">
                    <label htmlFor="skillLuck" className="form-field-label mb-3">
                      How much of the outcome(s) do you think will be due to your skill vs your luck?
                    </label>
                    <div className="text-center pb-3 px-2 flex items-start justify-center">
                      <div className="text-center">
                        <p className="text-3xl font-bold">{skill}%</p>
                        <p className="text-sm font-semibold">Skill</p>
                      </div>
                      <p className="text-3xl font-bold mx-3">/</p>
                      <div className="text-center">
                        <p className="text-3xl font-bold">{luck}%</p>
                        <p className="text-sm font-semibold">Luck</p>
                      </div>
                    </div>
                    <Slider 
                      disabled={!isDraft || isLoading}
                      min={0}
                      max={1}
                      step={.05}
                      value={decision.skillLuckWeight}
                      onChange={(value) => {
                        onUpdate({...decision, skillLuckWeight: value});
                      }}
                      onAfterChange={(value) =>
                        onUpdate({...decision, skillLuckWeight: value})
                      }
                      handleStyle={{width: 10, height: 30, marginTop: -14, borderRadius: 5}}
                      className="slider-neutral"
                    />
                    <div className="flex justify-between items-center mt-1">
                      <p>Skill</p>
                      <p>Luck</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-6">
            <div className="form-control">
              <div className="sm:col-span-6">
                <label htmlFor="reviewPeriod" className="form-field-label">
                  When would you like to review this decision?
                </label>
                <div className="mt-1">
                  <SelectInput
                    name="reviewPeriod"
                    value={decision.monthsToNextReview}
                    showEmpty={decision.status !== 'published'}
                    onChange={(event) => {
                      onUpdate({
                        ...decision, 
                        monthsToNextReview: event.target.value || reviewPeriodOptions[0].value
                      })
                    }}
                    options={reviewPeriodOptions.map(opt => ({
                      ...opt,
                      label: `in ${opt.label}`
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 p-3 -mx-4 sm:mx-0 sm:px-0 sticky bottom-0 bg-white dark:bg-black border-t border-gray-200 dark:border-gray-800">
          <div className="flex items-center justify-between">
            <div className="flex">
              {isEditing && (
                <Button
                  label="Delete"
                  onClick={(event) => {
                    event.preventDefault(); 
                    onDelete();
                  }}
                  additionalClasses="btn-danger"
                />
              )}
            </div>
            <div className="flex">
              {isDraft && (
                <Button
                  label="Save Draft"
                  onClick={(event) => {
                    event.preventDefault(); 
                    onSaveDraft();
                  }}
                  isDisabled={isSaveDraftDisabled || isLoading}
                  additionalClasses="mr-3"
                />
              )}
              <Button
                label={`${isEditing && !isDraft ? 'Save Decision' : 'Add Decision'}`}
                onClick={(event) => {
                  event.preventDefault(); 
                  onSave();
                }}
                isDisabled={isSaveDisabled || isLoading}
                additionalClasses="btn-primary"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default DecisionForm;