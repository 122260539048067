import Modal from './Modal';

const ExplainerModal = ({isVisible, onDismiss, title, children}) => {
  return (
    <Modal size="large" isVisible={isVisible} onDismiss={onDismiss}>
      <div>
        <h3 className="modal-title">{title}</h3>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </Modal>
  )
};

export default ExplainerModal;