import {isNaN, isNull, isUndefined, map, includes} from 'lodash';

const concatOrUpdate = (newItem, existingList) => {
  return includes(map(existingList, 'id'), newItem.id)
    ? existingList.map((d) => {
        if (d.id === newItem.id) {
          return newItem;
        }
        return d;
      })
    : [...existingList, newItem];
};

const isDefined = (obj) => {
  return !isNull(obj) && !isUndefined(obj) && !isNaN(obj);
}

export {
  concatOrUpdate,
  isDefined
};
