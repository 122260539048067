const initialState = {
  isLoading: false,
  loadingFailed: false,
  data: {},
};

function tagTimeSeries(state = initialState, action) {
  switch (action.type) {
    case 'FETCHING_TAG_TIME_SERIES':
      return {
        ...state,
        isLoading: true,
        loadingFailed: false,
      };

    case 'FETCHING_TAG_TIME_SERIES_FAILED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      };

    case 'TAG_TIME_SERIES_LOADED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: false,
        data: action.data,
      };

    case 'LOGGED_OUT':
      return initialState;

    default:
      return state;
  }
}

export default tagTimeSeries;
