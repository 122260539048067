import {DateTime} from 'luxon';

import firebase from '../util/firebase';
import {logError} from '../util/errors';

const showDecision = (decision) => {
  return {
    type: 'SHOW_DECISION',
    decision,
  };
};

const loadingDraftDecisions = () => {
  return {
    type: 'DRAFT_DECISIONS_LOADING',
  };
};

const draftDecisionsUpdated = (decisions) => {
  return {
    type: 'DRAFT_DECISIONS_UPDATED',
    decisions,
  };
};

const draftDecisionsLoadingFailed = () => {
  return {
    type: 'DRAFT_DECISIONS_LOADING_FAILED',
  };
};

const loadDraftDecisions = (limit) => async (dispatch, getState) => {
  try {
    dispatch(loadingDraftDecisions());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('getDraftDecisions');
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      limit,
    });

    return dispatch(draftDecisionsUpdated(data));
  } catch (e) {
    dispatch(draftDecisionsLoadingFailed());
    return logError(e);
  }
};

const loadingScheduledForReviewDecisions = () => {
  return {
    type: 'SCHEDULED_FOR_REVIEW_DECISIONS_LOADING',
  };
};

const scheduledForReviewDecisionsUpdated = (decisions) => {
  return {
    type: 'SCHEDULED_FOR_REVIEW_DECISIONS_UPDATED',
    decisions,
  };
};

const scheduledForReviewDecisionsLoadingFailed = () => {
  return {
    type: 'SCHEDULED_FOR_REVIEW_DECISIONS_LOADING_FAILED',
  };
};

const loadScheduledForReviewDecisions = (limit) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadingScheduledForReviewDecisions());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable(
      'getScheduledForReviewDecisions'
    );
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      limit,
    });

    return dispatch(scheduledForReviewDecisionsUpdated(data));
  } catch (e) {
    dispatch(scheduledForReviewDecisionsLoadingFailed());
    return logError(e);
  }
};

const loadingToReviewDecisions = () => {
  return {
    type: 'TO_REVIEW_DECISIONS_LOADING',
  };
};

const toReviewDecisionsUpdated = (decisions) => {
  return {
    type: 'TO_REVIEW_DECISIONS_UPDATED',
    decisions,
  };
};

const toReviewDecisionsLoadingFailed = () => {
  return {
    type: 'TO_REVIEW_DECISIONS_LOADING_FAILED',
  };
};

const loadToReviewDecisions = (limit) => async (dispatch, getState) => {
  try {
    dispatch(loadingToReviewDecisions());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('getToReviewDecisions');
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      limit,
    });

    return dispatch(toReviewDecisionsUpdated(data));
  } catch (e) {
    dispatch(toReviewDecisionsLoadingFailed());
    return logError(e);
  }
};

const loadingPastDecisions = () => {
  return {
    type: 'PAST_DECISIONS_LOADING',
  };
};

const pastDecisionsUpdated = (decisions) => {
  return {
    type: 'PAST_DECISIONS_UPDATED',
    decisions,
  };
};

const pastDecisionsLoadingFailed = () => {
  return {
    type: 'PAST_DECISIONS_LOADING_FAILED',
  };
};

const loadPastDecisions = (limit) => async (dispatch, getState) => {
  try {
    dispatch(loadingPastDecisions());
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable('getPastDecisions');
    const {data} = await httpsCallable({
      userId: currentUser.uid,
      limit,
    });

    return dispatch(pastDecisionsUpdated(data));
  } catch (e) {
    dispatch(pastDecisionsLoadingFailed());
    return logError(e);
  }
};

const updatedOutstandingDecisionsToReivewCount = (count) => {
  return {
    type: 'UPDATED_OUTSTANDING_DECISIONS_TO_REVIEW_COUNT',
    count,
  };
};

const loadOutstandingDecisionsToReviewCount = () => async (
  dispatch,
  getState
) => {
  try {
    const currentUser = getState().user;
    const httpsCallable = firebase.functions().httpsCallable(
      'getOutstandingDecisionsToReviewCount'
    );
    const {data} = await httpsCallable({
      userId: currentUser.uid,
    });

    return dispatch(updatedOutstandingDecisionsToReivewCount(data));
  } catch (e) {
    return logError(e);
  }
};

const loadHomeDecisionData = () => async (dispatch) => {
  return dispatch(loadOutstandingDecisionsToReviewCount());
};

const currentDecisionUpdated = (decision) => {
  return {
    type: 'CURRENT_DECISION_UPDATED',
    decision,
  };
};

const syncCurrentDecision = (docSnapshot) => async (dispatch) => {
  dispatch(
    currentDecisionUpdated({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    })
  );
};

const decisionSaved = (decision) => {
  return {
    type: 'DECISION_SAVED',
    decision,
  };
};

const saveDecision = (decisionData) => async (dispatch, getState) => {
  const currentUser = getState().user;
  const httpsCallable = firebase.functions().httpsCallable('saveDecision');
  const {data: decision} = await httpsCallable({
    userId: currentUser.uid,
    decision: {
      ...decisionData,
      updatedAt: decisionData.updatedAt || DateTime.local().toMillis(),
      createdAt: decisionData.createdAt || DateTime.local().toMillis(),
    },
  });

  // For better UX, pre-add the review date when necessary
  if (
    decision.status === 'published' &&
    decision.monthsToNextReview &&
    !decision.nextReviewDate
  ) {
    const now = DateTime.local();
    decision.nextReviewDate = now.plus({
      months: decision.monthsToNextReview
    }).toMillis();
  }

  return dispatch(decisionSaved(decision));
};

const decisionDeleted = (decisionId) => {
  return {
    type: 'DECISION_DELETED',
    decisionId,
  };
};

const deleteDecision = (decisionId) => async (dispatch, getState) => {
  // Dispatch the delete before waiting for server response for better animations
  const currentUser = getState().user;
  const httpsCallable = firebase.functions().httpsCallable('deleteDecision');
  await httpsCallable({
    userId: currentUser.uid,
    decisionId,
  });
  dispatch(decisionDeleted(decisionId));
};

const syncToReviewDecisions = (querySnapshot) => (dispatch) => {
  syncDecisions(querySnapshot, dispatch, toReviewDecisionsUpdated);
};

const syncDraftDecisions = (querySnapshot) => (dispatch) => {
  syncDecisions(querySnapshot, dispatch, draftDecisionsUpdated);
};

const syncScheduledDecisions = (querySnapshot) => (dispatch) => {
  syncDecisions(
    querySnapshot,
    dispatch,
    scheduledForReviewDecisionsUpdated
  );
};

const syncPastDecisions = (querySnapshot) => (dispatch) => {
  syncDecisions(querySnapshot, dispatch, pastDecisionsUpdated);
};

const syncDecisions = (querySnapshot, dispatch, callback) => {
  const list = [];
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      list.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  dispatch(callback(list));
};

export {
  loadingDraftDecisions,
  loadingScheduledForReviewDecisions,
  loadingPastDecisions,
  loadingToReviewDecisions,
  showDecision,
  saveDecision,
  deleteDecision,
  syncCurrentDecision,
  loadHomeDecisionData,
  loadPastDecisions,
  loadDraftDecisions,
  loadToReviewDecisions,
  loadScheduledForReviewDecisions,
  loadOutstandingDecisionsToReviewCount,
  syncDraftDecisions,
  syncToReviewDecisions,
  syncScheduledDecisions,
  syncPastDecisions,
};
