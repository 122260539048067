import {Switch, Route, useLocation} from 'react-router-dom';

import PageContainer from '../../common/PageContainer';
import Button from '../../common/Button';

import DecisionsSubMenu from './DecisionsSubMenu';
import InProgressDecisions from './InProgressDecisions';
import ToReviewDecisions from './ToReviewDecisions';
import ScheduledDecisions from './ScheduledDecisions';
import PastDecisions from './PastDecisions';
import CalendarEvents from './CalendarEvents';

import {showCreateDecision} from '../../../util/navigation';

const DecisionsHome = ({history}) => {
  const location = useLocation();

  return (
    <PageContainer 
      title="Decisions" 
      headerActionItems={[
        <Button 
          additionalClasses="btn-primary"
          onClick={() => showCreateDecision(history, {prevLocation: location.pathname})}
          label="Add Decision"
        />
      ]}
    >
      <>
        <CalendarEvents />
        <DecisionsSubMenu history={history} />
        <Switch>
          <Route exact path="/past">
            <PastDecisions />
          </Route>
          <Route exact path="/scheduled">
            <ScheduledDecisions />
          </Route>
          <Route exact path="/to_review">
            <ToReviewDecisions />
          </Route>
          <Route path="/">
            <InProgressDecisions />
          </Route>
        </Switch>
      </>
    </PageContainer>
  );
}

export default DecisionsHome;