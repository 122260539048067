const initialState = {
  isLoading: false,
  loadingFailed: false,
  list: [],
};

function pastDecisions(state = initialState, action) {
  switch (action.type) {
    case 'PAST_DECISIONS_LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'PAST_DECISIONS_UPDATED':
      return {
        ...state,
        list: action.decisions,
        isLoading: false,
        loadingFailed: false,
      };

    case 'PAST_DECISIONS_LOADING_FAILED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      };

    case 'LOGGED_OUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default pastDecisions;
