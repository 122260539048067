const PageHeader = ({
  title, 
  actionItems, 
  additionalClasses,
}) => {
  return (
    <header className={`bg-white dark:bg-black ${additionalClasses ? additionalClasses : ''}`}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="font-bold text-gray-900 dark:text-white sm:text-3xl">
              {title}
            </h2>
          </div>
          {actionItems && actionItems.length > 0 && (
            <div className="flex items-center">
              {actionItems.map((actionItem, idx) => {
                return (
                  <div key={idx} className="py-3 sm:py-0">
                    {actionItem}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default PageHeader;