const SegmentedControl = ({
  values,
  selectedIndex,
  onChange,
}) => {
  const handleSelect = (idx) => {
    onChange(idx);
  }

  return (
    <div className="block sm:inline-block">
      <nav className="flex space-x-0 bg-gray-200 dark:bg-gray-800 rounded-md border-2 dark:border-gray-800" aria-label="Tabs">
        {values.map((value, idx) => {
          return (
            <button
              key={idx}
              href="#" 
              className={`
                text-gray-600 dark:text-gray-300 dark:hover:text-gray-200 hover:text-gray-800 px-3 py-2 font-semibold text-sm rounded-md flex-1 sm:flex-0 focus:outline-none
                ${idx === selectedIndex ? 'bg-white text-gray-800 dark:text-white dark:bg-gray-700' : ''}
              `} 
              aria-current="page"
              onClick={() => handleSelect(idx)}
            >
              {value}
            </button>
          )
        })}
      </nav>
    </div>
  )
}

export default SegmentedControl;