const Button = ({
  onClick, 
  label, 
  icon, 
  additionalClasses, 
  isLoading, 
  isDisabled,
  children,
}) => {
  return (
    <button 
      onClick={onClick} 
      className={`btn ${additionalClasses ? additionalClasses : ''}`}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? (
        <span>Please Wait...</span>
      ) : children ? children : (
        <>
          {icon && (
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              {icon}
            </span>
          )}
          {label}
        </>
      )}
    </button>
  );
}

export default Button;