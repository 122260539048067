import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {round} from 'lodash';

import SidePanelHeader from '../../common/SidePanelHeader';
import EmptyState from '../../common/EmptyState';

import TimeSeriesGraph from './TimeSeriesGraph';
import TimeSeriesGraphNavMenu from './TimeSeriesGraphNavMenu';

import firebase from '../../../util/firebase';
import {
  getInfoFromTimeSeries,
  timeSeriesPeriodOptions,
} from '../../../util/timeSeries';
import {getSkillVsLuckDifferenceSummary} from '../../../util/skillLuck';

import {loadTagTimeSeries} from '../../../actions/tagTimeSeries';
import {syncCurrentTag} from '../../../actions/tags';

const AnalyzeTag = ({tagId, tagText, onDismiss, onBack}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const tag = useSelector((store) => store.tags.currentTag);
  const timeSeriesStats = useSelector((store) => store.tagTimeSeries.data);
  const timeSeriesIsLoading = useSelector(
    (store) => store.tagTimeSeries.isLoading
  );
  const [timeSeriesTimePeriodIndex, setTimeSeriesTimePeriodIndex] = useState(0);
  const [currentTimeSeriesChart, setCurrentTimeSeriesChart] = useState('accuracy');
  const tagRef = firebase.firestore()
    .collection('Users')
    .doc(user.uid)
    .collection('Tags')
    .doc(tagId);

  const timeSeriesData = getInfoFromTimeSeries(
    timeSeriesStats,
    timeSeriesPeriodOptions[timeSeriesTimePeriodIndex],
    currentTimeSeriesChart === 'brier' 
      ? 'brierScore' 
      : currentTimeSeriesChart === 'skillLuck' ? 'skillLuckDifference' : 'accuracyScore'
  );

  useEffect(() => {
    const tagUnsubscribe = tagRef.onSnapshot((docSnapshot) =>
      dispatch(syncCurrentTag(docSnapshot))
    );
    dispatch(loadTagTimeSeries(tagText));

    return () => {
      tagUnsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tag) {
    return null;
  }

  return (
    <div className="flex-1 h-0 overflow-y-auto">
      <SidePanelHeader title={`Analyze: ${tagText}`} onDismiss={onDismiss} onBack={onBack} />
      <div className="px-4 space-y-6 sm:px-6">
        <div className="space-y-3">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-3">
              <h3>Accuracy Score</h3>
              <div>
                <h3 className="text-3xl">{round(tag.avgAccuracyScore, 1)}</h3>
                <p className="text-sm">
                    {tag.avgAccuracyScore
                        ? `For ${tag.numReviews} ${tag.numReviews === 1 ? 'decision' : 'decisions'}`
                        : 'Add some reviews to get a score.'}
                  </p>
              </div>
            </div>
            <div className="space-y-3">
              <h3>Brier Score</h3>
              <div>
                <h3 className="text-3xl">{tag.avgBrierScore ? round(tag.avgBrierScore, 2) : 'N/A'}</h3>
                <p className="text-sm">
                  {tag.avgBrierScore
                      ? `For ${tag.numReviewsWithBrierScore} ${tag.numReviewsWithBrierScore === 1 ? 'decision' : 'decisions'}`
                      : 'Add outcome estimates to your decisions and reviews to get a score.'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-3">
          <h3>Skill vs Luck</h3>
          <div className="space-y-3">
            {tag.avgSkillLuckDifference ? (
              <div>
                <p>Overall, you {getSkillVsLuckDifferenceSummary(tag.avgSkillLuckDifference)}</p>
              </div>
            ) : (
              <EmptyState
                title="Not enough data yet"
                description="Once you've added a decision and review with skill vs luck weights, your results will show here."
                size="sm"
              />
            )}
          </div>
        </div>
        <div className="space-y-4">
          <h3>Over Time</h3>
          <TimeSeriesGraphNavMenu onChange={setCurrentTimeSeriesChart} currentSelection={currentTimeSeriesChart} />
          <TimeSeriesGraph
            isLoading={timeSeriesIsLoading}
            data={timeSeriesData}
            timePeriodIndex={timeSeriesTimePeriodIndex}
            handleChangeTimePeriodIndex={(selectedIndex) => {
              setTimeSeriesTimePeriodIndex(selectedIndex);
            }}
            type={currentTimeSeriesChart}
          />
        </div>
      </div>
    </div>
  )
}

export default AnalyzeTag;