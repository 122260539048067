import {
  Switch,
  Route,
} from 'react-router-dom';

import LoginForm from './LoginForm';
import PendingEmailConfirmationView from './PendingEmailConfirmationView';

const Login = ({history}) => {
  return (
    <div className="min-h-screen justify-center sm:max-w-lg sm:mx-auto">
      <div className="py-5 sm:py-10">
        <h1 className="base-text text-center sm:text-5xl">Decision Journal</h1>
      </div>
      <Switch>
        <Route path="/login/email_confirmation">
          <PendingEmailConfirmationView />
        </Route>
        <Route path="/login">
          <LoginForm history={history} />
        </Route>
      </Switch>
    </div>
  )
}

export default Login;