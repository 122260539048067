import {useSelector, useDispatch} from 'react-redux';
import {capitalize, orderBy} from 'lodash';

import ConnectGoogleCalendarButton from '../../common/ConnectGoogleCalendarButton';

import {removeCalendarProvider, updateSelectedEventCalendarForSource} from '../../../actions/calendar';
import {showLoadingModal, hideLoadingModal} from '../../../actions/loadingModal';

import {logAndShowError} from '../../../util/errors';

const CalendarSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const calendarSources = user.calendarSources;
  const calIsConnected = calendarSources && Object.keys(calendarSources).length > 0;

  const unlinkCalendar = async (source) => {
    try {
      dispatch(showLoadingModal());
      await dispatch(removeCalendarProvider(source));
      dispatch(hideLoadingModal());
    } catch (e) {
      dispatch(hideLoadingModal());
      logAndShowError(e);
    }
  }

  const handleToggleEventsCalendar = async (source, calendar, checked) => {
    let calendars = calendarSources[source].selectedCalendars || [];
    if (checked) {
      calendars.push(calendar.id);
    } else {
      calendars = calendars.filter(id => id !== calendar.id);
    }

    try {
      await dispatch(updateSelectedEventCalendarForSource(source, calendars));
    } catch (e) {
      logAndShowError(e);
    }
  }

  return (
    <div className="card">
      <h3 className="card-title">Calendar Settings</h3>
      <div className="card-body">
        {calIsConnected ? (
          <div className="settings-section-content">
            The following calendars are connected:
            {Object.keys(calendarSources).map(source => {
              return (
                <div key={source}>
                  <div className="settings-row">
                    <span className="font-bold">{capitalize(source)}</span>
                    <button onClick={() => unlinkCalendar(source)} className="link text-sm">
                      Unlink
                    </button>
                  </div>
                  {(calendarSources[source].availableCalendars && calendarSources[source].availableCalendars.length > 1) && (
                    <div className="space-y-2">
                      <div className="text-sm text-gray-500">Select the calendars you'd like to have events displayed from:</div>
                      {orderBy(calendarSources[source].availableCalendars, 'name').map(calendar => {
                        return (
                          <div key={calendar.id} className="relative flex items-center">
                            <div className="flex items-center h-5">
                              <input 
                                type="checkbox" 
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked={calendarSources[source].selectedCalendars && calendarSources[source].selectedCalendars.includes(calendar.id)}
                                onChange={(e) => handleToggleEventsCalendar(source, calendar, e.target.checked)}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="comments" className="font-medium text-gray-700 dark:text-gray-400">{calendar.name}</label>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : <ConnectGoogleCalendarButton />}
      </div>
    </div>
  )
}

export default CalendarSettings;