

const SearchResultCell = ({
  title, 
  onClick,
  dateInfo,
  isDraft
}) => {
  return (
    <div className="py-4 px-3 sm:flex items-center justify-between cursor-pointer" onClick={onClick}>
      <h4>
        {title}
        {isDraft && (<span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
          Draft
        </span>)}
      </h4>
      <p className="text-sm text-gray-500">
        {dateInfo}
      </p>
    </div>
  );
}

export default SearchResultCell;