import DecisionCard from './DecisionCard';

const DecisionsList = ({decisions, decisionType, onClickDecision}) => {
  return (
    <div className="py-5 px-4 sm:px-0 sm:mt-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {decisions.map((decision) => {
        return <DecisionCard key={decision.id} decision={decision} onClick={() => onClickDecision(decision)} type={decisionType} />;
      })}
    </div>
  )
}

export default DecisionsList;