const PUBLISHED_STATUS = 'published';
const DRAFT_STATUS = 'draft';

const initialReviewState = {
  actualOutcome: '',
  learningsAndReview: '',
  skillLuckWeight: null,
  accuracyScore: 5,
  status: 'draft',
  monthsToNextReview: null,
};

export {initialReviewState, PUBLISHED_STATUS, DRAFT_STATUS};
