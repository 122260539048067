import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from '../common/Button';
import { logError } from '../../util/errors';
import firebase from '../../util/firebase';
import { API_URL } from '../../util/constants';
import { logEvent } from '../../util/analytics';
import LoadingContainer from '../common/LoadingContainer';

import { showLoadingModal, hideLoadingModal } from '../../actions/loadingModal';

const OAuthAuthorization = () => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get('client_id');
  const redirectUri = queryParams.get('redirect_uri');
  const responseType = queryParams.get('response_type');
  const scopes = queryParams.get('scope');
  const state = queryParams.get('state');

  useEffect(() => {
    // Get the client information based on params
    const fetchClientData = async () => {
      try {
        setIsLoading(true);
        const resp = await firebase.functions().httpsCallable('getOAuthClient')({
          clientId,
        });
        setClient(resp.data);
        setIsLoading(false);
        logEvent('viewed_oauth_authorization_screen', {
          client_name: resp.data.name,
        });
      } catch (e) {
        setIsLoading(false);
        return logError(e);
      }
    };
    fetchClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    logEvent('denied_oauth', {
      client_name: client?.name,
    });
    window.location = redirectUri + '?error=access_denied';
  };

  const handleAuthorize = async () => {
    dispatch(showLoadingModal());
    try {
      const userIdToken = await firebase.auth().currentUser.getIdToken();
      const response = await fetch(`${API_URL}/oauth/authorize`, {
        method: 'POST',
        headers: {
          'X-Firebase-Token': userIdToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: clientId,
          redirect_uri: redirectUri,
          response_type: responseType,
          scope: scopes,
          state,
          allowed: true,
        }),
      });
      dispatch(hideLoadingModal());
      if (!response.ok) {
        const error = await response.json();
        logError(error);
        return alert(error.message);
      }
      const data = await response.json();
      logEvent('authorized_oauth', {
        client_name: client?.name,
      });
      const location = `${data.redirectUri}?code=${data.authorizationCode}${
        state ? `&state=${state}` : ''
      }`;
      window.location = location;
    } catch (e) {
      dispatch(hideLoadingModal());
      alert(e.message);
    }
  };

  if (isLoading)
    return (
      <div className='min-h-screen justify-center sm:max-w-lg sm:mx-auto'>
        <LoadingContainer />
      </div>
    );

  return (
    <div className='min-h-screen justify-center sm:max-w-lg sm:mx-auto'>
      <div className='py-5 sm:py-10'>
        <h3 className='base-text text-center flex justify-center items-center'>
          <img
            className='h-14 w-14'
            src='https://firebasestorage.googleapis.com/v0/b/decision-journal-app.appspot.com/o/website%2Ficon-rounded.png?alt=media&token=b52b9082-c0cf-4bad-840d-ba1881e2fdb9'
            alt='Decision Journal'
          />
          <span className='px-3'>+</span>
          <img className='h-14 w-14' src={client?.logoUrl} alt={client?.name} />
        </h3>
      </div>
      <div className='p-4 bg-white dark:bg-gray-900 border sm:border-gray-200 sm:dark:border-gray-800 sm:rounded-xl sm:p-5'>
        <div className='divide-y divide-gray-200 dark:divide-gray-800 space-y-5'>
          <div className='divide-y divide-gray-200 dark:divide-gray-800 leading-6 space-y-4'>
            <div className='divide-y'>
              <div className='space-y-3 pb-6'>
                <p className='base-text'>
                  <strong>{client?.name}</strong> is requesting permission to access your Decision
                  Journal account. This means it will be able to:
                </p>
                <ul className='list-disc list-inside'>
                  <li className='base-text'>View, edit, and create Decisions</li>
                  <li className='base-text'>View, edit, and create Reviews</li>
                </ul>
                <p className='base-text'>
                  By clicking "Allow", you are authorizing <strong>{client?.name}</strong> to do any
                  of the above.
                </p>
              </div>
              {client && clientId && redirectUri ? (
                <div className='pt-6 flex justify-between'>
                  <Button
                    label='Cancel'
                    additionalClasses={'btn-secondary'}
                    onClick={handleCancel}
                  />
                  <Button
                    label='Allow'
                    additionalClasses={'btn-primary'}
                    onClick={handleAuthorize}
                  />
                </div>
              ) : (
                <div>
                  <p className='base-text py-3'>Invalid request. Missing client information.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OAuthAuthorization;
