const showToast = (toast) => {
  return {
    type: 'SHOW_TOAST',
    toast,
  };
};

const hideToast = () => {
  return {
    type: 'HIDE_TOAST',
  };
};

const displayToast = ({
  type,
  position,
  message,
  dismissAfterTime = 3000,
}) => (dispatch) => {
  if (type === 'success') {
    dispatch(showToast({
      type,
      position,
      message,
      icon: 'check',
      autoDismissAfter: dismissAfterTime,
    }));
  }

  if (type === 'connection') {
    dispatch(showToast({
      type,
      position,
      message,
      icon: 'exclamation-triangle',
      showDismiss: true,
    }));
  }

  if (type === 'error') {
    dispatch(showToast({
      type,
      position,
      message,
      icon: 'exclamation-triangle',
      autoDismissAfter: dismissAfterTime,
    }));
  }
};

export {
  showToast, 
  displayToast, 
  hideToast
};
