const initialState = {
  isLoading: false,
  loadingFailed: false,
  selected: null,
  currentTag: null,
  decisions: [],
  list: [],
};

function tags(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_TAG':
      return {
        ...state,
        selected: action.tag,
      };

    case 'CURRENT_TAG_UPDATED':
      return {
        ...state,
        currentTag: action.tag,
      };

    case 'FETCHING_TAGS':
      return {
        ...state,
        isLoading: true,
        loadingFailed: false,
      };

    case 'FETCHING_TAG_DECISIONS':
      return {
        ...state,
        isLoading: true,
        loadingFailed: false,
      };

    case 'FETCHING_TAG_DECISIONS_FAILED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      };

    case 'FETCHING_TAGS_FAILED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      };

    case 'TAG_DECISIONS_LOADED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: false,
        decisions: action.decisions,
      };

    case 'TAGS_LOADED':
      return {
        ...state,
        isLoading: false,
        loadingFailed: false,
        list: action.tags,
      };

    case 'LOGGED_OUT':
      return initialState;

    default:
      return state;
  }
}

export default tags;
