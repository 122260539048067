import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {isEqual} from 'lodash';

import Button from '../../common/Button';

import TagInput from './TagInput';

import {logAndShowError} from '../../../util/errors';

import {showLoadingModal, hideLoadingModal} from '../../../actions/loadingModal';
import {saveDecision} from '../../../actions/decisions';
import {displayToast} from '../../../actions/toasts';

const EditTagsModal = ({decision: initialDecision, onDismiss}) => {
  const dispatch = useDispatch();
  const [decision, updateDecision] = useState(initialDecision);
  const changesMade = !isEqual(decision, initialDecision);

  const handleDismiss = (e) => {
    if (e) {
      e.preventDefault();
    }
    onDismiss();
  };

  const save = async () => {
    const decisionData = decision;
    try {
      dispatch(showLoadingModal());
      await dispatch(saveDecision(decisionData));
      dispatch(hideLoadingModal());
      onDismiss();
      dispatch(
        displayToast({
          type: 'success',
          message: 'Tags saved!',
        })
      );
    } catch (err) {
      dispatch(hideLoadingModal());
      logAndShowError(err);
    }
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700">
      <div>
        <h3 className="modal-title" id="modal-headline">
          Edit Tags
        </h3>
        <div className="modal-body">
          <TagInput
            tagsList={decision.tags}
            onAddTag={(tag) =>
              updateDecision({
                ...decision,
                tags: [...decision.tags, tag],
              })
            }
            onRemoveTag={(tag) =>
              updateDecision({
                ...decision,
                tags: decision.tags.filter((t) => t !== tag),
              })
            }
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button label="Cancel" onClick={handleDismiss} additionalClasses="btn-block" />
        <Button isDisabled={!changesMade} label="Save" onClick={save} additionalClasses="btn-primary btn-block" />
      </div>
    </div>
  );
}

export default EditTagsModal;