const FEATURE_HIGHLIGHTS = [
  {
    id: 1,
    text: 'Create unlimited number of decisions',
  },
  {
    id: 2,
    text: 'Create unlimited number of reviews',
  },
  {
    id: 3,
    text: 'Full access to all features',
  },
];

const SubscriptionFeatures = () => {
  const renderFeature = (feature) => {
    return (
      <div key={feature.id} className="flex items-center justify-center">
        <svg className="h-5 w-5 mr-2 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
        <p className="">{feature.text}</p>
      </div>
    );
  };

  return (
    <div className="text-center justify-center">
      {FEATURE_HIGHLIGHTS.map(renderFeature)}
    </div>
  )
}

export default SubscriptionFeatures;