import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

const PendingEmailConfirmationView = () => {
  const email = useSelector((store) => store.user.email);

  return (
    <div className="p-4 sm:p-5">
      <div className="space-y-3 sm:space-y-7">
        <div className="text-center justify-center items-center text-8xl flex">
          <PaperAirplaneIcon className="h-24 w-24" />
        </div>
        <h3 className="text-center sm:text-4xl">Check your email!</h3>
        <p className="text-center">
          We sent an email to{' '}
          <strong>{email}</strong>.<br/>
          Tap the link in the email to login.
        </p>
        <div className="text-center text-sm">
          <Link to="/login">Second thoughts? Login using a different method.</Link>
        </div>
      </div>
    </div>
  );
};

export default PendingEmailConfirmationView;
