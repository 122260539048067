import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Button from '../common/Button';

import {
  showLoadingModal,
  hideLoadingModal,
} from '../../actions/loadingModal';
import {loginUserWithGoogle} from '../../actions/login';

import {logError, logAndShowError} from '../../util/errors';

const LoginWithGoogleButton = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const loginWithGoogle = async () => {
    try {
      dispatch(showLoadingModal());
      await dispatch(loginUserWithGoogle());
      dispatch(hideLoadingModal());
      if (location.state && location.state.from) {
        const { from } = location.state;
        history.push(from);
      }
    } catch (e) {
      dispatch(hideLoadingModal())
      if (e.code === 'auth/popup-closed-by-user') {
        logError(e);
      } else {
        logAndShowError(e);
      }
    }
  }

  return (
    <Button 
      onClick={loginWithGoogle} 
      label="Continue with Google" 
      // icon={<FontAwesomeIcon icon={faGoogle} />} 
      additionalClasses="bg-blue-600 hover:bg-blue-700 btn-block text-white"
    />
  )
}

export default LoginWithGoogleButton;