import React from 'react';
import {DateTime} from 'luxon';

import SearchResultCell from './SearchResultCell';

import {decisionReviewCalendarDay} from '../../../util/time';
import {dueForReview} from '../../../util/copy';

const ToReviewDecisionSearchResult = ({decision, onClick}) => {
  const reviewDate = DateTime.fromMillis(decision.nextReviewDate);
  const isDueForReview = (reviewDate < DateTime.local()) || DateTime.local().hasSame(decision.nextReviewDate, 'day');

  const dateInfo = decision.nextReviewDate
    ? isDueForReview
      ? dueForReview
      : `Review Scheduled for ${decisionReviewCalendarDay(
          decision.nextReviewDate
        )}`
    : null;

  return (
    <SearchResultCell
      title={decision.title}
      showBadge={isDueForReview}
      onClick={onClick}
      dateInfo={dateInfo}
    />
  );
};

export default ToReviewDecisionSearchResult;
