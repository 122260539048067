import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import SidePanelHeader from '../../common/SidePanelHeader';
import EmptyState from '../../common/EmptyState';
import LoadingContainer from '../../common/LoadingContainer';

import TagScoreRow from './TagScoreRow';

import {
  showTagView,
} from '../../../util/navigation';

import {loadTags, showTag} from '../../../actions/tags';

const AnalyzeTags = ({onDismiss, onShow}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tags = useSelector((store) => store.tags.list);
  const isLoading = useSelector((store) => store.tags.isLoading);

  useEffect(() => {
    dispatch(loadTags(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickTag = (tag) => {
    dispatch(showTag(tag));
    showTagView(history, tag);
  };

  const renderEmpty = () => {
    return (
      <EmptyState
        title="No tags"
        description="Once you have some tags, they will show here."
      />
    );
  };

  const onShowTag = (tag) => {
    onShow(tag);
  }

  return (
    <div className="flex-1 h-0 overflow-y-auto">
      <SidePanelHeader title="Tag Scores" onDismiss={onDismiss} />
      <div className="px-4 divide-y divide-gray-200 dark:divide-gray-700 sm:px-6">
        {isLoading ? (
          <LoadingContainer />
        ) : (
          tags.length > 0 ? (
            tags.map((tag) => {
              return (
                <TagScoreRow
                  key={tag.id}
                  tag={tag}
                  onClickTag={onClickTag}
                  onClick={() => onShowTag(tag)}
                />
              );
            })
          ) : renderEmpty()
        )}
      </div>
    </div>
  )
}

export default AnalyzeTags;