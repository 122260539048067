import {useSelector, useDispatch} from 'react-redux';

import GettingStartedSteps from '../common/GettingStartedSteps';

import {getGettingStartedSteps} from '../../util/gettingStarted';
import {logEvent} from '../../util/analytics';

import {
  toggleGettingStartedMenu, 
  dismissGettingStartedMenu
} from '../../actions/user';

const GettingStartedMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const gettingStartedStepsStatuses = user.gettingStartedSteps;
  const stepsRemaining = getGettingStartedSteps(gettingStartedStepsStatuses).filter(s => !s.isCompleted && !s.isSkipped).length;
  const isMinimized = useSelector((store) => !store.gettingStartedMenu || store.gettingStartedMenu.isMinimized);
  const isVisible = useSelector((store) => store.gettingStartedMenu && store.gettingStartedMenu.isVisible);

  const toggleDisplay = (track = true) => {
    if (track) {
      logEvent('toggled_getting_started', {minimized: isMinimized});
    }
    dispatch(toggleGettingStartedMenu(isMinimized));
  }

  const dismissMenu = () => {
    logEvent('dismissed_getting_started_menu');
    dispatch(dismissGettingStartedMenu());
  }

  if ((gettingStartedStepsStatuses && gettingStartedStepsStatuses.isCompleted) || !isVisible) {
    return null;
  } else {
    const appEl = document.getElementById('app');
    if (appEl) {
      appEl.classList.add('pb-10');
    }
  }

  return (
    <div className={`fixed bottom-0 right-0 sm:right-5 bg-white dark:bg-gray-900 shadow-lg p-2 sm:p-3 rounded-t-lg border dark:border-gray-700 w-full sm:w-96`}>
      <nav aria-label="getting started">
        <div onClick={toggleDisplay} className={`flex items-center justify-between cursor-pointer ${isMinimized ? 'pb-0' : 'pb-4'}`}>
          <div className="flex items-center">
            {isMinimized && (
              <span className="flex-shrink-0 w-8 h-8 mr-2 flex items-center justify-center bg-indigo-600 rounded-full" aria-current="step">
                <span className="text-white">{stepsRemaining}</span>
              </span>
            )}
            <h4>Getting Started</h4>
          </div>
          {isMinimized ? (
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
            </svg>
          ) : (
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          )}
        </div>
        {!isMinimized && (
          <>
            <GettingStartedSteps 
              onClickStep={() => toggleDisplay(false)}
            />
            <div className="mt-2 text-center">
              <span onClick={dismissMenu} className="link text-sm">Hide Menu</span>
            </div>
          </>
        )}
      </nav>
    </div>
  );
};

export default GettingStartedMenu;