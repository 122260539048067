import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { InboxIcon } from '@heroicons/react/24/outline';

import EmptyState from '../../common/EmptyState';
import SidePanelHeader from '../../common/SidePanelHeader';

import DraftReviewCell from './DraftReviewCell';

import firebase from '../../../util/firebase';

import {syncDraftReviews, deleteReview} from '../../../actions/reviews';
import {displayToast} from '../../../actions/toasts';

const DraftReviews = ({decision, onDismiss, onShow}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const data = useSelector((store) => store.reviews.drafts);
  const draftReviewsRef = firebase.firestore()
    .collection('Users')
    .doc(user.uid)
    .collection('Decisions')
    .doc(decision.id)
    .collection('Reviews')
    .where('status', '==', 'draft');

  useEffect(() => {
    const draftReviewsUnsubscribe = draftReviewsRef.onSnapshot(
      (querySnapshot) => dispatch(syncDraftReviews(decision, querySnapshot))
    );

    return () => {
      draftReviewsUnsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDismiss = (event) => {
    if (event) {
      event.preventDefault();
    }
    onDismiss();
  }

  const showReview = (review) => {
    onShow(review);
  };

  const deleteDraft = (reviewId) => {
    const confirmedDelete = window.confirm('Are you sure you want to delete this draft?');
    if (confirmedDelete) {
      dispatch(deleteReview(decision.id, reviewId));
      dispatch(
        displayToast({
          type: 'success',
          message: `Draft deleted!`,
        })
      );
    }
  };

  const renderEmpty = () => {
    return (
      <EmptyState
        icon={<InboxIcon className="h-12 w-12 text-gray-400" />}
        title="No Drafts"
        description={'When you save drafts they\n will show up here.'}
      />
    );
  };

  return (
    <div className="flex-1 h-0 overflow-y-auto">
      <SidePanelHeader title="Reviews In Progress" onDismiss={handleDismiss} />
      <div className="px-4 divide-y divide-gray-200 dark:divide-gray-700 sm:px-6">
        {data.length > 0 ? (
          data.map((draft) => {
            return (
              <DraftReviewCell
                key={draft.id} 
                review={draft} 
                onShow={() => showReview(draft)} 
                onDelete={() => deleteDraft(draft.id)}
              />
            );
          })
        ) : renderEmpty()}
      </div>
    </div>
  );
};

export default DraftReviews;
